import React, { useEffect, useState } from "react";
import { Context } from "../store/StateProvider";
import SelectBox from "./../UITest4/SelectBox";
import classes from "./Test4.module.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

let QUESTION_ANS = [];
let QUESTION_ANS_DUPLICATE = [];

const Question2 = (props) => {
  // states
  const [state, dispatch] = Context();
  const [dropDown, setDropDown] = useState([]);
  const [dropDownDuplicate, setDropDownDuplicate] = useState([]);
  const [questionType, setQuestionType] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const ExampleCustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input type="text" value={value} ref={ref} onClick={onClick} className={`${classes["answerInputDate"]}`} placeholder="Date..." />
  ));

  // states end

  /////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////

  // dropdown logic
  useEffect(() => {
    // Options for dropDown
    // console.log(JSON.parse(props.options))
    // console.log(JSON.parse(props.questionType))
    console.log(props.id,props.answer)
    if(props.answer != null || props.answer != undefined)
    {
      setStartDate(props.answer)
    } 
    else{
      setStartDate(new Date())
    }
    setQuestionType(JSON.parse(props.questionType).value)
    const OPTIONS = JSON.parse(props.options);
    const dropDownOptions = OPTIONS.map((option) => {
      return {
        value: option.optionValue,
        label: option.optionValue,
      };
    });
    console.log(dropDownOptions);
    setDropDownDuplicate(dropDownOptions);
    setDropDown(dropDownOptions);
    // Options for dropDown end
  }, [props.options]);

  //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  // box1 handler
  const box1Handler = ({ label, value }, questionId) => {
    // console.log("label",label)
    // console.log("value",value)
    // console.log("questionId",questionId)
    props.questionChangeHander({ label, value }, questionId);
  };

  return (
    <div >
      <h1 className={`${classes["test2QuestionHead"]}`}>{props.question}</h1>
      {questionType == "dropdown" ?
        <SelectBox
          key={props.id}
          id={props.id}
          // isEnabled={props.isEnabled}
          index={props.index}
          // options={props.isEnabled ? dropDown : dropDownDuplicate}
          options={dropDown}
          box1={box1Handler}
        />
        : questionType == "short" ?
          <div className="text-center my-4">
            <div className={`${classes["selectBoxWrapper"]}`}>
              <div className={`${classes["selectBoxBefore"]}`}>
                <h4>
                  <span>Answer</span>
                </h4>
              </div>
              <div className={`${classes["selectBox"]}`}>
                <input type="text" onChange={(e) => {
                  let label = e.target.value;
                  let value = e.target.value;
                  props.questionChangeHander({ label, value }, props.id);
                }} class={`${classes["answerInput"]}`} placeholder="Answer..." />
              </div>
            </div>
          </div>
          : <div className="text-center my-4">
            <div className={`${classes["selectBoxWrapper"]}`}>
              <div className={`${classes["selectBoxBefore"]}`}>
                <h4>
                  <span>Date</span>
                </h4>
              </div>
              <div className={`${classes["selectBox"]}`}>
                <DatePicker
                  className={`${classes["react-datepicker-wrapper", "react-datepicker__input-container"]}`}
                  selected={startDate} 
                  onChange={(date) => {
                    setStartDate(date)
                    // console.log(date);
                    // console.log(JSON.stringify(date));
                    let label = date;
                    let value = date;
                    props.questionChangeHander({ label, value }, props.id);
                  }}
                  isClearable
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </div>
          </div>
      }
      {/* <div className={`mb-5 ${classes["whiteLine"]}`}></div> */}
    </div>
  );
};

export default Question2;
