import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Question2 from "./Question2";
import { Context } from "../store/StateProvider";
import Header from "../header/Header";
import classes from "./Test4.module.css";
let first_multiplier;
let second_multiplier;
let third_multiplier;
let TESTANSARRAY = [];
let count = 0;
let countend = 9;

const Test4 = () => {
  // states
  const [state, dispatch] = Context();
  const [winner, setWinner] = useState(undefined);
  const [mostCommonScoreDistribution, setMostCommonScoreDistribution] = useState([]);

  const [QUESTIONS, setQUESTIONS] = useState([]);
  const [answersArray, setAnswersArray] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [scriptData, setScriptData] = useState("");

  let navigate = useNavigate();
  const percentages = useLocation();
  let { id } = useParams();
  const move = useRef();
  // states end

  // use Effect
  useEffect(() => {
    axios.get(`${global.apiAdress}/api/code-snippets/list`).then(response => {
      console.log(response);
      if (response.data.status = 200) {
        let CodeSnippetsRaw = response.data.code_snippets;
        const CodeSnippets = CodeSnippetsRaw.filter(
          (e) => e.code_page == "Test Page"
        );
        if (CodeSnippets.length > 0) {
          setScriptData(CodeSnippets[0].code);
        }
        console.log(CodeSnippets)
      }
    })
    setWinner(state.winner);
    setMostCommonScoreDistribution(state.mostCommonScoreDistribution)
    console.log("move", move)
    axios.get(`${global.apiAdress}/api/demographic-questions/list`).then((response) => {
      console.log(response)
      setQUESTIONS(response.data.questions)
    });
  }, []);
  // use Effect end
  const questionChangeHander = ({ label, value }, questionId) => {
    // console.log("label",label)
    // console.log("value",value)
    // console.log("questionId",questionId)
    const UPDATEDQUESTIONS = QUESTIONS.map((question, index) => {
      // console.log(question)
      if (question.demographics_question_id == questionId) {
        question.answer = value;
      }

      return question;
    });
    console.log(UPDATEDQUESTIONS);
  };
  const resultBtnHandler = () => {
    localStorage.setItem("resultReload", "1");
    QUESTIONS.forEach(element => {
      const questionData = {
        demographics_answer_value: element.answer,
        demographics_question_id: element.demographics_question_id
      }
      console.log("questionData", questionData)
      axios.post(`${global.apiAdress}/api/test4/demographic-answer/add`, questionData).then(response => {
        if (response.status === 200) {
          navigate("/result", { state: percentages.state });
          localStorage.setItem("winner", JSON.stringify(winner));
          localStorage.setItem("mostCommonScoreDistribution", JSON.stringify(mostCommonScoreDistribution));
          // navigate("/result", { state: {percentages:percentages, winnerR:winner, mostCommonScoreDistributionR:mostCommonScoreDistribution }});
        }
      })
    });

    // dispatch({ type: "testStarted", isTesting: false });
  };
  return (
    <div className="container-fluid  h-100">
      <div dangerouslySetInnerHTML={{ __html: scriptData }} />
      <div className="row h-100">
        <div className="col-11  px-0 mx-auto h-100 ">
          <div className={` ${classes.mainBoxWrapper}`}>
            <Header />
            <div className={` ${classes.mainBox}`}>
              <div className={` ${classes.mainBoxAfter}`}>
                {/* copied */}
                {loading ? (
                  <h1 className="text-white text-center my-5">Loading...</h1>
                ) : (
                  <div>
                    {QUESTIONS.map((question, index) => (
                      <div
                        className="mt-5"
                        key={question.demographics_question_id}
                      >
                        <Question2
                          key={question.demographics_question_id}
                          id={question.demographics_question_id}
                          question={question.demographics_question_title}
                          options={question.demographics_question_options}
                          questionType={question.demographics_question_type}
                          answer={question.answer}
                          index={index}
                          questionLength={QUESTIONS}
                          scrollMove={move}
                          count={count}
                          questionChangeHander={questionChangeHander}
                        />
                      </div>
                    ))}
                    <div className="text-center">
                      {nextBtn ? (
                        <button
                          className={`btn ${classes["reportBtn"]}`}
                          // onClick={nextBtnHandler}
                          disabled={isDisabled}
                        >
                          next
                        </button>
                      ) : (
                        <button
                          className={`btn ${classes["reportBtn"]}`}
                          onClick={resultBtnHandler}
                          disabled={isDisabled}
                        >
                          result
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {/* copied end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test4;
