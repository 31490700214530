// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoqt66KqQt-5WEt0Jy3wlAUaf4RFPiFRY",
  authDomain: "personality-nft.firebaseapp.com",
  projectId: "personality-nft",
  storageBucket: "personality-nft.appspot.com",
  messagingSenderId: "308897091577",
  appId: "1:308897091577:web:a7daa206612511b27af728"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
