
import React, { Fragment, useEffect, useState } from "react";

let i = 0;
let txt = 'Your Result';
let speed = 50;

const NextStepsButtons = (props) => {
  const [hiddenPdfButton, setHiddenPdfButton] = useState(true);

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    // typeWriter();
    setTimeout(function () {
      setHiddenPdfButton(false);
    }, 8000)
  }, [props]);
  const handleScroll = () => {

    const joinUsInTheMetaverse = document.querySelector("#joinUsInTheMetaverse");
    if (joinUsInTheMetaverse.getBoundingClientRect().top > 0 && joinUsInTheMetaverse.getBoundingClientRect().top < document.documentElement.clientHeight) {
      joinUsInTheMetaverse.classList.add('resultYourResultHeadingAnim');
    }

    const threeGifts = document.querySelector("#threeGifts");
    if (threeGifts.getBoundingClientRect().top > 0 && threeGifts.getBoundingClientRect().top < document.documentElement.clientHeight) {
      threeGifts.classList.add('resultYourResultHeadingAnim');
    }

  }
  function typeWriter() {
    if (i < txt.length) {
      document.getElementById("mintButtonsYourResult").innerHTML += txt.charAt(i);
      i++;
      setTimeout(typeWriter, speed);
    }
  }
  return (
    <Fragment>
      {/* <div>
          <h2 className="resultPersonality">Your Personality</h2>
          </div> */}
      <div className="container">
        <div className="text-center">
          <h2 className="resultYourResultHeading" id="nextStepsTitle">Next Steps</h2>
        </div>
        <div className="row px-3 px-md-5 text-center">

          <div className="col-12 my-5 text-left">
            <h2 className="mintButtonsGiftHeading mt-3" id="joinUsInTheMetaverse">Join us in the Metaverse</h2>
            <div className="">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <p className="resultFirstLine">Your Personality NFT is your Pass to our Communities and to the Game of Life.</p>
                  {/* <p className="resultFirstLine">Your Primary Value System is <span>Achiever</span></p> */}
                </div>
                <div className="col-md-5 col-sm-12 text-center">
                  <button onClick={(e) => {
                    props.changeCurrentPage("MintCards")
                  }} className="mintButtonGreen">Mint My Personality NFT</button>
                  {/* <p className="mintButtonsSmallPara mt-2">*Personality NFT will be used in the Game of Life.</p> */}
                </div>
              </div>
            </div>
            <h2 className="mintButtonsGiftHeading mt-3" id="threeGifts">We have 3 Gifts</h2>
            <div className="mt-4">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <p className="resultSecondLine">1. Download your Cards & Results PDF</p>
                </div>
                <div className="col-md-5 col-sm-12 text-center">
                  <button hidden={hiddenPdfButton} onClick={(e) => {
                    props.downloadBothCards();
                  }} className="mintButtonRedNextSteps1">Download Cards & PDF</button>
                  <div hidden={!hiddenPdfButton} class="spinner-border"></div>
                </div>
                <div className="col-md-7 col-sm-12 mt-4">
                  <p className="resultSecondLine">2. Meet friends who share your Goals</p>
                </div>
                <div className="col-md-5 col-sm-12 mt-4 text-center">
                  <button onClick={(e) => {
                    console.log(props.winner.value_result_link)
                    window.open(props.winner.value_result_link, '_blank');
                  }} className="mintButtonRedNextSteps2 ">Join {props.winner!=undefined || props.winner!=null || props.winner!='undefined' ? props.winner.value_name : ""} Community</button>
                </div>
                <div className="col-md-7 col-sm-12 mt-4">
                  <p className="resultSecondLine">3. Discover how to use the Cards & achieve your Goals in the Hero’s Journey Workshop</p>
                </div>
                <div className="col-md-5 col-sm-12 mt-4 text-center">
                  <button onClick={(e) => {
                    window.open('https://playground.gameoflife.co/share/0Hw_RRKnRzLFkInV?utm_source=manual', '_blank');
                  }} className="mintButtonRedNextSteps3">Hero’s Journey Workshop</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NextStepsButtons;
