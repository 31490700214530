import React from "react";
import Ccheckbox from "../UI/Ccheckbox";
import classes from "./Test.module.css";
import CautionPNG from "../../assets/caution.png";
import "./Question.css";

const Question = (props) => {
  const checkBoxData = (BoxId, value, e) => {
    const TestId = JSON.parse(props.TestId);
    props.questionData(BoxId, value, e, props.id, TestId,props.subtraitId,props.traitId);
  };
  const TestAnswer = JSON.parse(props.TestAnswer);

  return (
    <div
      id={props.IsEnabled ? "move" : ""}
      // className={props.IsEnabled ? "" : classes["disabled"]}
      className={`  ${ props.IsEnabled ? "" : classes["disabled"]}`}
    >
      <div 
        className={`${classes["questionMainDiv"]} ${ (props.IsMissed == true && props.validationErrorCounter==1) ?  classes["questionMainDivError"] :""} `}
      >
        
        <h2 className={`${classes["questionHeading"]}`}>{props.question}</h2>
        {(props.IsMissed == true && props.validationErrorCounter==1) ? 
          <img className={`${classes["cautionErrorImg"]} `} src={CautionPNG} />
        : null }
        <div style={{ width: '100%' }} className={`customOptions d-flex align-items-center mt-5`}>
          <span className={`desktopLabel ${classes["checkLabel"]}`}>Disagree</span>
          {props.IsActive.map((style) => (
            <Ccheckbox
              key={style.id}
              id={style.id}
              IsChecked={style.IsChecked}
              checkboxCheck={checkBoxData}
              IsEnabled={props.IsEnabled}
              TestAnswer={TestAnswer}
              scrollMove={props.scrollMove}
              validationErrorCounter={props.validationErrorCounter}
            />
          ))}
          <span className={`desktopLabel ${classes["checkLabel"]}`}>Agree</span>
        </div>
        <div className="mobileCustomOptions">
          <p className={`${classes["checkLabel"]}`}>Disagree</p>
          <p className={`${classes["checkLabel"]}`}>Agree</p>
        </div>
        <div className={`${classes["whiteLine"]}`}></div>
      </div>
    </div>
  );
};

export default Question;
