import axios from "axios";
import React, { createContext, useReducer, useContext, useCallback, useEffect } from "react";
import { alchemy } from "../../utils/alchemy";

const PageContext = createContext(); // create a store
const WalletProvider = ({ reducer, initialstate, children }) => {
  const [state, dispatch] = useReducer(reducer, initialstate);
  const { address } = state;

  const loadData = useCallback(async () => {
    if (!address) {
      dispatch({
        type: 'multiUpdate',
        state: {
          nfts: [],
          mintPasses: [],  
        },
      });
      return;
    }
    let mintedTokensAndResultByAddressForm = {
      walletAddress: address
    }
    let currentResultTokenId = '';
    let rarity = '';
    const [responseCoinPrice, responseReward, response] = await Promise.all([
      axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`),
      axios.get(`${global.apiAddressBlockchain}/nfts/rewards?owner=${address}`),
      axios.post(`${global.apiAdress}/api/result-page/minted-tokens-and-result-by-address`, mintedTokensAndResultByAddressForm),
    ]);
    let currentPrice = responseCoinPrice.data[1].current_price;
    const [mintPassTokens, nftTokens] = await Promise.all([
      alchemy.nft.getNftsForOwner(address, {
        contractAddresses: [global.mintpassContracyAddress],
      }),
      alchemy.nft.getNftsForOwner(address, {
        contractAddresses: [global.personalityContracyAddress],
      }),
    ]);
    console.log("mintPassTokens, nftTokens", mintPassTokens, nftTokens)
    let extraversionData = "", opennessData = "", neuroticismData = "", conscientiousnessData = "", agreeablenessData = "";
    let machiavellianismData = "", narcissismData = "", psychopathyData = "", winnerData = "", commonDistribution = "";
    let winner = {}
    const [mintPasses, nfts] = await Promise.all([
      Promise.all(mintPassTokens.ownedNfts.reverse().map(async element => {
        const responseMetaData = await axios.get(`${global.apiAddressBlockchain}/nfts/mintpass/metadata/${element.tokenId}`);
        return {
          token_id: element.tokenId,
          balance: element.balance,
          description: element.description,
          title: element.title,
          rarity: responseMetaData.data.rarity
        };
      })),      
      (await Promise.all(nftTokens.ownedNfts.reverse().map(async (element, index) => {
        const metadata = await axios.get(`${global.apiAddressBlockchain}/metadata/personality/${element.tokenId}`);
        const currentTokenData = responseReward.data[element.tokenId]
        let lifeTimeEarnings = 0;
        let unpaidEarnings = 0;
        if (currentTokenData != undefined && currentTokenData != null) {
          let totalEth = 0;
          let totalEthUnpaid = 0;
          currentTokenData.forEach(element => {
            totalEth = totalEth + (element.amount)
            if (element.paid == false) {
              totalEthUnpaid = totalEthUnpaid + (element.amount)
            }
          });
          console.log("totalEth", totalEth)
          let reward_in_dollars = currentPrice * totalEth;
          let fixedNum = 2;
          if (reward_in_dollars < 10) {
            fixedNum = 6;
          }
          else {
            fixedNum = 2;
          }
          lifeTimeEarnings = reward_in_dollars.toFixed(fixedNum);
          let reward_in_dollars_unpaid = currentPrice * totalEthUnpaid;
          if (reward_in_dollars_unpaid < 10) {
            fixedNum = 6;
          }
          else {
            fixedNum = 2;
          }
          unpaidEarnings = reward_in_dollars_unpaid.toFixed(fixedNum);

        }
        let lifeTimeEarningsValue = lifeTimeEarnings;
        let unpaidEarningsValue = unpaidEarnings;

        if (!metadata.data) {
          return null;
        }
        return {
          token_id: element.tokenId,
          metadata: metadata.data,
          lifeTimeEarnings: lifeTimeEarningsValue,
          unpaidEarnings: unpaidEarningsValue
        };
      }))).filter(v => !!v),
    ]);
    console.log("mintPasses, nfts", mintPasses, nfts)
    dispatch({
      type: 'multiUpdate',
      state: {
        nfts,
        mintPasses,  
        currentResultTokenId,
        rarity,
      },
    });
  }, [address]);

  useEffect(loadData, [loadData]);
  useEffect(() => {
    document.addEventListener('loadData', loadData);
    return () => document.removeEventListener('loadData', loadData);
  }, [loadData]);
  
  return (
    <PageContext.Provider value={[state, dispatch]}>
      {children}
    </PageContext.Provider>
  );
};

export const WalletContext = () => {
  return useContext(PageContext);
};

export default WalletProvider;
