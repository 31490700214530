import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import Question from "./Question";
import Header from "../header/Header";
import { Context } from "../store/StateProvider";
import classes from "./Test.module.css";

import RedPNG from "../../assets/red-error.png";

let traitsPercentages = [];
let urlPath = "";
let markedAnswers = [];
let checkedAnswers = 0;
const Test = () => {
  // copied

  // start my state
  const [questions, setQuestions] = useState([]);
  const [testTraits, setTestTraits] = useState([]);
  const [resultBtn, setResultBtn] = useState(false);
  const [index, setIndex] = useState(0);
  const [state, dispatch] = Context();
  let navigate = useNavigate();
  let { id } = useParams();
  const [isDisable, setIsDisabled] = useState(true);
  const [isDisableError, setIsDisabledError] = useState(false);
  const [validationErrorCounter, setValidationErrorCounter] = useState(0);
  const [apiData, setApiData] = useState([]);
  // const [questionAnswer, setQuestionAnswer] = useState(state.testData);

  // end my states
  const [loading, setLoading] = useState(false);
  const [traitLimit, setTraitLimit] = useState(state.traitvalue);
  const [traitMarks, setTraitMarks] = useState([]);
  const location = useLocation();
  const move = useRef();
  const moveTop = useRef();

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [startQuestion, setStartQuestion] = useState(0);
  const [endQuestion, setEndQuestion] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [scriptData, setScriptData] = useState("");

  useEffect(() => {
    axios.get(`${global.apiAdress}/api/code-snippets/list`).then(response => {
      if (response.data.status = 200) {
        let CodeSnippetsRaw = response.data.code_snippets;
        const CodeSnippets = CodeSnippetsRaw.filter(
          (e) => e.code_page == "Test Page"
        );
        if (CodeSnippets.length > 0) {
          setScriptData(CodeSnippets[0].code);
        }
      }
    })
    dispatch({ type: "testStarted", isTesting: true });
    axios
      .get(`${global.apiAdress}/api/user/test/questions/${id}`)
      .then((testResponse) => {
        if (
          testResponse.status === 200 &&
          testResponse.data.questions.length > 0
        ) {
          setQuestions(testResponse.data.questions);
          if (testResponse.data.questions.length <= 10) {
            setResultBtn(true);
          }
          setTotalQuestions(testResponse.data.questions.length)
          let totalPagesVariable = Math.floor(testResponse.data.questions.length / 10)
          if (testResponse.data.questions.length % 10 != 0) {
            totalPagesVariable++;
          }
          setTotalPages(totalPagesVariable)
          setCurrentPage(1);
          setStartQuestion(0)
          setEndQuestion(10)
          setApiData(testResponse.data.questions.slice(0, 10));
        }
      });

    // to merge store data into variable
    markedAnswers = [];
    markedAnswers = markedAnswers.concat(state.testData);
  }, []);

  // function for calulating traits percentage
  const calculatePercentage = (answers) => {
    let marks = 0;
    let test_id = 0;
    let subTraitPercentage = []
    let traitPercentage = []
    answers.map((item) => {
      if (item.subtraitId != null && item.subtraitId != undefined) {
        let subtraitIndex = -1;
        if (subTraitPercentage.length > 0) {
          subtraitIndex = subTraitPercentage.findIndex((subtraitItem) => {
            return subtraitItem.subtraitId == item.subtraitId;
          });
        }
        if (subtraitIndex != -1) {
          subTraitPercentage[subtraitIndex].marks += item.optionMark;
          subTraitPercentage[subtraitIndex].questions += 1;
          subTraitPercentage[subtraitIndex].percent = (subTraitPercentage[subtraitIndex].marks / (subTraitPercentage[subtraitIndex].questions * 100)) * 100;

        }
        else {
          subTraitPercentage.push(
            {
              subtraitId: item.subtraitId,
              traitId: item.TraitId,
              marks: item.optionMark,
              questions: 1,
              percent: (item.optionMark / (1 * 100)) * 100
            }
          )
        }

      }

      if (item.TraitId != null && item.TraitId != undefined) {
        let traitIndex = -1;
        if (traitPercentage.length > 0) {
          traitIndex = traitPercentage.findIndex((traitItem) => {
            return traitItem.traitId == item.TraitId;
          });
        }
        if (traitIndex != -1) {
          traitPercentage[traitIndex].marks += item.optionMark;
          traitPercentage[traitIndex].questions += 1;
          traitPercentage[traitIndex].percentage = (traitPercentage[traitIndex].marks / (item.traitQuestions * 100)) * 100;

        }
        else {
          traitPercentage.push(
            {
              test_id: item.TestId,
              traitId: item.TraitId,
              marks: item.optionMark,
              questions: 1,
              percentage: (item.optionMark / (item.traitQuestions * 100)) * 100
            }
          )
        }

      }


    });
    dispatch({ type: "TEST1RESULT", firstTestR: traitPercentage });
    traitsPercentages = traitPercentage;

    dispatch({ type: "subtraitsPercentages", subtraitsPercentages: subTraitPercentage });
    navigate(`/reportResult/${id}`, { state: traitPercentage });

  };

  // questions handler
  const questionHandler = (BoxId, value, e, QuestionId, TestId, subtraitId, traitId) => {
    let firstQuestionId = "";
    let boxFilled = false;
    const UPDATEDQUESTIONS = apiData.map((question, index) => {

      if (question.id === QuestionId) {
        // checkbox marking logic
        question.IsActive.forEach((checkboxes) => {
          boxFilled = true;
          if (checkboxes.id === BoxId) {
            checkboxes.IsChecked = e;
          } else {
            checkboxes.IsChecked = false;
          }
        });
        if (e) {
          if (question.IsMissed == true) {
            checkedAnswers++;
          }
          question.IsMissed = false;

        }
        else {
          checkedAnswers--;
          question.IsMissed = true;
        }
        if (checkedAnswers == apiData.length) {
          setIsDisabled(false);
        }
        else {
          setIsDisabled(true);
        }
        // question enabling logic
        question.IsEnabled = false;
        const nextIndex = index + 1;
        const NextData = apiData[nextIndex];
        if (apiData[apiData.length] === NextData) {
          // setIsDisabled(false);
          return question;
        } else {
          if (validationErrorCounter == 0) {
            NextData.IsEnabled = true;
          }

          return question;
        }
      } else {
        if (boxFilled == true && question.IsMissed == true) {
          boxFilled = false;
          firstQuestionId = question.id;
          question.IsEnabled = true;
        }
        return question;
      }
    });
    // Answers of questions saving logic
    let tratiQuestions = questions.filter((question) => {
      return question.trait_id == traitId;
    });
    let subtratiQuestions = questions.filter((question) => {
      return question.subtrait_id == subtraitId;
    });
    const answerObj = {
      QuestionId: QuestionId,
      TraitId: traitId,
      traitQuestions: tratiQuestions.length,
      subtraitQuestions: subtratiQuestions.length,
      subtraitId: subtraitId,
      optionMark: value,
      TestId: TestId,
    };

    const temp = markedAnswers.findIndex((e) => e.QuestionId === QuestionId);
    if (temp >= 0) {
      markedAnswers[temp] = answerObj;
    } else {
      markedAnswers.push(answerObj);
    }
    if (validationErrorCounter == 1 && firstQuestionId != "") {
      document.getElementById("question" + firstQuestionId).scrollIntoView({ behaviour: "smooth" });
    }

    setApiData(UPDATEDQUESTIONS);
  };
  // next button handler
  const buttonHandler = () => {
    if (!isDisable) {
      setIsDisabledError(false);
      checkedAnswers = 0;
      setIsDisabled(true);
      setValidationErrorCounter(0);

      setTimeout(function () {
        document.getElementById("moveTop").scrollTo(0, 0);
      }, 500);
      dispatch({ type: "testData", resultData: markedAnswers });

      setCurrentPage(currentPage + 1);
      setStartQuestion(startQuestion + 10)
      let startQuestionVariable = startQuestion + 10;
      let endQuestionVariable = endQuestion;
      if (questions.length <= (endQuestion + 10)) {
        endQuestionVariable = questions.length;
        setResultBtn(true);
      }
      else {
        endQuestionVariable = endQuestion + 10;
      }
      setEndQuestion(endQuestionVariable)
      let nextTraitQuestions = questions.slice(startQuestionVariable, endQuestionVariable)
      nextTraitQuestions[0].IsEnabled = true;
      setApiData(nextTraitQuestions);

      setIsDisabled(true);
      setIndex((prev) => prev + 1);
    }
    else {
      setValidationErrorCounter(1);
      setIsDisabledError(true);

      let firstQuestionId = "";
      const UPDATEDQUESTIONS = apiData.map((question, index) => {
        if (firstQuestionId == "" && question.IsMissed == true) {
          firstQuestionId = question.id;
          question.IsEnabled = true;
          return question;
        }
        else {
          question.IsEnabled = false;
          return question;
        }

      });
      document.getElementById("question" + firstQuestionId).scrollIntoView({ behaviour: "smooth" });
      setApiData(UPDATEDQUESTIONS)
    }
  };

  // result button handler
  const resultButtonHandler = () => {
    if (!isDisable) {
      setIsDisabledError(false);
      setIsDisabled(true);
      checkedAnswers = 0;
      setValidationErrorCounter(0);
      dispatch({ type: "testData", resultData: markedAnswers });
      calculatePercentage(markedAnswers);
    }
    else {
      setValidationErrorCounter(1);
      setIsDisabledError(true);
      let firstQuestionId = "";
      const UPDATEDQUESTIONS = apiData.map((question, index) => {
        if (firstQuestionId == "" && question.IsMissed == true) {
          firstQuestionId = question.id;
          question.IsEnabled = true;
          return question;
        }
        else {
          question.IsEnabled = false;
          return question;
        }

      });
      document.getElementById("question" + firstQuestionId).scrollIntoView({ behaviour: "smooth" });
      setApiData(UPDATEDQUESTIONS)
    }

  };

  // copied end

  return (
    <div className="container-fluid h-100">
      <div dangerouslySetInnerHTML={{ __html: scriptData }} />
      <div className="row h-100">
        <div className="col-11  px-0 mx-auto h-100 ">
          <div className={` ${classes.mainBoxWrapper}`}>
            <Header />
            <div className={`container ${classes.mainBox}`}>
              <div id="moveTop" ref={moveTop} className={` ${classes.mainBoxAfter}`}>
                {/* copied */}
                <div className="mt-5 text-center testSection">
                  {loading ? (
                    <h4>Loading...</h4>
                  ) : (
                    apiData.map((question, index) => (
                      <div
                        id={"question" + question.id}
                        key={question.id}
                        ref={question.IsEnabled ? move : null}
                      >
                        <Question
                          key={question.id}
                          id={question.id}
                          question={question.question_title}
                          IsEnabled={question.IsEnabled}
                          IsActive={question.IsActive}
                          TestAnswer={question.test_three_answers}
                          TestId={question.test_id}
                          subtraitId={question.subtrait_id}
                          traitId={question.trait_id}
                          questionData={questionHandler}
                          scrollMove={move}
                          validationErrorCounter={validationErrorCounter}
                          IsMissed={question.IsMissed}

                        />

                      </div>
                    ))
                  )}
                  {isDisableError ?
                    <div className=" mb-5 align-items-center text-center testSection">
                      <h2 className={`${classes["errorHeading"]}`}><img style={{ height: 27, marginRight: 15 }} src={RedPNG} />Error ! Complete All Questions!</h2>
                    </div>
                    : null}
                  {resultBtn ? (
                    <button
                      onClick={resultButtonHandler}
                      className={`btn ${classes["reportBtn"]}`}
                    // disabled={isDisable}
                    >
                      result
                    </button>
                  ) : (
                    <button
                      onClick={buttonHandler}
                      // disabled={isDisable}
                      className={`btn ${classes["reportBtn"]}`}
                    >
                      next
                    </button>
                  )}
                </div>
                {/* copied */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;
