import { supportedChains } from "../../utils/chains";

export const initialWalletState = {
  loading: true,
  provider: null,
  web3Provider: null,
  address: "",
  chainId: 0,
  network: "",
  signer: null,
  chain: null,
  connect: null,
  disconnect: null,
  nfts: [],
  mintPasses: [],
  currentResultTokenId: '',
  loadData: () => {
    document.dispatchEvent(new Event('loadData'));
  },
};

const WalletReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return {
        ...initialWalletState,
        chain: supportedChains.find((c) => c.network === "goerli"),
      };
    case "update":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "multiUpdate":
      return {
        ...state,
        ...action.state,
      };
    default:
      throw new Error();
  }
};

export default WalletReducer;
