import React from "react";
import Header from "../header/Header";
import classes from "./MintPage.module.css";
import resultCardMain1 from "../../assets/resultCardMain1.webp";
import resultCardMain2 from "../../assets/resultCardMain2.webp";

const MintPage = () => {
  return (
    <div className="container-fluid  h-100">
      <div className="row h-100">
        <div className="col-11  px-0 mx-auto h-100 ">
          <div className={` ${classes.mainBoxWrapper}`}>
            <Header />
            <div className={`container ${classes.mainBox}`}>
              <div className={` ${classes.mainBoxAfter}`}>
                {/* copied */}
                <h2 className={`mb-5 ${classes["mintMain"]}`}>Mint Cards</h2>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 text-center text-md-right">
                      <img
                        className="img-fluid mb-3 mb-md-0"
                        src={resultCardMain1}
                        alt="maincard1"
                      />
                    </div>
                    <div className="col-md-7">
                      <h4
                        className={`mb-5 px-3 px-md-0 ${classes["mintSubHead"]}`}
                      >
                        Visionary Collection #807
                      </h4>
                      <p className={`${classes["mintSubPara"]}`}>
                        Congrats! You qualify for the Visionary Collection which
                        is the first 1,000 Personalities to ever get minted!
                      </p>
                      <p className={`${classes["mintSubPara"]}`}>
                        Get your Visionary Card via Auction here.
                      </p>
                      <button className={`btn ${classes["btnMint"]}`}>
                        Sign In & Join Waitlist &gt;
                      </button>
                    </div>
                    <div
                      className={`col-12 my-5 mx-auto ${classes["mintWhiteLine"]}`}
                    ></div>
                    <div className="col-12 text-center">
                      <h3 className={` mb-5 ${classes["mintPersonality"]}`}>
                        Mint your Personality Cards
                      </h3>
                      <div className="row">
                        <div className="col-md-6 mb-3 mb-md-0 text-md-right">
                          <img
                            className="img-fluid"
                            src={resultCardMain1}
                            alt="card1"
                          />
                        </div>
                        <div className="col-md-6 text-md-left">
                          <img
                            className="img-fluid"
                            src={resultCardMain2}
                            alt="card2"
                          />
                        </div>
                      </div>
                      <p className={`my-5 ${classes["mintPersonalityPara"]}`}>
                        After Visionary Collection has been auctioned. Minting
                        will be open to all.
                      </p>
                      <button
                        className={`btn ${classes["btnmintpersonality"]}  mb-5`}
                      >
                        Mint Coming Soon &gt;
                      </button>
                    </div>
                  </div>
                </div>
                {/* copied end*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintPage;
