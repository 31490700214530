import React, { createContext, useReducer, useContext, useCallback } from "react";
const PageContext = createContext(); // create a store

const StateProvider = ({ reducer, initialstate, children }) => {
  return (
    <PageContext.Provider value={useReducer(reducer, initialstate)}>
      {children}
    </PageContext.Provider>
  );
};

export const Context = () => {
  return useContext(PageContext);
};

export default StateProvider;
