import React from "react";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/bs";
import useWindowDimensions from "../customHooks/WindowsDimention";
import classes from "./../test1/Test.module.css";

// import tick from "../../assets/tick.png";

const Ccheckbox = (props) => {
  // dimensions
  const { height, width } = useWindowDimensions();
  let resSize = 0;
  if (width <= 786) {
    resSize = 12;
  }
  // conditional styling
  let borderColor, size, backgroundColor, value;
  if (props.id === "b1") {
    borderColor = "rgb(251, 209, 240)";
    size = 50 - resSize;
    backgroundColor = "rgb(160, 204, 180)";
    value = parseInt(props.TestAnswer[0]["optionMark"]);
  } else if (props.id === "b2") {
    borderColor = "rgb(251, 209, 240)";
    size = 45 - resSize;
    backgroundColor = "rgb(160, 204, 180)";
    value = parseInt(props.TestAnswer[1]["optionMark"]);
  } else if (props.id === "b3") {
    borderColor = "rgb(184, 168, 168)";
    size = 40 - resSize;
    backgroundColor = "rgb(160, 204, 180)";
    value = parseInt(props.TestAnswer[2]["optionMark"]);
  } else if (props.id === "b4") {
    borderColor = "#a0ccb4";
    size = 45 - resSize;
    backgroundColor = "rgb(160, 204, 180)";
    value = parseInt(props.TestAnswer[3]["optionMark"]);
  } else if (props.id === "b5") {
    borderColor = "#a0ccb4";
    size = 50 - resSize;
    backgroundColor = "rgb(160, 204, 180)";
    value = parseInt(props.TestAnswer[4]["optionMark"]);
  }
  // checkbox change handler
  const checkBoxChangeHandler = (BoxId, value, e) => {
    props.checkboxCheck(BoxId, value, e);
    if (props.validationErrorCounter == 0) {
      if (props.scrollMove.current != null || props.scrollMove.current != undefined) {
        props.scrollMove.current.scrollIntoView({ behaviour: "smooth" });
      }
    }
  };

  return (
    <div className={`d-inline-block questionSkippedError ${classes["checkboxWrapper"]}`}>
      <Checkbox
        checked={props.IsChecked}
        icon={
          <div
            style={{
              display: "flex",
              flex: 1,
              backgroundColor: backgroundColor,
              alignSelf: "stretch",
            }}
          >
            <Icon.BsCheck2 color="white" size={size} />

            {/* <img src={tick} alt="ent" /> */}
          </div>
        }
        borderColor={borderColor}
        borderWidth={3}
        borderRadius={100}
        style={{ overflow: "hidden" }}
        size={size}
        onChange={checkBoxChangeHandler.bind(null, props.id, value)}
        // disabled={!props.IsEnabled}
        value={value}

      // label="Coooool right?"
      />
    </div>
  );
};

export default Ccheckbox;
