import React, { Fragment, useEffect, useState } from "react";
import classes from "./ForgotPassword.module.css";
import axios from "axios";
import { Context } from "../../store/StateProvider";



const ForgotPassword = (props) => {
    const [state, dispatch] = Context();


    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true)
    const [userId, setUserId] = useState('')

    useEffect(async () => {
        console.log(window.location)
        if (window.location.href.includes("forgot-password")) {
            let splits = window.location.pathname.split('/');
            let received_id = splits[2];
            let number = received_id.length - 12;
            let number2 = number + 6;
            let result = received_id.substring(6, number2);
            setUserId(result)
        }
        console.log(props)
    }, []);


    return (
        <Fragment>
            <div className="container-fluid  h-100">
                <div className="row h-100">
                    <div className="col-11 px-0 mx-auto h-100">
                        <div className="text-center">
                            <h2 className={`mb-3 ${classes["loginMainHead"]}`}>
                                Update Password
                            </h2>

                            <div className={`${classes["loginTermWrapper"]}`}>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className={`form-control  ${classes["loginInput"]}`}
                                        placeholder="Enter Password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className={`form-control  ${classes["loginInput"]}`}
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={e => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                                <span hidden={confirmPasswordHidden} className={`${classes["confirmationFailedSpan"]}`}>
                                    Password Confirmation Failed!
                                </span>
                            </div>
                            <button onClick={(e) => {
                                console.log(password)
                                console.log(confirmPassword)
                                if (password != confirmPassword) {
                                    setConfirmPasswordHidden(false)
                                }
                                else {
                                    const userData = {
                                        password: password,
                                        user_id: userId
                                    }
                                    axios.post(`${global.apiAdress}/api/result-page/update-password`, userData).then(response => {
                                        console.log("Password Updated!", response)
                                        setPassword('')
                                        setConfirmPassword('')
                                        if (response.status === 200) {
                                            window.open("/login","_self")
                                        }
                                    })
                                }

                            }} className={`btn  ${classes["btnSignup"]}  my-3`}>
                                Update Password
                            </button>
                            <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ForgotPassword;
