export const initialstate = {
  testData: [],
  test1Result: [],
  test3Data: [],
  startTest: false,
  Hpercent: 0,
  winner:undefined,
  mostCommonScoreDistribution:[],
  subtraitsPercentages:[],
  bigFiveTraits:[],
  darkTraidTraits:[],
  loggedUser:"",
};


const Reducer = (state, action) => {
  if (action.type === "TEST1RESULT") {
    return {
      test1Result: action.firstTestR,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "testData") {
    return {
      testData: [...action.resultData],
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      test1Result: state.test1Result,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "testStarted") {
    return {
      testData: state.testData,
      startTest: action.isTesting,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      test1Result: state.test1Result,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "percentage") {
    return {
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: action.resultDataa,
      test3Data: state.test3Data,
      test1Result: state.test1Result,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "TEST3") {
    return {
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: action.test3Ans,
      test1Result: state.test1Result,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "TEMPLATE2_WINNER") {
    return {
      test1Result: state.test1Result,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:action.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "TEMPLATE2_SCORE_DISTRIBUTION") {
    return {
      test1Result: state.test1Result,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:state.winner,
      mostCommonScoreDistribution:action.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "subtraitsPercentages") {
    return {
      test1Result: state.test1Result,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:action.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "bigFiveTraits") {
    return {
      test1Result: state.test1Result,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:action.bigFiveTraits,
      darkTraidTraits:state.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "darkTraidTraits") {
    return {
      test1Result: state.test1Result,
      testData: state.testData,
      startTest: state.startTest,
      Hpercent: state.Hpercent,
      test3Data: state.test3Data,
      winner:state.winner,
      mostCommonScoreDistribution:state.mostCommonScoreDistribution,
      subtraitsPercentages:state.subtraitsPercentages,
      bigFiveTraits:state.bigFiveTraits,
      darkTraidTraits:action.darkTraidTraits,
      loggedUser:state.loggedUser,
    };
  }
  if (action.type === "multiUpdate") {
    return {
      ...state,
      ...action.state
    }
  }
  if (action.type === "reset") {
    return {
      ...initialstate
    }
  }

  return state;
};

export default Reducer;
