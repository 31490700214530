import axios from "axios";
import React, { useEffect, useState } from "react";
import classes from "../../test1/Test.module.css";
import Web3 from "web3/dist/web3.min.js";
import { WalletContext } from "../../store/WalletProvider";
import dropdownIcon from "../../../assets/dropdownIconMint.png";
import { personality } from "../../../abis/personality";
import dropdownIconEther from "../../../assets/Ether.png";
import dropdownIconBytes from "../../../assets/Bytes.PNG";
import EtheriumSideIconDropDown from "../../../assets/block1.png"
import mintAnimation from "../../../assets/minting.gif";
import { SessionContext } from "../../store/SessionProvider";
import TraitCard from "../../UI/TraitCard";

const MintModalForm = (props) => {
  const [mintProcessing, setMintProcessing] = useState(false)
  const [selectCryptoHidden, setSelectCryptoHidden] = useState(true)
  const [selectCryptoValue, setSelectCryptoValue] = useState(".05 ETH")
  const [mintpassRarity, setMintpassRarity] = useState("")
  const [mintpassTokenId, setMintpassTokenId] = useState("0")
  const [{ nextMint, visitorId }] = SessionContext();
  const [{ web3Provider, chain, address, loadData, mintPasses }, dispatchWallet] = WalletContext(); 
  const [etherPrice, setEtherPrice] = useState(0)
  const [dollarMintAmount, setDollarMintAmount] = useState(0)
  const [fee, setFee] = useState(null);

  useEffect(() => {
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`).then(response => {
      setEtherPrice(response.data[1].current_price)
    });
  }, []);

  useEffect(() => {
    if (!etherPrice) {
      return;
    }
    axios.get(`${global.apiAddressBlockchain}/nfts/sign-mint?receiver=0xb81798b54005170F23f08351F4C26C4e736e26C0`).then(response => {
      const { requiredEth } = response.data;
      let dollarAmount = etherPrice * requiredEth;
      setDollarMintAmount(dollarAmount);
      setFee(response.data);
      setSelectCryptoValue(`${requiredEth.toFixed(5)} ETH`);
    });
  }, [etherPrice])

  const getFormDataAndUrl = (actualTokenId) => {
    let discount_code = "abc";
    let discount_link = "https://discount.com/";
    let discount_code_used = "none";

    let nftInformation = {
      rarity: "Rarity 1",
      yield: "Bonus 1",
      payout_level: "20",
      lifetime_earning: "Lifetime Earnings",
      earning_details_link: "https://earnigns.com/"
    }
    let bigFiveTraits = {
      extraversion: props.extraversion.percentage,
      openness: props.openness.percentage,
      neuroticism: props.neuroticism.percentage,
      conscientiousness: props.conscientiousness.percentage,
      agreeableness: props.agreeableness.percentage,
    }

    let darkTriadTraits = {
      machiavellianism: props.machiavellianism.percentage,
      narcissism: props.narcissism.percentage,
      psychopathy: props.psychopathy.percentage
    }
    let value_card = {
      id: props.winner.id,
      value_name: props.winner.value_name,
      card_image: props.winner.card_image
    }
    let formData = {
      tokenId: actualTokenId,
      bigFiveTraits: JSON.stringify(bigFiveTraits),
      darkTraidTraits: JSON.stringify(darkTriadTraits),
      value_card: JSON.stringify(value_card),
      visitorId: visitorId,
      discount_code: discount_code,
      discount_link: discount_link,
      discount_code_used: discount_code_used,
      nftInformation: JSON.stringify(nftInformation),
      loggedUserWalletAddress: address,
    }
    let url = 'https://personality-nft.web.app?';
    if (props.conscientiousness) {
      url += `c=${parseInt(Math.round((props.conscientiousness.percentage / 10.00)))}&`
    }
    if (props.agreeableness) {
      url += `a=${parseInt(Math.round((props.agreeableness.percentage / 10.00)))}&`
    }
    if (props.openness) {
      url += `o=${parseInt(Math.round((props.openness.percentage / 10.00)))}&`
    }
    if (props.extraversion) {
      url += `e=${parseInt(Math.round((props.extraversion.percentage / 10.00)))}&`
    }
    if (props.neuroticism) {
      url += `n=${parseInt(Math.round((props.neuroticism.percentage / 10.00)))}&`
    }
    if (props.narcissism) {
      url += `dtn=${parseInt(Math.round((props.narcissism.percentage / 10.00)))}&`
    }
    if (props.machiavellianism) {
      url += `dtm=${parseInt(Math.round((props.machiavellianism.percentage / 10.00)))}&`
    }
    if (props.psychopathy) {
      url += `dtp=${parseInt(Math.round((props.psychopathy.percentage / 10.00)))}&`
    }
    if (props.winnerCardName) {
      url += `back=${props.winnerCardName}`
    }
    const traits = [
      ...Object.keys(bigFiveTraits).map(k => ({
        name: k,
        value: `${bigFiveTraits[k]}%`,
      })),
      ...Object.keys(darkTriadTraits).map(k => ({
        name: k,
        value: `${darkTriadTraits[k]}%`,
      }))
    ];
    return { formData, url, traits }
  }

  return (
    <div className={`${classes.mainBoxAfterResult}`}>
      <div className="container">
        <div id="mintCardsNew" className="text-center">
          <div className="row mt-5">
            {/* this is the card div */}
            <div className="col-md-5 col-sm-12 d-flex justify-content-center align-items-center">
              {
                !!props && <TraitCard
                  conscientiousness={props.conscientiousness}
                  agreeableness={props.agreeableness}
                  openness={props.openness}
                  extraversion={props.extraversion} 
                  neuroticism={props.neuroticism}
                  narcissism={props.narcissism}
                  psychopathy={props.psychopathy}
                  machiavellianism={props.machiavellianism}
                  winnerCardName={props.winnerCardName}
                  height="420px" 
                  width="300px"
                />
              }
            </div>
            {/* end of card div */}
            <div className="col-md-6 col-sm-12 ml-4 mintModalInfoContainer d-flex justify-content-center align-items-center">
              <div className="container-sm" style={{maxWidth: 400}}>
                <h1 hidden={!mintProcessing} className="myNFTMainHeadingBold text-center mintModalTitle">Processing...</h1>
                <img src={mintAnimation} hidden={!mintProcessing} className="mx-auto" height="200" />
                <div hidden={mintProcessing}>
                  <h2 className="myNFTMainHeadingBold text-left mintModalTitle">Mint Your Personality</h2>
                  <h4 className="myNFTSmallMessage text-left">Congrats! You are one of the first people to have their personality minted on the blockchain.
                    This NFT is interactive which means you can spin it, zoom in and out to look at both cards and your traits!  </h4>
                </div>
                <div hidden={selectCryptoValue == "Rarity Card"} className="">
                  <div className="row">
                    <div className="col-md-12">
                      {nextMint ? (
                        <>
                          <h2 hidden={!mintProcessing} className="myNFTSecondHeadingMint text-center mt-4">Your Minting#  {nextMint.tokenId}</h2>
                          <h4 className={`myNFTSecondHeadingMint mt-2 ${mintProcessing ? 'text-center' : 'text-left'}`}>Rarity: {nextMint.rarity.name} </h4>                      
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                {
                  nextMint ? (
                    <div hidden={selectCryptoValue == "Rarity Card"} className="">
                      <div className="row justify-content-between">
                        <div className="col-md-6">
                          <h4 className={`myNFTSecondHeading ${mintProcessing ? 'text-center' : 'text-left'} mt-1 mr-5`}>Bonus:  {nextMint.rarity.payouts.reduce((pv, cv) => pv + cv, 0)}%</h4>
                        </div>
                        <div className="col-md-6">
                          <h4 className={`myNFTSecondHeading ${mintProcessing ? 'text-center' : 'text-left'} mt-1`}>Payout Levels: {nextMint.rarity.payouts.filter(p => !!p).length}</h4>
                        </div>
                      </div>
                    </div>  
                  ) : null
                }
              <h4 hidden={selectCryptoValue == "Rarity Card" || mintProcessing} className="myNFTSmallMessage mt-2 text-left">Your Rarity & Bonus is determined by Mint Number on a first come first serve basis. As Mint Numbers increase Rarity and Bonus will decrease. Get Mint # 14 before its Gone.</h4>
              {(mintPasses && mintPasses.length) ?
                <div hidden={selectCryptoValue != "Rarity Card"} className="containerEpicMintPass mt-2">
                  <h2 className="myNFTMainHeading text-left">{!mintpassRarity} Mint Pass</h2>
                  <h4 className="myNFTSmallMessageMintPass mt-2 text-left">You have an {mintpassRarity} MINT PASS. We increase your Rarity & Bonus to {mintpassRarity}.</h4>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-2 mr-5">Rarity: {mintpassRarity} </h4>
                      </div>
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-2">Mint #: {mintpassTokenId}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-1 mr-5">Bonus: {props.getYield(mintpassRarity)} </h4>
                      </div>
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-1">Payout Levels: {props.getPayoutLevels(mintpassRarity)}</h4>
                      </div>
                    </div>
                  </div>
                </div>
                : null}
              <h2 hidden className="myNFTSecondHeadingMint text-left mt-2 mr-5">18 : 00 : 00</h2>
              <div className="mintButtonGroup" hidden={mintProcessing}>
                {localStorage.getItem('referralTokenId') != undefined && localStorage.getItem('referralTokenId') != null && localStorage.getItem('referralTokenId') != "" ?
                  <h4 className="myNFTMaxDiscount mt-2">Cash Back : &nbsp;.005ETH (${etherPrice * 0.005}) </h4>
                  : null}
                {nextMint ?
                  <h2 hidden={mintProcessing} className="myNFTSecondHeadingMint text-left mt-4 mr-5">Your Mint#  {nextMint.tokenId}</h2>
                  : null}
                <h4 className="myNFTSmallMessage mt-2 text-left">Select Payment Method </h4>
                <div className="selectCrypto" onClick={(e) => {
                  setSelectCryptoHidden(!selectCryptoHidden);
                }}>
                  <div className="row">
                    <div className="col-9 selectCryptoColumn1">
                      <img
                        hidden={selectCryptoValue == "Rarity Card"}
                        className="selectCryptoDropdownImage mt-3 mb-1"
                        style={{ width: "27px", height: "26px", float: "left", marginLeft: 10 }}
                        src={EtheriumSideIconDropDown}
                        alt="card1"
                      />
                      <h2 className="selectCryptoValueHeading mt-1">{selectCryptoValue}</h2>
                      <h2 hidden={selectCryptoValue == "Rarity Card"} className="selectCryptoValueLabel mt-1">(${parseInt(dollarMintAmount)} USD)</h2>
                    </div>
                    <div className="col-3">
                      <img
                        hidden={!mintPasses || !mintPasses.length}
                        className="selectCryptoDropdownImage mt-3 mb-1"
                        style={{ width: 20, height: 20 }}
                        src={dropdownIcon}
                        alt="card1"
                      />
                    </div>
                  </div>
                </div>
                {(mintPasses && mintPasses.length) && selectCryptoValue == "Rarity Card" ?
                  <div hidden={selectCryptoHidden} className="selectCryptoDropdownRarity selectCryptoDropdown" onClick={(e) => {
                    setSelectCryptoHidden(!selectCryptoHidden);

                    if (selectCryptoValue == "Rarity Card") {
                      setSelectCryptoValue(`${fee ? fee.requiredEth.toFixed(4) : ''} ETH`)
                    }
                    else {
                      setSelectCryptoValue("Rarity Card")
                    }
                  }}>

                    <div className="row">
                      <div className="col-12 selectCryptoColumn1">
                        <h2 className="selectCryptoValueHeading">{fee ? fee.requiredEth.toFixed(4) : ''} ETH</h2>
                      </div>
                    </div>
                  </div>
                  : null}
                {(mintPasses && mintPasses.length) && selectCryptoValue != "Rarity Card" ?
                  mintPasses.map((element, index) => {
                    return <div hidden={selectCryptoHidden} style={{ marginTop: index * 46 }} className="selectCryptoDropdown1 selectCryptoDropdown" onClick={(e) => {
                      setSelectCryptoHidden(!selectCryptoHidden);
                      if (selectCryptoValue == "Rarity Card") {
                        setSelectCryptoValue(`${fee ? fee.requiredEth.toFixed(4) : ''} ETH`)
                      }
                      else {
                        setSelectCryptoValue("Rarity Card")
                      }
                      setMintpassRarity(element.rarity)
                      setMintpassTokenId(element.token_id)
                    }}>
                      <div className="row">
                        <div className="col-12 text-center selectCryptoColumn1">
                          <h2 className="selectCryptoValueHeadingRarity mt-2">{element.rarity} Rarity Card ({element.balance})</h2>
                        </div>
                      </div>
                    </div>
                  }) : null}
                <div hidden className="selectCryptoDropdown2 selectCryptoDropdown" onClick={(e) => {
                  setSelectCryptoHidden(!selectCryptoHidden);
                  setSelectCryptoValue(`${fee ? fee.requiredEth.toFixed(4) : ''} ETH`)
                }}>
                  <div className="row">
                    <div className="col-9 selectCryptoColumn1">
                      <h2 className="selectCryptoValueHeading">{fee ? fee.requiredEth.toFixed(4) : ''} ETH</h2>
                    </div>
                    <div className="col-3">
                      <img
                        className="selectCryptoDropdownImage mt-3 mb-1"
                        style={{ width: 10, height: 10, minHeight: 10 }}
                        src={dropdownIconEther}
                        alt="card1"
                      />
                    </div>
                  </div>
                </div>
                <div hidden className="selectCryptoDropdown3 selectCryptoDropdown" onClick={(e) => {
                  setSelectCryptoHidden(!selectCryptoHidden);
                  setSelectCryptoValue("45 BYTES")
                }}>
                  <div className="row">
                    <div className="col-9 selectCryptoColumn1">
                      <h2 className="selectCryptoValueHeading">45 BYTES</h2>
                    </div>
                    <div className="col-3">
                      <img
                        className="selectCryptoDropdownImage mt-2 mb-1"
                        style={{ width: 10, height: 10, minHeight: 10 }}
                        src={dropdownIconBytes}
                        alt="card1"
                      />
                    </div>
                  </div>
                </div>

                {
                  web3Provider && chain ? (
                    <>
                    <button className="myNFTMintButton mt-2" onClick={async () => {
                      setMintProcessing(true)
                      const web3 = new Web3(web3Provider.provider);
                      
                      const contractAddress = global.personalityContracyAddress;

                      let owner = address;

                      let referralTokenIdValue = "0";
                      if (localStorage.getItem('referralTokenId') != undefined && localStorage.getItem('referralTokenId') != null && localStorage.getItem('referralTokenId') != "") {
                        referralTokenIdValue = localStorage.getItem('referralTokenId')
                      }
                      console.log("referralTokenIdValue", referralTokenIdValue)
                      let contract_Personality = new web3.eth.Contract(personality, contractAddress);
                      try {
                        axios.get(`${global.apiAddressBlockchain}/nfts/sign-mint?receiver=${owner}`).then(async response => {
                          const { requiredEth, requiredWei } = response.data;

                          let weiValue = requiredWei;
                          let mintPassAddress = "0x0000000000000000000000000000000000000000";
                          let mintPassTokenId = "0";
                          let amountETH = requiredEth;
                          if (selectCryptoValue == "Rarity Card" && (mintPasses && mintPasses.length)) {
                            mintPassTokenId = mintpassTokenId
                            weiValue = 0;
                            amountETH = 0;
                            mintPassAddress = global.mintpassContracyAddress
                          }
                          console.log("THIS IS THE PRICE OF NFT BEING SET ", weiValue)

                          contract_Personality.methods.mint(requiredWei, response.data.timestamp, response.data.signature, mintPassAddress, mintPassTokenId).send({
                            from: owner,
                            gas: 1500000,
                            gasPrice: 0,
                            value: weiValue
                          }).then(async function (result) {
                            
                            let actualTokenId = parseInt((await contract_Personality.methods.numTokens().call()).toString(), 10);
                            const { formData, url, traits } = getFormDataAndUrl(actualTokenId);
                            axios.post(`${global.apiAdress}/api/result-page/store-minted-card`, formData).then(async storeMintedCardResponse => {
                              axios.post(`${global.apiAddressBlockchain}/nfts/save-mint?tokenId=${actualTokenId}&amountETH=${amountETH}&referralTokenId=${referralTokenIdValue}`, {
                                url,
                                traits,
                              }).then(response => {
                                console.log("save-mint", response);
                                localStorage.setItem("referralTokenId", "");
                                loadData();
                                setMintProcessing(false)
                                props.changeMintModal()
                                props.setCongratulationsModal(true)
                                setTimeout(function () {
                                  props.setCongratulationsModal(false)
                                  props.setCurrentPage("MintCards")
                                }, 7000);

                              })
                            });    
                          }).catch(e => {
                            console.log("minting error!");
                            console.log(e.code);

                            if (e.code === 4001) {
                              console.log("user rejected mint");
                              setMintProcessing(false);
                            }

                          });
                        })
                      } catch (e) {
                        console.log("error in request", e);
                      }
                    }} disabled={mintProcessing} >{mintProcessing == false ? selectCryptoValue == "Rarity Card" ? 'Free Mint' : "Mint" : 'Processing...'}</button>
                    <button className="myNFTMintButton mt-2" style={{fontSize: 11}} onClick={async () => {
                      axios.post(`${global.apiAddressBlockchain}/webhooks/create-checkout?url=${window.location.origin}${window.location.pathname}&address=${address}`, getFormDataAndUrl('')).then(response => {
                          window.location.href = response.data.url;
                      }) 
                    }}>Mint with Credit Card</button>
                    </>
                  ) : (
                    <>
                      {
                        web3Provider && !chain ? (
                          <h3 className="myNFTSecondHeading mt-4 text-center">Note: Switch network to Mint</h3>
                        ) : null
                      }
                    </>
                  )
                }
                {
                  !web3Provider ? (
                    <h3 className="myNFTSecondHeading mt-4 text-center">Note: Please connect wallet to Mint</h3>
                  ) : null
                }
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintModalForm;
