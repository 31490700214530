import axios from "axios";
import React, { createContext, useReducer, useContext, useCallback, useEffect } from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs'

const PageContext = createContext(); 
export const getCardName = (winner) => {
  if (!winner || !winner.value_name) {
    return '';
  }

  if (winner.value_name == "Shaman") {
    return "shaman"
  }
  else if (winner.value_name == "King" || winner.value_name == "Kings & Queens") {
    return "king"
  }
  else if (winner.value_name == "The Order" || winner.value_name == "Holy Warrior") {
    return "order"
  }
  else if (winner.value_name == "Achiever" || winner.value_name == "The Chariot") {
    return "achiever"
  }
  else if (winner.value_name == "Egalitarian" || winner.value_name == "The Hermit") {
    return "egalitarian"
  }
  else if (winner.value_name == "Integrator" || winner.value_name == "The Hanged Man | Integrator") {
    return "integrator"
  }
  else if (winner.value_name == "Oracle" || winner.value_name == "The Tower") {
    return "oracle"
  }
  else if (winner.value_name == "Mystic") {
    return "mystic"
  }
  else if (winner.value_name == "Survivalist") {
    return "survivalist"
  }
  return '';
}
const getResultsFromLocalStorage = () => {
  try {
    if (!localStorage.getItem("resultMain")) {
      return {};
    }
    let resultMain = JSON.parse(localStorage.getItem("resultMain"));
    if (resultMain != null && resultMain != undefined && resultMain != "") {
      return {
        ...resultMain,
        winnerCardName: getCardName(resultMain.winner),
        bigFiveTraitsDetails: resultMain.bigFiveTraits,
        darkTraidTraitsDetails: resultMain.darkTraidTraits,
        subtraitsPercentages: resultMain.resultSubTraitsPercentages,
      }
    }
  
  } catch (err) {
    console.log(err);
  }
  return {};
}
const SessionProvider = ({ reducer, initialstate, children }) => {

  const loadSessionData = useCallback(async (props) => {

    if (!props) return
    props.setErrorMessage('');
    const result = getResultsFromLocalStorage()
    if (!result || !result.winner) {
      return;
    }
    if (props.method == "signup") {
      axios.post(`${global.apiAdress}/api/result-page/signup`, props.formData).then(response => {
        if (response.status === 200) {
          if (response.data.status == "Signed Up") {
            console.log("new user created");
            let communityLink = result.winner.value_result_link;
            let message = "You've sucessfully " + response.data.status;
            const userEmailData = {
              user_data_email: props.formData.email,
              user_data_message: message,
              user_data_community_link: communityLink
            }
            axios.post(`${global.apiAdress}/api/user/user-email/sendLoginSignupMessage`, userEmailData).then(response => {
              if (response.status === 200) {
              }
            })
            if (localStorage.getItem('emailPopup') != "Sent" && localStorage.getItem('emailPopup') != "Closed") {
              localStorage.setItem("emailPopup", "LoggedIn");
            }
            localStorage.setItem("loggedUser", props.formData.email);
            props.setErrorMessage('');
            dispatch({
              type: 'multiUpdate',
              state: {
                email: props.formData.email,
                currentPage: 'Profile',
                password: '',
                loggedIn: true,
                loggedUser: props.formData.email,
                errorMsg: '',
              },
            })
          }
          else if (response.data.status == "Email already exist") {
            props.setErrorMessage('This email address is already taken!');
            dispatch({
              type: 'multiUpdate',
              state: {
                email: '',
                password: '',
                loggedIn: false,
                loggedUser: '',
                errorMsg: 'This email address is already taken!',
              },
            })
          }
        }
      });
    }

    if (props.method == "login") {
      axios.post(`${global.apiAdress}/api/result-page/login`, props.formData).then(response => {
        if (response.status === 200) {
          if (response.data.status == "Logged In") {
            let communityLink = result.winner.value_result_link;
            let message = "You've sucessfully " + response.data.status;
            const userEmailData = {
              user_data_email: props.formData.email,
              user_data_message: message,
              user_data_community_link: communityLink
            }
            axios.post(`${global.apiAdress}/api/user/user-email/sendLoginSignupMessage`, userEmailData).then(response => {
              if (response.status === 200) {
              }
            })
            if (localStorage.getItem('emailPopup') != "Sent" && localStorage.getItem('emailPopup') != "Closed") {
              localStorage.setItem("emailPopup", "LoggedIn");
            }
            localStorage.setItem("loggedUser", props.formData.email);
            props.setErrorMessage('');
            dispatch({
              type: 'multiUpdate',
              state: {
                email: props.formData.email,
                currentPage: 'Profile',
                password: '',
                loggedIn: true,
                loggedUser: props.formData.email,
                errorMsg: '',
              },
            })
          }
          else if (response.data.status == "Wrong Password") {
            props.setErrorMessage('You entered a wrong password, please retry!');
            dispatch({
              type: 'multiUpdate',
              state: {
                email: '',
                password: '',
                loggedIn: false,
                loggedUser: '',
                errorMsg: 'You entered a wrong password, please retry!',
              },
            })
          }
        }
      })
    }
  }, []);


  const loadEmailData = useCallback(async (userEmail) => {
    if (!userEmail){ 
      return
    }

    const compatibleParticipants = (await axios.get(
      `${global.discordApiAddress}/communities/0/matches?email=${encodeURIComponent(userEmail)}`
    )).data;

    const data = (await axios.get(
      `${global.apiAdress}/api/discord-codes/get-id/${userEmail}`
    )).data;

    const userId = data && data.user_id && data.user_id.length ? data.user_id[0].test_user_id : '';

    dispatch({
      type: 'multiUpdate',
      state: {
        activeConnections: userId ? (await axios.get(
          `${global.apiAdress}/api/discord-codes/active-connections/${userId}`
        )).data : [],
        compatibleParticipants,
        userId,
      },
    });
  }, []);

  const logout = useCallback(() => {
    localStorage.setItem("loggedUser", "");
    dispatch({ 
      type: "multiUpdate",  
      state: {
        email: '',
        currentPage: 'Result',
      }
    });
    if (localStorage.getItem('emailPopup') != "Sent" && localStorage.getItem('emailPopup') != "Closed") {
      localStorage.setItem("emailPopup", "");
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    ...initialstate,
    email: localStorage.getItem('loggedUser') || '',
    loadSessionData,
    result: getResultsFromLocalStorage(),
    loadEmailData,
    logout,
  });

  const { email, visitorId, loading } = state;

  useEffect(() => {
    if (!visitorId) {
      return
    }
    axios.get(`${global.apiAdress}/api/result-page/user-result-details-by-uid/${visitorId}`)
    .then(response => {
      if (response.data.users_result.length > 0) {

        let traitsTable = response.data.test_traits;
        let subtraitsTable = response.data.sub_traits;

        let traitsPercentagesTable = response.data.result_traits;
        let subtraitsPercentagesTable = response.data.result_subtraits;

        let worldViewTable = response.data.result_values;

        let userResultTable = response.data.users_result;
        let userResultTableLatestResult = userResultTable[userResultTable.length - 1];
        let resultTraitsPercentagesRaw = JSON.parse(userResultTableLatestResult.result_user_data_percentages);
        let resultSubTraitsPercentagesRaw = JSON.parse(userResultTableLatestResult.result_user_data_subtrait_percentages);
        let resultWorldViewRaw = JSON.parse(userResultTableLatestResult.result_user_data_world_view_winner);
        let resultMostCommonDistributionRaw = JSON.parse(userResultTableLatestResult.result_user_data_world_view_common_distrubution);

        let extraversionData = {percentage: '0'}, 
          agreeablenessData = {percentage: '0'}, 
          conscientiousnessData = {percentage: '0'}, 
          neuroticismData = {percentage: '0'}, 
          opennessData = {percentage: '0'}, 
          machiavellianismData = {percentage: '0'},
          narcissismData = {percentage: '0'}, 
          psychopathyData = {percentage: '0'};

        // setWinner(resultWorldViewRaw)
        const winnerRaw = worldViewTable.find(
          (e) => e.id == resultWorldViewRaw.id
        );
        const winnerData = {
          card_image: resultWorldViewRaw.card_image,
          id: resultWorldViewRaw.id,
          value_id: resultWorldViewRaw.value_id,
          value_name: resultWorldViewRaw.value_name,
          description: winnerRaw ? winnerRaw.description : '',
          value_result_link: winnerRaw ? winnerRaw.value_result_link : '',
        };

        let resultTraitsPercentages = [];
        let heighestPercentage = 0;
        let heighestTrait = "";
        let bigFiveTraits = [];
        let darkTraidTraits = [];

        resultTraitsPercentagesRaw.forEach(element => {
          const traitNameRaw = traitsTable.filter(
            (e) => e.id == element.traitId
          );
          let traitName = traitNameRaw[0].trait_title;
          const currentTraitResults = traitsPercentagesTable.filter(
            (e) => e.trait_id == element.traitId
          );
          let percentage = element.percentage;


          currentTraitResults.forEach(item => {
            let scoreSplit = item.score.split("-");
            let startScore = parseInt(scoreSplit[0])
            let endScore = parseInt(scoreSplit[1])
            if (heighestPercentage < percentage) {
              if (traitName != "Machiavellianism" && traitName != "Psychopathy" && traitName != "Narcissism") {
                heighestPercentage = percentage;
                heighestTrait = traitName;
              }
            }
            if (percentage >= startScore && percentage <= endScore) {
              if (traitName == "Extraversion") {
                extraversionData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                bigFiveTraits.push(extraversionData);
              }
              else if (traitName == "Agreeableness") {
                agreeablenessData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                bigFiveTraits.push(agreeablenessData);
              }
              else if (traitName == "Conscientiousness") {
                conscientiousnessData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                bigFiveTraits.push(conscientiousnessData);
              }
              else if (traitName == "Neuroticism") {
                neuroticismData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                bigFiveTraits.push(neuroticismData);
              }
              else if (traitName == "Openness") {
                opennessData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                bigFiveTraits.push(opennessData);
              }

              if (traitName == "Machiavellianism") {
                machiavellianismData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                darkTraidTraits.push(machiavellianismData);
              }
              else if (traitName == "Narcissism") {
                narcissismData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                darkTraidTraits.push(narcissismData);
              }
              else if (traitName == "Psychopathy") {
                psychopathyData = {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
                darkTraidTraits.push(psychopathyData);
              }

              resultTraitsPercentages.push(
                {
                  test_id: element.test_id,
                  traitId: element.traitId,
                  trait_id: element.traitId,
                  result_trait_id: item.id,
                  percentage: element.percentage.toFixed(2),
                  bullets: item.bullets,
                  description: item.description,
                  traitName: traitName
                }
              )
            }
          });
        });
        
        let resultSubTraitsPercentages = [];
        resultSubTraitsPercentagesRaw.forEach(element => {
          const subtraitNameRaw = subtraitsTable.filter(
            (e) => e.id == element.subtraitId
          );
          let subtraitName = subtraitNameRaw[0].subtrait_title;
          const currentTraitResults = subtraitsPercentagesTable.filter(
            (e) => e.subtrait_id == element.subtraitId
          );
          let percent = element.percent;
          currentTraitResults.forEach(item => {
            let scoreSplit = item.score.split("-");
            let startScore = parseInt(scoreSplit[0])
            let endScore = parseInt(scoreSplit[1])
            if (percent >= startScore && percent <= endScore) {
              resultSubTraitsPercentages.push(
                {
                  subtraitId: element.subtraitId,
                  traitId: element.traitId,
                  percent: element.percent.toFixed(2),
                  result: {
                    description: item.description,
                    bullets: item.bullets
                  },
                  name: subtraitName
                }
              )
            }
          });
        });
        const resultMain = {
          resultTraitsPercentages: resultTraitsPercentages,
          resultSubTraitsPercentages: resultSubTraitsPercentages,
          extraversion: extraversionData,
          openness: opennessData,
          neuroticism: neuroticismData,
          conscientiousness: conscientiousnessData,
          agreeableness: agreeablenessData,
          machiavellianism: machiavellianismData,
          narcissism: narcissismData,
          psychopathy: psychopathyData,
          winner: winnerData,
          mostCommonDistribution: resultMostCommonDistributionRaw,
          highestTrait: heighestTrait,
          bigFiveTraits: bigFiveTraits,
          darkTraidTraits: darkTraidTraits
        }
        console.log("resultMain session", resultMain)
        localStorage.setItem("resultMain", JSON.stringify(resultMain));
        localStorage.setItem("countLoader", "1");
      }
    })
    .finally(() => {
      dispatch({
        type: 'multiUpdate',
        state: {
          result: getResultsFromLocalStorage(),
          loading: false,
        }
      })

    })
  }, [visitorId]);

  const getNextMint = useCallback(() => {
    axios.get(`${global.apiAddressBlockchain}/nfts/next-mint`).then(nextMint => {
      console.log(nextMint, "nextMint")
      dispatch({
        type: 'update',
        key: 'nextMint',
        value: nextMint.data,
      });
    })
  }, []);

  useEffect(() => {
    getNextMint();
    const interval = setInterval(getNextMint, 60000);
    return () => clearInterval(interval);
  }, [getNextMint])

  useEffect(() => {
    loadEmailData(email);
    const interval = setInterval(() => loadEmailData(email), 60000);
    return () => clearInterval(interval);
  }, [email]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('visitorId')) {
        dispatch({
          type: 'update',
          key: 'visitorId',
          value: localStorage.getItem('visitorId'),
        });  
        return
      }
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      dispatch({
        type: 'update',
        key: 'visitorId',
        value: result.visitorId
      });
      localStorage.setItem("visitorId", result.visitorId);
    })()
  }, []);

  return (
    <PageContext.Provider value={[state, dispatch]}>
      {loading?  '' : children}
    </PageContext.Provider>
  );
};

export const SessionContext = () => {
  return useContext(PageContext);
};

export default SessionProvider;
