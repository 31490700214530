import axios from "axios";
import React, { useEffect, useState } from "react";
import DarkTraid from "./DarkTraid";
import MainCards from "./MainCards";
import Personality from "./Personality";
import Login from "../LoginPage/Login";
import NextStepsButtons from "./NextStepsButtons";
import "./Result.css";
import "./Community.css";
import "./MintCards.css";
import WorldView from "./WorldView";
import Combinations from "./Combinations";
import { Modal } from 'reactstrap'

import chatPNG from "../../assets/chat.png";
import chatUser from "../../assets/chatUser.jpg";
import closeWhite from "../../assets/closeWhite.png";
import Community from "./Community";
import MintCards from "./MintCards";

import CongratsGIF from "../../assets/confetti-40.gif"
import { WalletContext } from "../store/WalletProvider";
import MintModalForm from "./MintModal/MintModalForm";

import Matches from "./Matches";
import Profile from "./Profile";
import { Layout } from "../Layout";
import { SessionContext } from "../store/SessionProvider";
import Signup from "../SignupPage/Signup";
import TraitCard from "../UI/TraitCard";

const Result = () => {
  const [ { currentPage, visitorId, result }, dispatchSession ] = SessionContext();
  const [worldViewHighestScoreNameForPdf, setWorldViewHighestScoreNameForPdf] = useState([]);
  const [worldViewCommonNameForPdf, setWorldViewCommonNameForPdf] = useState([]);
  const [bigFiveTraitsDetailsForPdf, setBigFiveTraitsDetailsForPdf] = useState([]);
  const [combosForPdf, setCombosForPdf] = useState([]);
  console.log("result in result.js", result);

  const {
    extraversion,
    openness,
    neuroticism,
    agreeableness,
    conscientiousness,
    machiavellianism,
    narcissism,
    psychopathy,
    bigFiveTraits,
    darkTraidTraits,
    bigFiveTraitsDetails,
    darkTraidTraitsDetails,
    subtraitsPercentages,
    winner,
    winnerCardName,
  } = result;

  useEffect(() => {
    if (!result || JSON.stringify(result) === '{}') {
      window.location.href = '/';
    }
  }, []);

  const [emailModal, setEmailModal] = useState(false)
  const [downgradeNFTModal, setDowngradeNFTModal] = useState(false)
  const [mintModal, setMintModal] = useState(false)
  

  const [email, setEmail] = useState("")

  const setCurrentPage = (value) => dispatchSession({
    type: 'update',
    key: 'currentPage',
    value: value,
  })

  const [chatPopup, setChatPopup] = useState(false)

  const [disablePdf, setDisablePdf] = useState(true);

  const [swapProcessing, setSwapProcessing] = useState(false)

  const [congratulationsModal, setCongratulationsModal] = useState(false)
  const [mintedNFTIdForSwap, setMintedNFTIdForSwap] = useState("")
  const [ { loadData, nfts, currentResultTokenId  } ] = WalletContext();
  const [rarities, setRarities] = useState(null);

  const getYield = (key) => {
    if (!rarities) {
      return '';
    }
    const rarity = rarities.find(r => r.key === key);
    if (!rarity) {
      return '';
    }
    return `${rarity.payouts.reduce((partialSum, a) => partialSum + a, 0)}%`;
  }

  const getPayoutLevels = (key) => {
    if (!rarities) {
      return '';
    }
    const rarity = rarities.find(r => r.key === key);
    if (!rarity) {
      return '';
    }
    return `${rarity.payouts.filter((p) => !!p).length}`;
  }

  useEffect(() => {
    axios.get(`${global.apiAddressBlockchain}/nfts/rarities`).then(rarities => {
      setRarities(rarities.data);
    })
  }, [])

  function changeMintModal() {
    setMintModal(!mintModal)
  }

  function changeDowngradeModal() {
    setDowngradeNFTModal(!downgradeNFTModal)
  }

  function changeCurrentPage(value) {
    console.log("this is the value: ", value);
    console.log("this is the current page: ", currentPage);
    let valueNow = currentPage;
    console.log(localStorage.getItem('emailPopup'))
    if (valueNow == "Result") {
      if (localStorage.getItem('emailPopup') == "" || localStorage.getItem('emailPopup') == undefined || localStorage.getItem('emailPopup') == null || (localStorage.getItem('emailPopup') != "Sent" && localStorage.getItem('emailPopup') != "Closed" && localStorage.getItem('emailPopup') != "LoggedIn")) {
        if (!email) {
          setEmailModal(true);
        }
      }
    }
    console.log("THIS IS NEW VALUE FOR CURRENTPAGE ", value)
    setCurrentPage(value);
  }

  function saveBigFiveForPdf(bigFiveTratisDetailsForPdf) {
    let bigFiveTratisDetailsForPdfValue = [];
    bigFiveTratisDetailsForPdfValue = bigFiveTratisDetailsForPdf;
    setBigFiveTraitsDetailsForPdf(bigFiveTratisDetailsForPdfValue)
  }
  function saveHighestNamesForPdf(highscoreNameValue, mostCommonNameValue) {
    setWorldViewHighestScoreNameForPdf(highscoreNameValue)
    setWorldViewCommonNameForPdf(mostCommonNameValue)
  }
  function saveCombosForPdf(comboForPdf) {
    setCombosForPdf(comboForPdf)
  }
  function downloadBothCards() {
    downloadWorldViewCard("https://pdfapi.personalitynft.com/public/images/" + winner.card_image)
    // console.log(downloadablePNGLinkValue)
    // downloadablePNGLinkValue.click();
    // downloadPersonalityPNG()
    // window.open('https://ptalaravelapi.unialsolutions.com/result/createPdf', '_blank');
    let personalityScoresArray = [];
    personalityScoresArray[0] = parseInt(Math.round((extraversion.percentage / 10.00)));
    personalityScoresArray[1] = parseInt(Math.round((openness.percentage / 10.00)));
    personalityScoresArray[2] = parseInt(Math.round((neuroticism.percentage / 10.00)));
    personalityScoresArray[3] = parseInt(Math.round((conscientiousness.percentage / 10.00)));
    personalityScoresArray[4] = parseInt(Math.round((agreeableness.percentage / 10.00)));

    let darkTraidScoresArray = [];
    darkTraidScoresArray[0] = parseInt(Math.round((machiavellianism.percentage / 10.00)));
    darkTraidScoresArray[1] = parseInt(Math.round((narcissism.percentage / 10.00)));
    darkTraidScoresArray[2] = parseInt(Math.round((psychopathy.percentage / 10.00)));
    sendPdfData(JSON.stringify(personalityScoresArray), JSON.stringify(darkTraidScoresArray));
  }
  function downloadWorldViewCard(href) {
    if (href == null) {
      return;
    }
    console.log(href);
    fetch(href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        console.log(response);
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "world_view_card.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }
  function sendPdfData(personalityScoresArray = "", darkTraidScoresArray = "") {

    // window.open("/personality_card/"+JSON.stringify(personalityScoresArray)+"/"+JSON.stringify(darkTraidScoresArray)+"/","_blank")
    // console.log(bigFiveTraitsDetailsForPdf.length)
    // return;
    let mostCommonScoreDistribution = [];
    JSON.parse(localStorage.getItem('mostCommonScoreDistribution')).forEach(element => {
      if (!((element.value_id).includes("."))) {
        mostCommonScoreDistribution.push({
          card_image: element.card_image,
          // first_Choice: element.first_Choice,
          id: element.id,
          // most_Common: element.most_Common,
          most_Common_Distribution: element.most_Common_Distribution,
          // netMostCommon: element.netMostCommon,
          // netTotalScore: element.netTotalScore,
          // second_Choice: element.second_Choice,
          // third_Choice: element.third_Choice,
          // total_Score: element.total_Score,
          total_Score_Distribution: element.total_Score_Distribution,
          // value_id: element.value_id,
          value_name: element.value_name
        })
      }
    });

    let Big5Traits = [];
    bigFiveTraitsDetailsForPdf.forEach(element => {
      Big5Traits.push({
        trait_id: element.trait_id,
        percentage: element.percentage,
        result_trait_id: element.result_trait_id
      })
    });

    let Combos = [];
    combosForPdf.forEach(element => {
      Combos.push({
        id: element.id
      })
    });

    let DarkTraidTraits = [];
    darkTraidTraitsDetails.forEach(element => {
      DarkTraidTraits.push({
        trait_id: element.trait_id,
        percentage: element.percentage,
        result_trait_id: element.result_trait_id
      })
    });

    // let SubtraitPercentages=[];
    // subtraitsPercentages.forEach(element => {
    //   SubtraitPercentages.push({
    //     id:element.id
    //   })
    // });

    let winnerData = {
      card_image: JSON.parse(localStorage.getItem('winner')).card_image,
      // first_Choice: JSON.parse(localStorage.getItem('winner')).first_Choice,
      id: JSON.parse(localStorage.getItem('winner')).id,
      // most_Common: JSON.parse(localStorage.getItem('winner')).most_Common,
      most_Common_Distribution: JSON.parse(localStorage.getItem('winner')).most_Common_Distribution,
      // netMostCommon: JSON.parse(localStorage.getItem('winner')).netMostCommon,
      // netTotalScore: JSON.parse(localStorage.getItem('winner')).netTotalScore,
      // second_Choice: JSON.parse(localStorage.getItem('winner')).second_Choice,
      // third_Choice: JSON.parse(localStorage.getItem('winner')).third_Choice,
      // total_Score: JSON.parse(localStorage.getItem('winner')).total_Score,
      total_Score_Distribution: JSON.parse(localStorage.getItem('winner')).total_Score_Distribution,
      // value_id: JSON.parse(localStorage.getItem('winner')).value_id,
      value_name: JSON.parse(localStorage.getItem('winner')).value_name,
      // value_result_link: JSON.parse(localStorage.getItem('winner')).value_result_link
    }

    const resultDataApi = {
      bigFiveTraitsDetailsForPdf: JSON.stringify(Big5Traits),
      subtraitsPercentages: JSON.stringify(subtraitsPercentages),
      darkTraidTraitsDetails: JSON.stringify(DarkTraidTraits),
      combosForPdf: JSON.stringify(combosForPdf),
      winner: JSON.stringify(winnerData),
      mostCommonScoreDistribution: JSON.stringify(mostCommonScoreDistribution),
      worldViewHighestScoreNameForPdf: JSON.stringify(worldViewHighestScoreNameForPdf),
      worldViewCommonNameForPdf: JSON.stringify(worldViewCommonNameForPdf),
      visitorId: visitorId
    }
    const resultDataSend = {
      bigFiveTraitsDetailsForPdf: bigFiveTraitsDetailsForPdf,
      subtraitsPercentages: subtraitsPercentages,
      darkTraidTraitsDetails: darkTraidTraitsDetails,
      combosForPdf: combosForPdf,
      winner: JSON.parse(localStorage.getItem('winner')),
      mostCommonScoreDistribution: mostCommonScoreDistribution,
      worldViewHighestScoreNameForPdf: worldViewHighestScoreNameForPdf,
      worldViewCommonNameForPdf: worldViewCommonNameForPdf,
      visitorId: visitorId,
      personalityScoresArray: personalityScoresArray,
      darkTraidScoresArray: darkTraidScoresArray
    }

    // return;
    console.log(JSON.stringify(Big5Traits).length)
    console.log(JSON.stringify(subtraitsPercentages).length)
    console.log(JSON.stringify(DarkTraidTraits).length)
    console.log(JSON.stringify(combosForPdf).length)
    console.log(JSON.stringify(mostCommonScoreDistribution).length)
    console.log(JSON.stringify(winnerData).length)
    console.log(JSON.stringify(visitorId).length)
    console.log(resultDataApi);
    console.log(resultDataSend);
    // return;
    axios.post(`${global.apiAdress}/api/result-page/result-for-pdf`, resultDataApi).then(response => {
      if (response.status === 200) {
        console.log(response);
        console.log(response.data.id);
        window.open('https://pdfapi.personalitynft.com/result/createPdf/' + response.data.id, '_blank');
        localStorage.setItem("resultData", JSON.stringify(resultDataSend));
        window.open("/personality_card", "_blank")


      }
    })

  }
  function sendPdfLinkAsEmail(receiverEmail) {

    let mostCommonScoreDistribution = [];
    JSON.parse(localStorage.getItem('mostCommonScoreDistribution')).forEach(element => {
      if (!((element.value_id).includes("."))) {
        mostCommonScoreDistribution.push({
          card_image: element.card_image,
          id: element.id,
          most_Common_Distribution: element.most_Common_Distribution,
          total_Score_Distribution: element.total_Score_Distribution,
          value_name: element.value_name
        })
      }
    });

    let Big5Traits = [];
    bigFiveTraitsDetailsForPdf.forEach(element => {
      Big5Traits.push({
        trait_id: element.trait_id,
        percentage: element.percentage,
        result_trait_id: element.result_trait_id
      })
    });

    let Combos = [];
    combosForPdf.forEach(element => {
      Combos.push({
        id: element.id
      })
    });

    let DarkTraidTraits = [];
    darkTraidTraitsDetails.forEach(element => {
      DarkTraidTraits.push({
        trait_id: element.trait_id,
        percentage: element.percentage,
        result_trait_id: element.result_trait_id
      })
    });

    let winnerData = {
      card_image: JSON.parse(localStorage.getItem('winner')).card_image,
      id: JSON.parse(localStorage.getItem('winner')).id,
      most_Common_Distribution: JSON.parse(localStorage.getItem('winner')).most_Common_Distribution,
      total_Score_Distribution: JSON.parse(localStorage.getItem('winner')).total_Score_Distribution,
      value_name: JSON.parse(localStorage.getItem('winner')).value_name,
    }

    const resultDataApi = {
      bigFiveTraitsDetailsForPdf: JSON.stringify(Big5Traits),
      subtraitsPercentages: JSON.stringify(subtraitsPercentages),
      darkTraidTraitsDetails: JSON.stringify(DarkTraidTraits),
      combosForPdf: JSON.stringify(combosForPdf),
      winner: JSON.stringify(winnerData),
      mostCommonScoreDistribution: JSON.stringify(mostCommonScoreDistribution),
      worldViewHighestScoreNameForPdf: JSON.stringify(worldViewHighestScoreNameForPdf),
      worldViewCommonNameForPdf: JSON.stringify(worldViewCommonNameForPdf),
      visitorId: "1"
    }

    // return;
    axios.post(`${global.apiAdress}/api/result-page/result-for-pdf`, resultDataApi).then(response => {
      if (response.status === 200) {
        console.log(response);
        console.log(response.data.id);
        let pdfLink = 'https://pdfapi.personalitynft.com/result/createPdf/' + response.data.id;
        let worldViewLink = "https://pdfapi.personalitynft.com/public/images/" + JSON.parse(localStorage.getItem('winner')).card_image;
        let message = "PDF/Cards Email";
        const userEmailData = {
          user_data_email: receiverEmail,
          user_data_message: message,
          user_data_pdf_link: pdfLink,
          user_data_world_view_link: worldViewLink
        }
        axios.post(`${global.apiAdress}/api/user/user-email/sendPdfCardsEmail`, userEmailData).then(response => {
          if (response.status === 200) {
          }
        })
        // window.open('https://ptalaravelapi.unialsolutions.com/result/createPdf/' + response.data.id, '_blank');

      }
    })

  }
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  function signupEmail() {
    if (validateEmail(email)) {
      let communityLink = JSON.parse(localStorage.getItem('winner')).value_result_link;
      let message = "Pop-up Email";
      const userEmailData = {
        user_data_email: email,
        user_data_message: message,
        user_data_community_link: communityLink
      }
      axios.post(`${global.apiAdress}/api/user/user-email/sendLoginSignupMessage`, userEmailData).then(response => {
        if (response.status === 200) {
          setEmailModal(!emailModal);
          localStorage.setItem("emailPopup", "Sent");
        }
      })

    }
  }

  console.log("result", result)
  if (!result || JSON.stringify(result) === '{}') {
    return `no result`;
  }
  return (
    <Layout showMenu>
      {
        currentPage == "Community" ?
          <Community winner={winner} bigFiveTraitsDetails={bigFiveTraitsDetails} darkTraidTraitsDetails={darkTraidTraitsDetails} extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism} />
        : currentPage == "Login" ?
          <Login sendPdfLinkAsEmail={sendPdfLinkAsEmail} changeCurrentPage={changeCurrentPage} />
        : currentPage == "Signup" ?
          <Signup changeCurrentPage={changeCurrentPage} />
        : currentPage == "Matches" ?
          <Matches changeCurrentPage={changeCurrentPage} />
        : currentPage == "Profile" ?
          <Profile changeCurrentPage={changeCurrentPage} />
        : currentPage == "MintCards" ? 
          <MintCards 
            changeDowngradeModal={changeDowngradeModal}
            getYield={getYield}
            getPayoutLevels={getPayoutLevels}
            fileName="result.js" changeMintModal={changeMintModal} disablePdf={disablePdf} bigFive={bigFiveTraits} darkTraid={darkTraidTraits}   bigFiveTraitsDetails={bigFiveTraitsDetails} darkTraidTraitsDetails={darkTraidTraitsDetails} extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism} winner={winner} sendPdfData={sendPdfData} changeCurrentPage={changeCurrentPage} />
        : (
          <div className="text-center">
            <MainCards            
              getYield={getYield}
              getPayoutLevels={getPayoutLevels}
              fileName="result.js" changeMintModal={changeMintModal} disablePdf={disablePdf} bigFive={bigFiveTraits} darkTraid={darkTraidTraits}   bigFiveTraitsDetails={bigFiveTraitsDetails} darkTraidTraitsDetails={darkTraidTraitsDetails} extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism} winner={winner} sendPdfData={sendPdfData} changeCurrentPage={changeCurrentPage} />
            <div className="px-3 px-md-5 text-left">
              <h5 className="resultWelcomeTags">
                Skip Ahead:
                <a href="#PersonalityTestResultSection"> Personality Results</a> |
                <a href="#DarkTriadTestResultSection"> Dark Triad Results</a> |
                <a href="#WorldViewTestResultSection"> Archetype Results</a> |
                <a href="#NextStepsSection"> Next Steps</a>
              </h5>
            </div>

            { bigFiveTraits && bigFiveTraits.length > 0 && darkTraidTraits && darkTraidTraits.length > 0 ? (
              <div id="PersonalityTestResultSection">
                <Personality bigFive={bigFiveTraits} darkTraid={darkTraidTraits}   bigFiveTraitsDetails={bigFiveTraitsDetails} darkTraidTraitsDetails={darkTraidTraitsDetails} extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism} subtraitsPercentages={subtraitsPercentages} saveBigFiveForPdf={saveBigFiveForPdf} />
                <Combinations bigFiveTraitsDetails={bigFiveTraitsDetails} saveCombosForPdf={saveCombosForPdf} />
              </div>
            ) : null}

            { bigFiveTraits && bigFiveTraits.length > 0 && darkTraidTraits && darkTraidTraits.length > 0 ? (
              <div id="DarkTriadTestResultSection">
                <DarkTraid bigFive={bigFiveTraits} darkTraid={darkTraidTraits}   bigFiveTraitsDetails={bigFiveTraitsDetails} darkTraidTraitsDetails={darkTraidTraitsDetails} extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism} />
              </div>
            ) : null}
            <div id="WorldViewTestResultSection">
              <WorldView saveHighestNamesForPdf={saveHighestNamesForPdf} />
            </div>
            <div id="NextStepsSection">
              <NextStepsButtons winner={winner} changeCurrentPage={changeCurrentPage} downloadBothCards={downloadBothCards} />
            </div>
          </div>
        )
      }

      <Modal style={{ borderTopRightRadius: 40, color: "white", textAlign: "center" }} contentEditable={false} isOpen={emailModal} toggle={() => setEmailModal(!emailModal)} className='modalEmail modal-lg modal-dialog-centered' contentClassName="modalEmail" >
        <div>
          <svg className="close closeModalBtn btnMargins" style={{ cursor: "pointer", height: 40, width: 40, fontWeight: "lighter", color: "#fff", position: 'absolute', top: "-10%", right: "-10%" }} onClick={() => {
            localStorage.setItem("emailPopup", "Closed");
            setEmailModal(!emailModal)
          }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
            <style>
              {
                `.cls-1
                    {
                      fill:#fff
                    }
                    @media only screen and (max-width: 576px) {
                      .btnMargins{
                        right:'15%';
                      }
                    }
                    `
              }
            </style>
            <path className="cls-1 btnMargins" d="M28.7,134.3l-6.9-6.8,6.9-6.8-1.4-1.4-6.8,6.9-6.8-6.9-1.4,1.4,6.9,6.8-6.9,6.8,1.4,1.4,6.8-6.9,6.8,6.9Z" transform="translate(0 -107)" />
            <path className="cls-1 btnMargins" d="M20.5,107A20.5,20.5,0,1,0,41,127.5,20.5,20.5,0,0,0,20.5,107Zm0,39.5a19,19,0,1,1,19-19A19,19,0,0,1,20.5,146.5Z" transform="translate(0 -107)" />
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="modalEmailTitle">Save your Results </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p className="modalEmailDescription">Get your Personality Cards and Test Results PDF right in your email!
                We will also send you an invite to a community of people who share your values and personality traits!</p>
            </div>
          </div>
          <div className="modalEmailFooter row mt-5 mb-5">
            <div className="col-lg-8 col-md-12 modalEmailFooterCol1">
              <input type="email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} className="modalEmailText" placeholder="Enter Email  " />
            </div>
            <div className="col-lg-4 col-md-12 modalEmailFooterCol2 mt-xl-0 mt-lg-0 mt-3">
              <button className="modalEmailSignupButton" onClick={() => {
                signupEmail()
              }} >Sign Up</button>
            </div>

          </div>
        </div>
      </Modal>
      {/* Downgrade NFT Modal */}
      <Modal style={{ borderTopRightRadius: 40, color: "white", textAlign: "center" }} contentEditable={false} isOpen={downgradeNFTModal} toggle={() => setDowngradeNFTModal(!downgradeNFTModal)} className='modalDowngrade modal-lg modal-dialog-centered' contentClassName="modalDowngrade" >
        <div>
          <svg className="close closeModalBtn btnMargins" style={{ cursor: "pointer", height: 40, width: 40, fontWeight: "lighter", color: "#fff", position: 'absolute', top: "0%", right: "-5%" }} onClick={() => {
            setDowngradeNFTModal(!downgradeNFTModal)
          }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
            <style>
              {
                `.cls-1
                    {
                      fill:#fff
                    }
                    @media only screen and (max-width: 576px) {
                      .btnMargins{
                        right:'15%';
                      }
                    }
                    `
              }
            </style>
            <path className="cls-1 btnMargins" d="M28.7,134.3l-6.9-6.8,6.9-6.8-1.4-1.4-6.8,6.9-6.8-6.9-1.4,1.4,6.9,6.8-6.9,6.8,1.4,1.4,6.8-6.9,6.8,6.9Z" transform="translate(0 -107)" />
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="modalDowngradeTitle">Switch to {localStorage.getItem('rarityForSwap')} </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <p className="modalDowngradeDescription">Looks like you've more than one Personality NFT in your Wallet.
                Please select the Personality NFT you want to switch:</p>
            </div>
          </div>
          <div className="row ml-1">
            {nfts ? nfts.map((element, index) => {
              console.log("element", element  )
              const rarityAttribute = element.metadata.attributes.find(a => a.trait_type === 'Rarity');
              if (!rarityAttribute) {
                return null;
              }
              const rarity = rarityAttribute.value;
              return <div className={index / 2 != 0 || index == nfts.length ? "col-md-6 mt-2 modalDowngroundMainColumn1" : "col-md-6 mt-2"} style={{ borderWidth: mintedNFTIdForSwap == element.token_id ? 2 : 0, borderColor: mintedNFTIdForSwap == element.token_id ? "red" : "none", borderRadius: mintedNFTIdForSwap == element.token_id ? 17 : 0, borderStyle: "solid" }} onClick={(e) => {
                setMintedNFTIdForSwap(element.token_id)
              }}>
                <div className="row">
                  <div className="col-md-6">
                    <TraitCard
                      animation_url={element.animation_url}
                      height="130px"
                      hideMessage
                    />
                  </div>
                  <div className="col-md-6 pl-0 pr-0 mt-2 text-left">
                    <p className="modalDowngradeValuesText">Rarity: {rarity}</p>
                    <p className="modalDowngradeValuesText">Bonus: {getYield(rarity)}</p>
                    <p className="modalDowngradeValuesText">Payout Levels: {getPayoutLevels(rarity)}</p>
                    <p className="modalDowngradeValuesText">Mint #: {element.token_id}</p>
                  </div>
                </div>
              </div>
            }) : null}
          </div>
          <div className="modalEmailFooter row mt-3 mb-3">

            <div className="col-lg-12 col-md-12 mt-xl-0 mt-lg-0 mt-3 text-center align-items-center">
              <button className="modalDowngradeRarityButton pt-1 pb-1 ml-4 mt-4" onClick={() => {
                setSwapProcessing(true)
                if (mintedNFTIdForSwap) {
                  // return
                  axios.post(`${global.apiAddressBlockchain}/nfts/swap?tokenId=${mintedNFTIdForSwap}&mintPassTokenId=${localStorage.getItem('tokenIdForSwap')}`).then(response => {
                    console.log(response)
                    if (response.data.success == true) {
                      setMintedNFTIdForSwap("")
                      setDowngradeNFTModal(!downgradeNFTModal)
                      setSwapProcessing(false)
                      loadData();
                      alert("Token swap compeleted successfully!")
                    }
                  })
                    .then(function (error) {
                      console.log(error)
                      setSwapProcessing(false)
                      loadData();
                    })
                }
                else {
                  alert("Please select token to swap");
                  setSwapProcessing(false)
                }

                // 
              }} >{swapProcessing == false ? 'Switch my Personality NFT' : "Processing..."}</button>
            </div>

          </div>
        </div>
      </Modal>
      {/* Congratulations Modal */}
      <Modal style={{ background: CongratsGIF, color: "white", textAlign: "center" }} contentEditable={false} isOpen={congratulationsModal} toggle={() => setCongratulationsModal(!congratulationsModal)} className='modalCongrats modal-lg modal-dialog-centered' contentClassName="modalCongrats" >
        <div>
          <svg className="close closeModalBtn btnMargins" style={{ cursor: "pointer", height: 40, width: 40, fontWeight: "lighter", color: "#fff", position: 'absolute', top: "0%", right: "-5%" }} onClick={() => {
            setCongratulationsModal(congratulationsModal)
            setCurrentPage("MintCards")
          }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
            <style>
              {
                `.cls-1
                    {
                      fill:#fff
                    }
                    @media only screen and (max-width: 576px) {
                      .btnMargins{
                        right:'15%';
                      }
                    }
                    `
              }
            </style>
            <path className="cls-1 btnMargins" d="M28.7,134.3l-6.9-6.8,6.9-6.8-1.4-1.4-6.8,6.9-6.8-6.9-1.4,1.4,6.9,6.8-6.9,6.8,1.4,1.4,6.8-6.9,6.8,6.9Z" transform="translate(0 -107)" />
          </svg>
        </div>
        <div className="container" >
          <div className="row">
            <div className="col-sm-12 pt-5">
              <h2 id="mintButtonsYourResult" className="resultYourResultHeadingCongrats" >Congrats!</h2>
              <h2 id="mintButtonsYourResult" className="resultYourResultTitleCongrats" >You Got MINT # {currentResultTokenId}</h2>
              <p className="modalDowngradeDescriptionCongrats mt-5">Did you know you can Earn ETH with your Personality NFT?</p>
              <p className="modalDowngradeDescriptionCongrats mb-3">Check out the NFTs Tab!</p>
            </div>
          </div>
        </div>
      </Modal>

      {chatPopup == false ?
        <img hidden src={chatPNG} alt="Chat Icon" onClick={(e) => {
          setChatPopup(!chatPopup);
        }} className="open-button"></img>
        : <img src={closeWhite} alt="Chat Close Icon" onClick={(e) => {
          setChatPopup(!chatPopup);
        }} className="open-button"></img>
      }
      {chatPopup == true ?

        <div class="chat-popup" id="myForm">
          {/* <div className="container"> */}
          <div className="row">
            <div className="col-md-12">
              <div className="headerChat">
                <h5 className="chatHeading pt-3 ml-3">Personality NFT</h5>
                <p className="chatIsOperatorActive ml-3 pb-3">No Operator Online</p>
              </div>
            </div>
          </div>
          <div className="messageArea">
            <div className="row">
              <div className="col-md-2">
                <img src={chatUser} alt="User1" className="userIcon ml-3 mb-2" />
              </div>
              <div className="col-md-9 ml-md-2 mr-md-2 ml-sm-2 mr-sm-2 ml-xs-2 mr-xs-2">
                <p className="chatMessageUser1">Hello! How can we help you?</p>
                <p className="chatMessageUser1">We are currently offline. In order to answer you, please indicate your email in your messages.</p>
                <p className="chatTime">11:48 PM</p>
                <p className="chatDate">May 19, 2022</p>
                <p className="chatMessageUser2">We are currently offline. In order to answer you, please indicate your email in your messages.</p>
                <p className="chatTime">11:48 PM</p>

              </div>
            </div>
          </div>
          <hr className="" />
          <div className="row">
            <div className="col-md-8">
              <textarea className="chatMessageInput" placeholder="Write your message..." rows={1} />
            </div>
            <div className="col-md-1">
            </div>
            <div className="col-md-1">
              <button type="submit" class="sendButton">SEND</button>
            </div>
          </div>
          {/* </div> */}
        </div>

        : null}
      {/* THIS IS WHERE THE MINT MODAL CODE EXIST */}
      <Modal style={{ color: "white", textAlign: "center" }} contentEditable={false} isOpen={mintModal} toggle={() => setMintModal(!mintModal)} className='modalEmail mintModal mintModalSize modal-xl modal-dialog-centered'>
        <div>
          <svg className="close closeModalBtn btnMargins" style={{ cursor: "pointer", height: 40, width: 40, fontWeight: "lighter", color: "white", position: 'absolute' }} onClick={() => {
            setMintModal(!mintModal)
          }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
            <style>
              {
                `.cls-1
                  {
                    fill:#fff
                  }
                  @media only screen and (max-width: 576px) {
                    .btnMargins{
                      right:'15%';
                    }
                  }
                `
              }
            </style>
            <path className="cls-1 btnMargins" d="M28.7,134.3l-6.9-6.8,6.9-6.8-1.4-1.4-6.8,6.9-6.8-6.9-1.4,1.4,6.9,6.8-6.9,6.8,1.4,1.4,6.8-6.9,6.8,6.9Z" transform="translate(0 -107)" />
            <path className="cls-1 btnMargins" d="M20.5,107A20.5,20.5,0,1,0,41,127.5,20.5,20.5,0,0,0,20.5,107Zm0,39.5a19,19,0,1,1,19-19A19,19,0,0,1,20.5,146.5Z" transform="translate(0 -107)" />
          </svg>
        </div>
        <div className="container">
          <MintModalForm 
            getYield={getYield}
            getPayoutLevels={getPayoutLevels}
            winnerCardName={winnerCardName}
            setCurrentPage={setCurrentPage} changeMintModal={changeMintModal}
            darkTraidTraitsDetails={darkTraidTraitsDetails} 
            extraversion={extraversion} openness={openness} neuroticism={neuroticism} agreeableness={agreeableness} 
            conscientiousness={conscientiousness} narcissism={narcissism} psychopathy={psychopathy} machiavellianism={machiavellianism}
            visitorId={visitorId}
            winner={winner} setCongratulationsModal={setCongratulationsModal}
          />
        </div>
      </Modal>
    </Layout>
  );
};

export default Result;
