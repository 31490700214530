
import React, { Fragment, useEffect, useState } from "react";

import "./Community.css";
import axios from "axios";
import { SessionContext } from "../store/SessionProvider";

const tableHeaders = ["Community Name", "Date Joined", "Connection Status"];

const Community = (props) => {
  const [ { loadEmailData, activeConnections, userId, email, result }] = SessionContext();
  const [ registrationCode, setRegistrationCode] = useState('');
  const winner = result ? result.winner : null;

  const setCodeAsUsed = async (code) => {
    console.log("user id: ", userId);
    const response = await axios.post(
      `${global.apiAdress}/api/discord-codes/set-used/${code}/${userId}`
    );

    if (!response) {
      console.log("updating record failed");
      return;
    }
  }

  async function getCode(code) {
    const response = await axios.get(
      `${global.apiAdress}/api/discord-codes/${code}`
    );
    return response;
  }

  const registerUser = async (row) => {
    const code = row[0].code;
    const repsonse = await setCodeAsUsed(code, userId);

    // since code was used and user is registered, update the active connections list
    await loadEmailData(email);

    //probably set some UI stuff next...
  }

  async function handleRegistration() {
    if (registrationCode == "") {
      console.log("no registration code entered");
      return;
    }

    const response = await getCode(registrationCode);
    if (!response) {
      console.log("no response");
      return;
    }

    if (response.status != 200) {
      console.log("error with registration code call");
      return;
    }

    const row = response.data.row_with_code;
    const codeFound = response.data.row_with_code.length; // length will only ever be 1 or 0

    if (codeFound == 1) {
      // row is found, need to get user id from database
      registerUser(row);

    } else if (codeFound == 0) {
      console.log("no registration code found");
      //TODO inform user that code was not found
    }
  }

  async function deRegister(code) {
    const response = await axios.delete(
      `${global.apiAdress}/api/discord-codes/deregister/${code}`
    );

    if (response.status == 200) {
      loadEmailData(email);
    } else {
      console.log("degistration failed");
    }
  }

  return (
    <Fragment>
      {/* <!-- worlds view --> */}
      <div className="container communityContainer">
        {/* communities div */}
        <div className="mt-4">
          <h4 className="communityLinksSecondHeading">Communities</h4>
          <div className="row gx-5 pb-4 pb-md-4">
            <p className="communityCommonParas2 col-sm">This is your personalized group. People here share your goals & values and can help you on your journey.</p>
            <button onClick={(e) => {
              winner && window.open(winner.value_result_link, '_blank');
            }} className="mintButtonRedCommunity1 col-sm">Join {winner ? winner.value_name : ''} Community</button>
          </div>
        </div>

        {/* bootcamp div */}
        <div>
          <h4 className="communityLinksSubHeading">3 Free Bootcamps</h4>
          <div className="row gx-8 pb-4 pb-md-4">
            <p className="communityCommonParas2 col-sm">Discover how to use the Cards in your life in our Free Workshops on Self Mastery & Big 5 Personality Traits.</p>
            <button onClick={(e) => {
              winner && window.open(winner.value_result_link, '_blank');
            }} className="mintButtonRedCommunity1 col-sm">Join Workshops</button>
          </div>
        </div>

        {/* discord and web3 div */}
        <div className="">
          <h4 className="communityLinksSecondHeading">Discord &#38; Web3.0</h4>
          <div className="row gx-8">
            <p className="communityCommonParas2 col-sm">Find friends in any Discord or Web3.0 community using the Friend Finder Bot or our APIs by entering your code.</p>
            <div className="col-sm registrationGroup">
              <div className="input-group mb-3" style={{ maxWidth: "606px" }}>
                <input type="text" className="form-control" placeholder="Enter Discord registration code" onChange={e => setRegistrationCode(e.target.value) && e.target.value == ""} />
                <div className="input-group-append">
                  <button className="btn btn-outline-success registerButton" type="button" onClick={handleRegistration}>Register</button>
                </div>
              </div>
            </div>
          </div>
          {/* community tools */}
          <div className="row ml-0 w-md-50 pb-4 pt-2 align-items-center d-block d-md-flex communityTools">
            <h5 className="text-left pr-4 communityLinksSubHeading pb-2 pb-md-0" style={{color: "white", marginBottom: "0px"}}>Community Tools</h5>
            <button className="btn btn-primary mr-2 communityToolsButton" onClick={() => window.open("https://discord.com/api/oauth2/authorize?client_id=1032296724041048137&permissions=8&scope=bot")}>Get the Bot</button>
            <button className="btn btn-primary ml-2 communityToolsButton">Get the API</button>
          </div>
          <div className="row justify-content-center">
            <div className="table-responsive">
              <table className="text-left table w-100 d-block d-md-table connectionsTable" style={{ color: "white", display: "block", overflowX: "auto", whiteSpace: "nowrap" }}>
                <thead className="">
                  <tr>
                    {tableHeaders.map((item, index) => {
                      return (
                        <th key={index} scope="row" >{item}</th>
                      )
                    })}
                  </tr>
                </thead>
                <tbody>
                  {activeConnections && activeConnections.connections ? activeConnections.connections.map((item, index) => {
                    return (
                      <tr key={index} >
                        <td>{item.guild_name}</td>
                        <td>{item.created_at}</td>
                        <td><button className="connectedButton emailAccountId text-uppercase" onClick={() => deRegister(item.code)} >On</button></td>
                      </tr>
                    )
                  }) : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- worlds view end --> */}\
    </Fragment>
  );
};

export default Community;
