import React from "react";
import Header from "../header/Header";
import classes from "./CommunityPage.module.css";
import resultCardMain1 from "../../assets/resultCardMain1.webp";

const CommunityPage = () => {
  return (
    <div className="container-fluid  h-100">
      <div className="row h-100">
        <div className="col-11  px-0 mx-auto h-100 ">
          <div className={` ${classes.mainBoxWrapper}`}>
            <Header />
            <div className={`container ${classes.mainBox}`}>
              <div className={` ${classes.mainBoxAfter}`}>
                <h2 className={`${classes["communityMain"]}`}>Community</h2>
                <p className={` mb-5 px-3 ${classes["communityMainPara"]}`}>
                  The links before are personalized to your results. Now, you
                  can join a community of like minded friends!
                </p>
                <div className="container">
                  <div className="row">
                    <div className="col-md-5 text-center text-md-right">
                      <img
                        className="img-fluid"
                        src={resultCardMain1}
                        alt="maincard1"
                      />
                    </div>
                    <div className="col-md-7">
                      <h4 className={`mb-5 ${classes["communitySubHead"]}`}>
                        Your Community Links:
                      </h4>
                      <p className={`${classes["communitySubPara"]}`}>
                        &gt; General <a href="link">Community</a> &
                        <a href="link">Discord</a>
                      </p>
                      <p className={`${classes["communitySubDis"]}`}>
                        Group Area for Everyone
                      </p>
                      <p className={`${classes["communitySubPara"]}`}>
                        &gt; Identity: Extroversion
                      </p>
                      <p className={`${classes["communitySubDis"]}`}>
                        Go to Profile &gt; Identity &gt; Select Identity
                      </p>
                      <p className={`${classes["communitySubPara"]}`}>
                        &gt; Group: <a href="link"> Achiever Group </a>
                      </p>
                      <p className={`${classes["communitySubDis"]}`}>
                        This group shares your goals & values. Follow link for
                        access.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityPage;
