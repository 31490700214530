import axios from "axios";
import React, { useEffect, useState } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { Context } from "../store/StateProvider";
let apiComboData = [];

const Combinations = (props) => {
  // states
  const [state, dispatch] = Context();
  const [combos, setCombos] = useState([]);
  // states end

  // data fetching from combos api
  const comboLoad = async () => {
    // console.log("ABC")
    const response = await axios.get(
      `${global.apiAdress}/api/big-five/combo-result/index`
    );
    if (response.status === 200) {
      apiComboData = response.data.combos;
      // console("ABC12")
      comboCalc();
    }
  };
  // data fetching from combos api end

  // calculations for combos
  const comboCalc = () => {
    if (state.test1Result.length > 0) {
      let comboPercentage = [];

      // merging calculated percentages into api data
      state.test1Result.forEach((element) => {
        comboPercentage = apiComboData.map((e) => {
          const FId = parseInt(e.first_trait_id);
          const SID = parseInt(e.second_trait_id);
          if (FId === element.traitId) {
            e.first_trait_percentage = element.percentage;
            return e;
          } else if (SID === element.traitId) {
            e.second_trait_percentage = element.percentage;
            return e;
          } else {
            return e;
          }
        });
      });
      // merging calculated percentages into api data end

      // splitting percentages
      const spiltedPercentages = comboPercentage.map((e) => {
        const FAScore = e.first_trait_score.split("-");
        const SAScore = e.second_trait_score.split("-");
        const FStartScore = parseInt(FAScore[0]);
        const FEndScore = parseInt(FAScore[1]);
        const SStartScore = parseInt(SAScore[0]);
        const SEndScore = parseInt(SAScore[1]);
        e.FirstStartScore = FStartScore;
        e.FirstEndScore = FEndScore;
        e.SecondStartScore = SStartScore;
        e.SecondEndScore = SEndScore;
        return e;
      });
      // splitting percentages end
      const filteredData = spiltedPercentages.filter(
        (e) =>
          e.first_trait_percentage >= e.FirstStartScore &&
          e.first_trait_percentage <= e.FirstEndScore &&
          e.second_trait_percentage >= e.SecondStartScore &&
          e.second_trait_percentage <= e.SecondEndScore
      );
      if (filteredData.length > 0) {
        props.saveCombosForPdf(filteredData)
        setCombos(filteredData);
      }
    } else {
      console.log("No data found");
    }
  };
  // calculations for combos end

  // useEffect
  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    comboLoad();
  }, []);
  // useEffect end

  const handleScroll = () => {

    const resultTraitCombHeadTitle = document.querySelector("#resultTraitCombHeadTitle");
    if (resultTraitCombHeadTitle != null) {
      if (resultTraitCombHeadTitle.getBoundingClientRect().top > 0 && resultTraitCombHeadTitle.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultTraitCombHeadTitle.classList.add('resultYourResultHeadingAnim');
      }
    }

    const resultTraitCombHeadCombo = document.querySelector("#resultTraitCombHeadCombo");
    if (resultTraitCombHeadCombo != null) {
      if (resultTraitCombHeadCombo.getBoundingClientRect().top > 0 && resultTraitCombHeadCombo.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultTraitCombHeadCombo.classList.add('resultYourResultHeadingAnim');
      }
    }




  };

  return (
    <div className="container">
      {combos.length > 0 ? 
      <div className="col-12 mb-5">
        <h3 className="my-4 resultTraitCombHead" id="resultTraitCombHeadTitle">Trait Combinations</h3>
        <p className="resultTraitCombPara">
          No one is just one trait, we are all a mix. You can think of these traits as colors, and when you mix two colors together you get a third.
        </p>
        <p className="resultTraitCombPara">
          Some trait combinations are complementary and produce a beautiful combination while other trait combinations produce conflicting motivations. For example, one part of you may want to do something while another part of you doesn’t.
        </p>
        <p className="resultTraitCombPara">
          Now let’s look at some of your trait combinations.
        </p>
      </div>
      : null }
      {combos.map((e) => (
        <div key={e.id} className="col-12 mb-5">
          <h3 className="my-4 resultTraitCombHead" id="resultTraitCombHeadCombo">
            {e.first_trait_name} & {e.second_trait_name}
          </h3>
          <p className="resultTraitCombPara"><div dangerouslySetInnerHTML={{ __html: e.description }} /></p>
        </div>
      ))}
      {/* <!-- trait Combinations end --> */}
    </div>
  );
};

export default Combinations;
