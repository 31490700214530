import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "./index.css";
import StateProvider from "./components/store/StateProvider";
import Reducer, { initialstate } from "./components/store/Reducer";
import WalletProvider from "./components/store/WalletProvider";
import WalletReducer, { initialWalletState } from "./components/store/WalletReducer";
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from "@ethersproject/providers";
import SessionProvider from "./components/store/SessionProvider";
import SessionReducer, { initialSessionState } from "./components/store/SessionReducer";
// import Web3 from "web3";
function getLibrary(provider) {
  return new Web3Provider(provider);
}

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <StateProvider reducer={Reducer} initialstate={initialstate}>
      <WalletProvider reducer={WalletReducer} initialstate={initialWalletState}>
        <SessionProvider reducer={SessionReducer} initialstate={initialSessionState}>
          <App />
        </SessionProvider>
      </WalletProvider>
    </StateProvider>
  </Web3ReactProvider>,
  document.getElementById("root")
);
