import P1 from "../assets/PersonalityTraitsNums/1.png";
import P2 from "../assets/PersonalityTraitsNums/2.png";
import P3 from "../assets/PersonalityTraitsNums/3.png";
import P4 from "../assets/PersonalityTraitsNums/4.png";
import P5 from "../assets/PersonalityTraitsNums/5.png";
import P6 from "../assets/PersonalityTraitsNums/6.png";
import P7 from "../assets/PersonalityTraitsNums/7.png";
import P8 from "../assets/PersonalityTraitsNums/8.png";
import P9 from "../assets/PersonalityTraitsNums/9.png";
import P10 from "../assets/PersonalityTraitsNums/10.png";

import D1 from "../assets/PersonalityTraitsNums/1.png";
import D2 from "../assets/PersonalityTraitsNums/2.png";
import D3 from "../assets/PersonalityTraitsNums/3.png";
import D4 from "../assets/PersonalityTraitsNums/4.png";
import D5 from "../assets/PersonalityTraitsNums/5.png";
import D6 from "../assets/PersonalityTraitsNums/6.png";
import D7 from "../assets/PersonalityTraitsNums/7.png";
import D8 from "../assets/PersonalityTraitsNums/8.png";
import D9 from "../assets/PersonalityTraitsNums/9.png";
import D10 from "../assets/PersonalityTraitsNums/10.png";

export const PersonalityTrait = ({ trait, x, y, width, height, negative }) => {
  if (!trait) {
    return null;
  }

  const { percentage } = trait;
  if (typeof percentage === 'undefined') {
    return null;
  }
  return (
    <image href={
      parseInt(Math.round((percentage / 10.00))) == "1" ? negative ? P1 : D1
      : parseInt(Math.round((percentage / 10.00))) == "2" ? negative ? P2 : D2
      : parseInt(Math.round((percentage / 10.00))) == "3" ? negative ? P3 : D3
      : parseInt(Math.round((percentage / 10.00))) == "4" ? negative ? P4 : D4
      : parseInt(Math.round((percentage / 10.00))) == "5" ? negative ? P5 : D5
      : parseInt(Math.round((percentage / 10.00))) == "6" ? negative ? P6 : D6
      : parseInt(Math.round((percentage / 10.00))) == "7" ? negative ? P7 : D7
      : parseInt(Math.round((percentage / 10.00))) == "8" ? negative ? P8 : D8
      : parseInt(Math.round((percentage / 10.00))) == "9" ? negative ? P9 : D9
      : parseInt(Math.round((percentage / 10.00))) == "10" ? negative ? P10 : D10
      : ''} 
    x={x} y={y} width={width} height={height} /> 
  );
}
