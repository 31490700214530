import React, { Fragment, useState, useEffect } from "react";

import { WalletContext } from "../store/WalletProvider";

import { SessionContext } from "../store/SessionProvider";
import TraitCard from "../UI/TraitCard";

const MainCards = (props) => {

  const [highestTrait, setHighestTrait] = useState("");

  const [hiddenPdfButton, setHiddenPdfButton] = useState(true);
  const [{ address, rarity, currentResultTokenId }] = WalletContext();
  const [ { email, result }] = SessionContext();  
  const { winnerCardName } = result;

  useEffect(() => {
    
    setTimeout(function () {
      if (props.bigFiveTraitsDetails.length > 0) {
        let data = props.bigFiveTraitsDetails.sort((a, b) => b.percentage - a.percentage);
        // console.log("data big5", data)
        setHighestTrait(data[0].traitName)
        const card = document.querySelector(".card__inner");
        if (card != null && card != undefined) {
          card.classList.toggle('is-flipped');
        }
      }

    }, 3000);

    setTimeout(function () {
      setHiddenPdfButton(false);
    }, 8000)
    // convertSVGtoLink();


    // setTimeout(function(){
    //   drawCanvas();
    // },3000)
  }, [props.bigFiveTraitsDetails]);

  function downloadBothCards() {
    let personalityScoresArray = [];
    personalityScoresArray[0] = parseInt(Math.round((props.extraversion.percentage / 10.00)));
    personalityScoresArray[1] = parseInt(Math.round((props.openness.percentage / 10.00)));
    personalityScoresArray[2] = parseInt(Math.round((props.neuroticism.percentage / 10.00)));
    personalityScoresArray[3] = parseInt(Math.round((props.conscientiousness.percentage / 10.00)));
    personalityScoresArray[4] = parseInt(Math.round((props.agreeableness.percentage / 10.00)));

    let darkTraidScoresArray = [];
    darkTraidScoresArray[0] = parseInt(Math.round((props.machiavellianism.percentage / 10.00)));
    darkTraidScoresArray[1] = parseInt(Math.round((props.narcissism.percentage / 10.00)));
    darkTraidScoresArray[2] = parseInt(Math.round((props.psychopathy.percentage / 10.00)));

    // window.open("/personality_card/"+JSON.stringify(personalityScoresArray)+"/"+JSON.stringify(darkTraidScoresArray)+"/","_blank")
    downloadWorldViewCard("https://pdfapi.personalitynft.com/public/images/" + props.winner.card_image)
    // setTimeout(function () {
    props.sendPdfData(JSON.stringify(personalityScoresArray), JSON.stringify(darkTraidScoresArray));
    // }, 15000);
  }
  function downloadWorldViewCard(href) {
    if (href == null) {
      return;
    }
    console.log(href);
    fetch(href, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        console.log(response);
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "world_view_card.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <Fragment>
      <div class="container mt-5 text-center align-items-center align-content-center">
        <div className="row">
          <div className="col-md-6 text-left align-items-start align-content-start pl-sm-0 iframeCenter">
            <div>
              {
                !!props && <TraitCard 
                  conscientiousness={props.conscientiousness}
                  agreeableness={props.agreeableness}
                  openness={props.openness}
                  extraversion={props.extraversion} 
                  neuroticism={props.neuroticism}
                  narcissism={props.narcissism}
                  psychopathy={props.psychopathy}
                  machiavellianism={props.machiavellianism}
                  winnerCardName={winnerCardName}
                />
              }
            </div>
          </div>
          <div className="col-md-6 mainResultDescription align-self-center">
            <h2 id="mintButtonsYourResult" className="resultYourResultHeading" >Your Results</h2>
            <div className="container mt-4" style={{ paddingRight: '0px', textAlign: 'left' }}>
              <p className="resultFirstLine">Highest Trait: <span>{(highestTrait != "" && highestTrait != null && highestTrait != undefined) ? highestTrait : ""}</span></p>
              <p className="resultFirstLine mt-md-n3 mt-0">Archetype: <span>{props.winner.value_name}</span></p>
              <p className="resultFirstLine">Perks</p>
              <ol className="resultFirstLine" style={{fontWeight: 'normal', fontSize: 16, lineHeight: `30px`, maxWidth: 380, marginLeft: 15}}>
                <li style={{marginBottom: 10}}>Minting gives you ownership of your Personality NFT & you’ll become one of the 1st people in history to Mint their
Personality on the Blockchain.</li>
                <li style={{marginBottom: 10}}>You can use the NFT in our Communities, Tools, and multiple Metaverses & 3rd Party Apps.</li>
                <li style={{marginBottom: 10}}>Full Access to Matches & Filtering</li>
                <li style={{marginBottom: 10}}>Access to our VIP Community & Exclusive Content</li>
                <li>NFT will unlock ‘Experiences’ & give you access to the Game of Life</li>
              </ol>
              <br />
              <button onClick={() => {
                if (address) {
                  props.changeMintModal()
                }
                else {
                  window.callback = () => props.changeMintModal();
                  document.dispatchEvent(new Event('signin'));
                }
              }} className="mintButtonRed">Mint my Personality NFT</button>
              <p className="mintButtonsSmallPara text-left mt-3">Need help Minting? <a href="https://www.personalitynft.com/minting" target="_blank">Learn More</a></p>

              {/* <p className="mintButtonsSmallPara text-left mt-2">* After you Login we will email you your results, cards, PDF and Save your Minting Spot giving you a first dibs opportunity to Mint Super Rare.</p> */}
              {/* <p className="mintButtonsSmallPara text-left mt-md-n2 mt-0">*Personality NFT will be used in the Game of Life.</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row px-3 px-md-5">
          <div className="col-12 my-5 text-left">

            <h2 id="mintButtons3Gifts" className="mintButtonsGiftHeading mt-3">We have 3 Gifts</h2>
            <div className="mt-4">
              <div className="row">
                <div className="col-md-7 col-sm-12">
                  <p className="resultSecondLine">1. Download your Cards & Results PDF</p>
                </div>
                <div className="col-md-5 col-sm-12 text-center">
                  <button hidden={hiddenPdfButton} onClick={(e) => { downloadBothCards() }} className="mintButtonWhite pl-4 pr-4">Download Cards & PDF</button>
                  <div hidden={!hiddenPdfButton} class="spinner-border"></div>
                </div>
                <div className="col-md-7 col-sm-12 mt-5">
                  <p className="resultSecondLine">2. Meet friends who share your Goals</p>
                </div>
                <div className="col-md-5 col-sm-12 mt-5 text-center">
                  <button onClick={(e) => {
                    window.open(props.winner.value_result_link, '_blank');
                  }} className="mintButtonWhite pl-3 pr-3">Join {props.winner != undefined ? props.winner.value_name : ""} Community</button>
                </div>
                <div className="col-md-7 col-sm-12 mt-5">
                  <p className="resultSecondLine ">3. Discover how to use the Cards & achieve your Goals in the Hero’s Journey Workshop</p>
                </div>
                <div className="col-md-5 col-sm-12 mt-5 text-center">
                  <button onClick={(e) => {
                    window.open('https://playground.gameoflife.co/share/0Hw_RRKnRzLFkInV?utm_source=manual', '_blank');
                  }} className="mintButtonWhite pl-4 pr-4">Hero’s Journey Workshop</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden className="py-5 px-3 px-md-5 text-left">
        <h3 id="resultWelcomeHeadA" className="resultWelcomeHead">
          Welcome
        </h3>
        <p className="resultWelcomePara">
          Congratulations, you just finished one of the most comprehensive psychometrics tests! This test has three parts, the Big 5 (also known as Five Factor Analysis), the Dark Triad Test and Values Test.
        </p>
        <p className="resultWelcomePara">
          The Big 5, is the only scientifically valid personality test. It breaks down your personality across five dimensions and is the only test that is able to predict future behavior. These five dimensions impact how we perceive the world, what we notice and what strategies we use to move forward in the world and accomplish our goals.
        </p>
        <p className="resultWelcomePara">
          The Values test is based on the latest developmental psychology. It helps you identify what you value in life. Values determine how we see ourselves and the world and can help us identify our main goals in life. By combining these tests, you’ll have clarity on what values are most meaningful to you and how to move towards them using your personality traits.
        </p>
      </div>
    </Fragment>
  );
};

export default MainCards;
