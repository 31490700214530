
import React, { Fragment, useEffect, useState } from "react";
import { WalletContext } from "../store/WalletProvider";
import "./Profile.css";
import axios from "axios";
import { getPartialAddress } from "../../utils/string";
import { SessionContext } from "../store/SessionProvider";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../firebaseConfig";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

const DEFAULT_PROFILE_IMAGE = "https://static.wixstatic.com/media/39fa66_9e6838a515c64c71a76cc9f9f4b042b6~mv2.jpg/v1/crop/x_421,y_1119,w_3184,h_3774/fill/w_522,h_608,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/2.jpg";

const Profile = ({ changeCurrentPage }) => {
  const [{ address }] = WalletContext();
  const [{ email, selectedAddress, result }] = SessionContext();
  const [loadingProfileData, setLoadingProfileData] = useState(false);
  const [userGender, setUserGender] = useState("");
  const [newUserGender, setNewUserGender] = useState("");
  const [twitterHandle, setTwitterHandle] = useState("");
  const [newTwitterHandle, setNewTwitterHandle] = useState("");
  const [discordHandle, setDiscordHandle] = useState("");
  const [newDiscordHandle, setNewDiscordHandle] = useState("");
  const [profileImage, setProfileImage] = useState(DEFAULT_PROFILE_IMAGE);
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { bigFiveTraitsDetails } = result;

  useEffect(() => {
    (async () => {
      if (!email) {
        return;
      }
      setLoadingProfileData(true);
      await getProfileDetails();
      setLoadingProfileData(false);
    })();
  }, [email]);

  function beginProfileEdit() {
    setNewTwitterHandle(twitterHandle);
    setNewDiscordHandle(discordHandle);
    setNewUserGender(userGender);
    setShowModal(true);
  }

  function cancelProfileEdit() {
    setNewUserGender("");
    setNewTwitterHandle("");
    setNewDiscordHandle("");
    setNewProfileImage("");
    setShowModal(false);
  }

  async function getProfileDetails() {
    const userDetails = await axios.get(
      `${global.apiAdress}/api/user/getDetailsByEmail/${email}`
    );
    if (!userDetails || !userDetails.data || !userDetails.data.userDetails || !userDetails.data.userDetails.length) {
      return;
    }

    let gender = userDetails.data.userDetails[0].gender;
    if (gender != null) {
      gender = gender[0].toUpperCase() + gender.substring(1);
    }
    const twitter = userDetails.data.userDetails[0].twitter;
    const discord = userDetails.data.userDetails[0].discord;
    const profileImage = userDetails.data.userDetails[0].profile_image;
    if (!profileImage) {
      setProfileImage(DEFAULT_PROFILE_IMAGE);
    } else {
      setProfileImage(profileImage);
    }
    setUserGender(gender);
    setTwitterHandle(twitter);
    setDiscordHandle(discord);
  }

  async function handleSubmit() {
    let profileImageURL = ""
    if (newProfileImage) {
      const fileExtension = newProfileImage.type.split("/")[1];
      profileImageURL = await storeProfileImage(fileExtension);
    } else {
      profileImageURL = profileImage; // keep the old profile picture
    }

    const response = await axios.put(`${global.apiAdress}/api/user/updateDetailsByEmail/${email}`, {
      email: email,
      twitter: newTwitterHandle,
      discord: newDiscordHandle,
      gender: newUserGender,
      profileImage: profileImageURL
    });
    setShowModal(false);
    getProfileDetails();
  }

  async function storeProfileImage(fileExtension) {
    const storageRef = ref(storage, "/images/" + uuidv4() + "." + fileExtension);
    let profileImageURL = "";
    await uploadBytes(storageRef, newProfileImage).then(async (snapshot) => {
      await getDownloadURL(storageRef).then((url) => {
        profileImageURL = url;
      });
    }).catch((error) => {
      console.log("photo upload error: ", error);
    });

    return profileImageURL;
  }

  return (
    <Fragment>
      <div className="profileContainer">
        <div>
          <div className="profileAvatar">
            <img className="imgAvatar" src={profileImage} alt="profileAvatar" />
          </div>
          <div className="mt-3 text-center" style={{ maxWidth: '400px' }}>
            {
              bigFiveTraitsDetails && bigFiveTraitsDetails.length > 0 ? (
                <p style={{ fontWeight: 'bold', fontSize: '1.15rem' }}>{bigFiveTraitsDetails[0]?.traitName} & {bigFiveTraitsDetails[1]?.traitName}</p>
              ) : (
                <p style={{ fontWeight: 'bold', fontSize: '1.15rem' }}>Please take quiz</p>
              )
            }
          </div>
        </div>

        <div className="profileDetails">
          <div className="">
            <Modal isOpen={showModal} className="edit-profile-modal">
              <ModalHeader toggle={() => setShowModal(false)}>Edit Profile Details</ModalHeader>
              <ModalBody>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail" onLoad={() => setNewUserGender(userGender)}>
                    <Form.Label>Sex</Form.Label>
                    <br></br>
                    <Form.Check
                      inline
                      type="radio"
                      id="default-radio"
                      label="Male"
                      name="gender-form"
                      defaultChecked={userGender === "Male"}
                      onClick={() => setNewUserGender("male")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="default-radio1"
                      label="Female"
                      name="gender-form"
                      defaultChecked={userGender === "Female"}
                      onClick={() => setNewUserGender("female")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="default-radio2"
                      label="Other"
                      name="gender-form"
                      defaultChecked={userGender === "Other"}
                      onClick={() => setNewUserGender("other")}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      id="default-radio3"
                      label="Prefer not to say"
                      name="gender-form"
                      defaultChecked={userGender === "Prefer not to say"}
                      onClick={() => setNewUserGender("prefer not to say")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formTwitter">
                    <Form.Label>Twitter (optional)</Form.Label>
                    <Form.Control type="text" defaultValue={twitterHandle} onChange={(e) => setNewTwitterHandle(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formDiscord">
                    <Form.Label>Discord (optional)</Form.Label>
                    <Form.Control type="text" defaultValue={discordHandle} onChange={(e) => setNewDiscordHandle(e.target.value)} />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formProfileImage">
                    <Form.Label>Profile Image</Form.Label>
                    <br></br>
                    <Form.Control type="file" onChange={(e) => setNewProfileImage(e.target.files[0])} />
                  </Form.Group>
                  <div className="">
                    <Button variant="primary" onClick={() => handleSubmit()} className="mr-2">
                      Submit
                    </Button>
                    <Button variant="danger" onClick={() => cancelProfileEdit()}>
                      Cancel
                    </Button>
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          </div>
          <div>
            <div className="d-flex flex-column flex-md-row align-items-center" style={{gap: "5px"}}>
              <p className="profileTitle d-inline">Profile Details</p>
              {!loadingProfileData ? (
                <button className="d-inline btn btn-primary communityToolsButton ml-4" onClick={() => beginProfileEdit()} >
                  Edit profile
                </button>
              ) : null}
            </div>
            <div className="profileData">
              <p>Email: <span>{email}</span></p>
              <p style={{ fontWeight: 'bold' }}>Wallet Address:
                {
                  address ? (
                    <span style={{ letterSpacing: '2px', fontWeight: 'normal' }}> {getPartialAddress(address)}</span>
                  ) : null
                }
                {
                  !address || address === selectedAddress ? (
                      <button className="d-inline btn btn-primary communityToolsButton ml-4" onClick={(e) => document.dispatchEvent(new Event('signin'))} >
                        Connect Metamask
                      </button>
                  ) : null
                }
              </p>
              {twitterHandle ? (
                <p>Twitter: <span>{twitterHandle}</span></p>
              ) : null}
              {discordHandle ? (
                <p>Discord: <span>{discordHandle}</span></p>
              ) : null}
              {userGender != "" ? (
                <p>Sex: <span>{userGender}</span></p>
              ) : null}

              {loadingProfileData ? (
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : null}
            </div>
            {
              address ? (
                <div className="profileBtnGroup">
                  <button className="profileConnectedBtn pt-2 pb-2" onClick={() => changeCurrentPage('MintCards')} >
                    See Earnings
                  </button>
                  <button className="profileConnectedBtn pt-2 pb-2" onClick={() => changeCurrentPage('MintCards')}>
                    Claim Payout
                  </button>
                </div>
              ) : null
            }
            {/* <div className="matchSettings">
              <p className="matchTitle">Match Settings</p>
              <p>Allow matches to see your email address.</p>
            </div> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
