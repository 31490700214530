import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import classes from "./../test2/Test2.module.css";

const SelectBox = (props) => {
  // states

  // states end

  // First Choice change Handler
  const firstChoicechangeHandler = (boxValue, event) => {
    props.box1(event, boxValue);
  };

  // Second choice change Handler
  const secondChoicechangeHandler = (boxValue, event) => {
    props.box2(event, boxValue);
    // const dropDownRemove = dropDown.filter((e) => e.label !== event.label);
    // setDropDown(dropDownRemove);
  };

  // Third choice change Handler
  const thirdChoicechangeHandler = (boxValue, event) => {
    props.box3(event, boxValue);
    // const dropDownRemove = dropDown.filter((e) => e.label !== event.label);
    // setDropDown(dropDownRemove);
  };

  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',
  
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 20,
      height: 10,
      width: 10,
    },
  });

  // custom styling
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: "1px solid white",
      marginTop:-2,
      padding: 10,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0,0.5)",
      color: "rgba(255,255,255,1)",
      cursor: "pointer",
      zIndex: 9999,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: "100%",
      backgroundColor: "rgba(0, 0, 0,0.5)",
      display: "flex",
      color: "rgba(255, 255, 255, 1)",
      border: "1px solid white",
      fontSize: 20,
      
    }),
    input: (styles) => ({ ...styles, ...dot() }),
    singleValue: (provided, state) => {
      const color = "rgba(255,255,255,1)";
      return { ...provided, color };
    },
    menu: (provided, state) => ({
      ...provided,
      backgroundColor:"black",
      marginTop:-40,
      paddingBottom:5,
      textAlign:"left",
    }),
    menuList: (provided, state) => ({
      ...provided,
      overflow:"visible",
      display: "inline-block",
      width:"100%",
      height:"100%",

    }),
  };
  // custom styling end

  return (
    <Fragment>
      {/* first choice */}
      <div className="text-center my-4">
        <div className={`${classes["selectBoxWrapper"]}`}>
          <div className={`${classes["selectBoxBefore"]}`}>
            <h4>
              <span className={`${classes["selectBoxBeforeSpan1"]}`}>1st Choice</span>
              <span className={`${classes["selectBoxBeforeSpan2"]}`}>1st</span>
            </h4>
          </div>
          <div className={`${classes["selectBox"]}`}>
            <Select
              options={props.options}
              styles={customStyles}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  neutral0: "rgba(0,0,0,1)",
                  primary50: "rgba(255,255,255,0.5)",
                },
              })}
              onChange={firstChoicechangeHandler.bind(null, 1)}
              // onClick={(e)=>{
              //   console.log("Clicked"+props.id)
              // }}
              // isDisabled={!props.isEnabled}
            />
          </div>
        </div>
      </div>
      {/* second choice */}
      <div className="text-center my-4">
        <div className={`${classes["selectBoxWrapper"]}`}>
          <div className={`${classes["selectBoxBefore"]}`}>
            <h4>
            <span className={`${classes["selectBoxBeforeSpan1"]}`}>2nd Choice</span>
              <span className={`${classes["selectBoxBeforeSpan2"]}`}>2nd</span>
            </h4>
          </div>
          <div className={`${classes["selectBox"]}`}>
            <Select
              options={props.options}
              styles={customStyles}
              isSearchable={false}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  neutral0: "rgba(0,0,0,1)",
                  primary50: "rgba(255,255,255,0.5)",
                },
              })}
              onChange={secondChoicechangeHandler.bind(null, 2)}
              // isDisabled={!props.isEnabled}
            />
          </div>
        </div>
      </div>
      {/* third choice */}
      <div className="text-center my-4">
        <div className={`${classes["selectBoxWrapper"]}`}>
          <div className={`${classes["selectBoxBefore"]}`}>
            <h4>
            <span className={`${classes["selectBoxBeforeSpan1"]}`}>3rd Choice</span>
              <span className={`${classes["selectBoxBeforeSpan2"]}`}>3rd</span>
            </h4>
          </div>
          <div className={`${classes["selectBox"]}`}>
            <Select
              options={props.options}
              isSearchable={false}
              styles={customStyles}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  neutral0: "rgba(0,0,0,1)",
                  primary50: "rgba(255,255,255,0.5)",
                },
              })}
              onChange={thirdChoicechangeHandler.bind(null, 3)}
              // isDisabled={!props.isEnabled}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SelectBox;
