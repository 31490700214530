import axios from "axios";
import React, { useEffect, useState } from "react";

import "./Earnings.css";
import twitter from "../assets/twitter.png";
import instagram from "../assets/instagram.png";
import discord from "../assets/discord_social.png";
import facebook from "../assets/facebook.png";
import EtheriumImage from "../assets/block.png"

const Earnings = (props) => {
  const [attributes, setAttributes] = useState(
    {
      totalSales: 0,
      lifetimeEarningsEther: 0,
      lostEarnings: 0,
      lostEarningsDollars: 0,
      unclaimedPayoutEther: 0,
      lifetimeEarnings: 0,
      unclaimedPayout: 0,
      claimPayoutProcessing: 0,
      etherPrice: 0,
      dollarMintAmount: 0,
      directSales: 0,
      quaternarySales: 0,
      earningsAtLevel: 0,
      currentPrice: 0,
    }
  );

  const [yields, setYields] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [rarities, setRarities] = useState([]);
  const [mainCardQuery, setmainCardQuery] = useState(false);
  const [levelsQuery1, setlevelsQuery1] = useState(false);
  const [NetPriceQuery, setNetPriceQuery] = useState(false);
  const [metadata, setMetadata] = useState(null);
  const [rarity, setRarity] = useState('');


  const [earningsList, setEarningsList] = useState([])
  const [tokenId, setTokenId] = useState("")
  const [tokenNotMinted, setTokenNotMinted] = useState(false)

  useEffect(() => {
    getTotalYieldFromRarity();
  }, [rarities])

  useEffect(() => {
    if (!rarity) {
      return
    }
    axios.get(`${global.apiAddressBlockchain}/nfts/rarities`).then(rarities => {
      setRarities(rarities.data)
      console.log("rarities", rarities.data)
      for (let i = 0; i < rarities.data.length; i++) {
        if (rarities.data[i].key == rarity) {
          const payouts = rarities.data[i].payouts;
          console.log("payouts", payouts)
          setYields(payouts);
          return;
        }
      }
    })
  }, [rarity]);

  useEffect(() => {
    let splits = window.location.href.split("earnings/")
    let tokenIdValue = splits[1]
    setTokenId(tokenIdValue)
    axios.get(`${global.apiAddressBlockchain}/metadata/personality/${tokenIdValue}`).then(responseMetaData => {
      console.log("responseMetaData", responseMetaData.data);
      setMetadata(responseMetaData.data);
      setTokenNotMinted(!responseMetaData.data);
      callPaymentAndRewards(tokenIdValue);
      if (responseMetaData.data) {
        const rarityAttribute = responseMetaData.data.attributes.find(a => a.trait_type === 'Rarity');
        if (rarityAttribute) {
          setRarity(rarityAttribute.value);
        }
      }
    })

  }, [props]);

  function callPaymentAndRewards(tokenIdValue) {
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`).then(response => {

      let dollarAmount = response.data[1].current_price * 0.05;
      let currentPrice = response.data[1].current_price
      axios.get(`${global.apiAddressBlockchain}/nfts/rewards-per-token?tokenId=${tokenIdValue}`).then(responseReward => {
        console.log("responseReward", responseReward)
        let rewards_list = responseReward.data;
        console.log("rewards_list", rewards_list)
        setEarningsList(rewards_list)

        let totalEth = 0;
        let totalEthUnpaid = 0;
        let totalPotential = 0;
        rewards_list.forEach(element => {
          totalEth = totalEth + (element.amount);
          if (element.pontential) {
            totalPotential += element.pontential;
          } else {
            totalPotential += element.amount;
          }
          if (element.paid == false) {
            totalEthUnpaid = totalEthUnpaid + (element.amount)
          }
        });


        let reward_in_dollars = currentPrice * totalEth;
        let fixedNum = 2;
        if (reward_in_dollars < 10) {
          fixedNum = 6;
        }
        else {
          fixedNum = 2;
        }

        let reward_in_dollars_unpaid = currentPrice * totalEthUnpaid;
        if (reward_in_dollars_unpaid < 10) {
          fixedNum = 6;
        }
        else {
          fixedNum = 2;
        }
        setAttributes({
          ...attributes,
          etherPrice: response.data[1].current_price,
          dollarMintAmount: dollarAmount,
          totalSales: rewards_list.length,
          lifetimeEarningsEther: totalEth.toFixed(2),
          lifetimeEarnings: reward_in_dollars.toFixed(fixedNum),
          unclaimedPayoutEther: totalEthUnpaid.toFixed(fixedNum),
          unclaimedPayout: reward_in_dollars_unpaid.toFixed(fixedNum),
          lostEarnings: (totalPotential - totalEth).toFixed(2),
          currentPrice: currentPrice,
        });
      })
    })
  }
  function getTotalYieldFromRarity(rarityLevel) {
    console.log(rarityLevel, rarities)
    let totalYield = 0;

    if (!rarities) {
      return;
    }

    for (let i = 0; i < rarities.length; i++) {
      if (rarities[i].key === rarityLevel) {
        for (let j = 0; j < rarities[i].payouts.length; j++) {
          totalYield += rarities[i].payouts[j];
        }
        console.log("totalYield", totalYield)
        return totalYield;
      }
    }

    //user's rarity was not found in the list of rarities from api call, error
    console.error("Rarity not found");
    return -1;
  }

  function getTotalPayoutsFromRarity(rarityLevel) {
    if (!yields) {
      return;
    }

    let totalPayouts = 0;
    for (let i = 0; i < yields.length; i++) {
      if (yields[i] > 0) {
        totalPayouts++;
      }
    }

    return totalPayouts;
  }

  function getDirectSalesForLevel(level) {
    if (!earningsList) {
      console.error("Earnings list not loaded");
      return -1;
    }

    let directSales = 0;
    for (let i = 0; i < earningsList.length; i++) {
      if (earningsList[i].level === level) {
        directSales++;
      }
    }
    return directSales;
  }

  function getEthEarningsForLevel(level) {
    if (!earningsList) {
      console.error("Earnings list not loaded");
      return -1;
    }

    let ethEarnings = 0;
    for (let i = 0; i < earningsList.length; i++) {
      if (earningsList[i].level === level) {
        ethEarnings += earningsList[i].amount;
      }
    }
    return ethEarnings.toFixed(4);
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }

  const levels = [1, 2, 3, 4, 5, 6, 7];

  if (!tokenNotMinted && !metadata) {
    return null;
  }

  return (
    <div className="earningsBody">
      {tokenNotMinted == false ?
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="earningMainCard1 ml-0">
                <p className="earningTitle" style={{ fontWeight: "bold" }}>Earning</p>

                <label className="earningAmount">
                  <img src={EtheriumImage} alt="EtherImage" width="50px" />
                  <span style={{ position: "relative" }}>{attributes.lifetimeEarningsEther} ETH
                    <div className="earningSubAmount">
                      ($ {attributes.lifetimeEarnings} USD)
                    </div>
                  </span>
                </label>
                <div className="row earningMainCard1Items">
                  <div className="col-md-4 col-sm-6 pl-1 pr-3">
                    <p className="earningAmountSecondary mb-0">{attributes.totalSales}</p>
                    <hr className="shadowBlue mt-0" />
                    <p className="earningSmallHeading mt-2">Total Sales</p>
                  </div>
                  <div className="col-md-4 col-sm-6 pl-1 pr-3">
                    <p className="earningAmountSecondary mb-0"><img src={EtheriumImage} alt="EtheriumImage" width="20px" /> {attributes.unclaimedPayoutEther} ETH</p>
                    <hr className="shadowGreen mt-0" />
                    <p className="earningSmallHeading d-flex flex-column mt-2">
                      <span>Unclaimed Payout</span>
                      <span className="earningSmallSubHeading">(${attributes.unclaimedPayout})</span>
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-6 pl-1 pr-3">
                    <p className="earningAmountSecondary mb-0"><img src={EtheriumImage} alt="EtheriumImage" width="20px" /> {attributes.lostEarnings} ETH</p>
                    <hr className="shadowRed mt-0" />
                    <p className="earningSmallHeading d-flex flex-column mt-2">
                      <span style={{ position: "relative" }}>Lost Earnings <i className="fa-solid fa-question queryIcon" onClick={() => setmainCardQuery(true)}></i>
                        <div className={mainCardQuery ? "trueQueryIconDescription" : "falseQueryIconDescription"}>
                          This is the amount you have lost due to Rarity.To increase earnings upgrade your Rarity.
                          <i className="fa-solid fa-xmark queryDescriptionCloseIcon" onClick={() => setmainCardQuery(false)}></i>
                        </div>
                      </span>
                      <span className="earningSmallSubHeading">(${attributes.lostEarningsDollars})</span>
                    </p>
                  </div>
                </div>

              </div>
              <div className="earningTempCard">
                <h4 className="earningByLevelHeading mt-4 ml-2 float-left">Earning By Level</h4>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="earningMainCard mr-0">
                <div className="row">
                  <div className="col-lg-6 pr-2 earningImageCol">
                    <img style={{width: '100%'}} src={metadata ? metadata.image : ''} />
                  </div>
                  <div className="col-lg-6 pr-0">
                    <p className="imageValues mt-1"><span>Rarity:</span> {rarity}</p>
                    <p className="imageValues"><span>Mint #:</span> {tokenId}</p>
                    <p className="imageValues"><span>Bonus:</span> {getTotalYieldFromRarity(rarity)}%</p>
                    <p className="imageValues"><span>Levels:</span> {getTotalPayoutsFromRarity(rarity)}</p>
                  </div>

                </div>
                <h4 className="myNFTSecondHeadingEarning mt-4 ml-2 text-left">Discount Link: Give 10% and Get Bonus </h4>
                <div className="lifeTimeEarningsWhiteBackgroundEarning ml-2 mt-1 pl-1 pr-1 pb-1">
                  <label className="myNFTHeadingAddressLabelEarning mt-1 pt-1">{global.baseUrl}/discount-code/#{tokenId}</label>
                  <button className="myNFTSeeDetailsEarningButtonEarning pl-1 pr-1 pr-md-0 mt-1" onClick={() => {
                    navigator.clipboard.writeText(global.baseUrl + '/discount-code/#' + tokenId)
                  }} >Copy </button>
                </div>
                <div className="text-center mt-2">
                  <img onClick={(e) => {
                    window.open("https://twitter.com/intent/tweet?url=https://app.personalitynft.com/&text=Recently[…]20maybe%20we%20are%20a%20match:%20app.personalitynft.com/%0A%0A", "_blank")
                  }} className="socialMediaIcon mt-2 mr-2" src={twitter} />
                  <img onClick={(e) => {
                    window.open("https://www.instagram.com/personality_nft/", "_blank")
                  }} className="socialMediaIcon mt-2 mr-2" src={instagram} />
                  <img onClick={(e) => {
                    window.open("https://discord.gg/fepxdrpFvP", "_blank")
                  }} className="socialMediaIcon mt-2 mr-2" src={discord} />
                  <img onClick={(e) => {
                    window.open("https://www.facebook.com/sharer/sharer.php?u=https://app.personalitynft.com/", "_blank")
                  }} className="socialMediaIcon mt-2 mr-2" src={facebook} />
                </div>
                <h4 className="myNFTSecondSmallHeadingEarning mt-3 text-center">Upgrade and Downgrade Rarity & Bonus with Rarity Cards </h4>
                <button className="btnRarityCardsOpensea pt-1 pb-1" onClick={() => {
                   window.open("https://opensea.io/collection/personality-nft-rarity-cards-v2", "_blank")
                }} >Rarity Cards on Opensea</button>
              </div>
            </div>

          </div>
          <div className="row handleSmallCardWrap">

            {/* render level cards */}
            {levels.map((level, index) =>
              yields[index] > 0 ?
                (
                  <div className="col-md-2 col-sm-4 col-xs-12 mt-2 mt-md-0 mt-lg-0 handleSmallCard">
                    <div className="smallCard">
                      <h4 className="smallCardH1 mt-2 text-center">Level {level}</h4>
                      <h4 className="smallCardH2 mt-1 text-center">Active</h4>
                      <h4 className="smallCardH3 mt-1 text-center d-flex flex-column">
                        <span>{getEthEarningsForLevel(level)} ETH</span>
                        <span className="smallCardSubText">(${(getEthEarningsForLevel(level) * attributes.currentPrice).toFixed(2)})</span>
                      </h4>
                      <h4 className="smallCardH4 mt-1 text-left">Direct Sales: {getDirectSalesForLevel(level)}</h4>
                      <h4 className="smallCardH5 mt-1 text-left">Bonus: {yields[index]}%</h4>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 col-sm-4 col-xs-12 mt-2 mt-md-0 mt-lg-0 handleSmallCard">
                    <div className="smallCard smallCardRed">
                      <h4 className="smallCardH1 mt-2 text-center">Level {level}</h4>
                      <h4 className="smallCardH2 smallCardH2Red mt-1 text-center" style={{ position: "relative" }}>Not Earning
                        <i className="fa-solid fa-question queryIcon" onClick={() => setlevelsQuery1(true)}></i>
                        <div className={levelsQuery1 ? "trueLevel2IconDescription" : "falseLevel2IconDescription"}>
                          You are not earning on this level due to Rarity. To increase earnings upgrade your Rarity.
                          <i className="fa-solid fa-xmark queryDescriptionCloseIcon" onClick={() => setlevelsQuery1(false)}></i>
                        </div>
                      </h4>
                      <h4 className="smallCardH3 smallCardH3Gray mt-1 text-center d-flex flex-column">
                        <span>{getEthEarningsForLevel(level)} ETH</span>
                        <span className="smallCardSubText">(${(getEthEarningsForLevel(level) * attributes.currentPrice).toFixed(2)})</span>
                      </h4>
                      <h4 className="smallCardH4 mt-1 text-left">Quaternary Sales: {attributes.quaternarySales}</h4>
                      <h4 className="smallCardH5 mt-1 text-left">Bonus: {yields[index]}%</h4>
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="row mt-4 mb-5">
            <div className="col-12">
              <div className="transactionsCard">
                <h4 className="transactionsTitle mt-2 text-center">Transactions</h4>
                <div className="transactionTitleLineDiv">
                  <hr className="transactionTitleLine" />
                </div>
                <div className="tableTransactionDiv">
                  <table className="tableTransaction mt-5">
                    <thead className="tableHeader">
                      <th className="tableHeading">Date</th>
                      <th className="tableHeading">Earning Rarity</th>
                      <th className="tableHeading">Level</th>
                      <th className="tableHeading" style={{ position: "relative" }}>Net Price <i className="fa-solid fa-question queryIcon" onClick={() => setNetPriceQuery(true)}></i>
                        <div className={NetPriceQuery ? "trueNetPriceIconDescription" : "falseNetPriceIconDescription"}>
                          Net price is the purchase price minus discounts and fees.Price updates daily to reflect changes in the exchange rate.
                          <i className="fa-solid fa-xmark queryDescriptionCloseIcon" onClick={() => setNetPriceQuery(false)}></i>
                        </div></th>
                      <th className="tableHeading">Earnings</th>
                    </thead>
                    <tbody>

                      {earningsList.map((element, index) => {
                        console.log("element", element)
                        return <tr className="tableRow">
                          <td className="tableData tableDataRemoveLeftBorder">{formatDate(element.createdAt)}</td>
                          <td className="tableData">{element.rarity}</td>
                          <td className="tableData">{element.level == null ? 1 : element.level == 0 ? "Cash Back" : element.level}</td>
                          <td className="tableData">
                            <div className="d-flex flex-column">
                              <span>{element.amount}ETH</span>
                              <span>(${(element.amount * attributes.etherPrice).toFixed(2)})</span>
                            </div>
                          </td>
                          <td className="tableData">
                            <div className="d-flex flex-column">
                              <span>{element.amount}ETH</span>
                              <span>(${(element.amount * attributes.etherPrice).toFixed(2)})</span>
                            </div>
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </div>
                <div hidden={earningsList.length < 50} className="col-12 text-center">
                  <span className="TransactionCardPaginationBtn">Next 50</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="container-fluid">
          <h4 className="earningByLevelHeading mt-4 ml-2 text-center">This Token Number Is Not Minted!</h4>
        </div>
      }
    </div>
  );
};

export default Earnings;
