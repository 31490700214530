import React, { Fragment, useEffect, useState } from "react";
import classes from "./Header.module.css";
import axios from "axios";
import { WalletContext } from "../store/WalletProvider";
import { Context } from "../store/StateProvider";
import { Flip } from "./Flip";
import { networks } from "../../utils/chains";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { SessionContext } from "../store/SessionProvider";

const Header = (props) => {
  const { connect, disconnect } = props;

  const [{ testData, startTest, Hpercent, test3Data }, dispatch] = Context();

  const [{ web3Provider, address, chain }] = WalletContext();

  const [{ currentPage, email, selectedAddress }, dispatchSession] = SessionContext();

  const changeCurrentPage = (value) => dispatchSession({
    type: 'update',
    key: 'currentPage',
    value: value,
  })
  const [totalQuestions, setTotalQuestions] = useState(0);
  // const [percent, setPercent] = useState(0);
  // copied
  // header percentage logic
  useEffect(() => {
    const simultinous = async () => {
      const simulti = await axios.all([
        axios.get(`${global.apiAdress}/api/user/get-all-questions`),
      ]);
      const result = simulti.map((a) => {
        return a.data.questions;
      });

      const filteredResult = result.map((e) => {
        let ali = e.length;
        return ali;
      });

      const sum = filteredResult.reduce((a, b) => a + b, 0);

      setTotalQuestions(sum);
      // setTotalQuestions(sum - 1);
      // console.log(sum, "sum");
    };
    simultinous();
  }, []);

  useEffect(() => {
    let solvedQuestions = testData.length;
    let solvedQuestionsTest3 = test3Data.length / 3;

    solvedQuestions += solvedQuestionsTest3;
    // totalQuestions += solvedQuestionsTest3;
    if (totalQuestions != 0) {
      // console.log("test3Data", test3Data);
      // console.log("solvedQuestions", solvedQuestions);
      // console.log("totalQuestions", totalQuestions + solvedQuestionsTest3);
      let percentage = (solvedQuestions / (totalQuestions + solvedQuestionsTest3)) * 100;
      // console.log("percentage", percentage);
      if (percentage >= 0 && percentage <= 100) {
        // setPercent(Math.round(percentage)); a
        let percent = Math.round(percentage);
        percent = percent.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
        dispatch({ type: "percentage", resultDataa: percent });
      }
    }

  }, [testData, totalQuestions, dispatch]);
  // header percentage logic end
  // copied

  const changeNetwork = async ({ network }) => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: network.chainId,
          },
        ],
      });
      window.location.reload();
    } catch (err) {
      if (err.code === 4902) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              network,
            },
          ],
        });
      }
    }
  };

  const handleNetworkChange = async () => {
    const network = networks[0];
    await changeNetwork({ network });
  };

  const getPartialAddress = (address, front = 5, back = 3) => {
    if (!address) {
      return "";
    }
    return (
      address.substr(0, front) +
      "..." +
      address.substr(address.length - back, address.length)
    );
  };


  return (
    <Fragment>
      {
        web3Provider && !chain && (
          <div className="alert alert-danger container">
            You are connected to invalid network. Please switch network in order to mint your NFT.
            <button onClick={handleNetworkChange} className="btn btn-link">Switch Network</button>
          </div>
        )
      }

      {/* navbar-expand-lg */}
      <nav
        style={{ marginLeft: "auto", marginRight: "auto" }}
        id="navbar"
        className={`navbar container navigation-style navbar-dark  ${props.isResult == true ? classes["navigation-style-result"] : classes["navigation-style"]} ${classes["navbar"]}`}
      >
        <a
          className={`navbar-brand ${classes["navbar-brand"]} ${props.disableHeaderLinks ? classes["navbar-brand-disabled"] : classes["navbar-brand-enabled"]}`}
          href="/"
          style={{ pointerEvents: props.disableHeaderLinks ? "none" : "all" }}
        >
          {"Personality NFT"}
        </a>

        {props.isResult == true ?
          <div className={`${classes["resultLinkParent"]} ${props.isResult == true ? classes["resultLinkParentNone"] : null}`}>
            <DropdownButton
              variant="secondary"
              title={
                !email ? "Login" :
                  !web3Provider ? "Connect" : "Account"
              }
              className={`customHeaderNav ${currentPage == "Login" ? classes["resultLinkActive"] : classes["resultLinkInactive"]} ${classes["resultLink"]}`}
            >

              {email ? <Dropdown.Item>
                <div onClick={
                  (e) => {
                    changeCurrentPage("Profile")
                  }
                }>Profile
                </div>
              </Dropdown.Item> : null
              }
              {
                !email ? (
                  <Dropdown.Item>
                    <div onClick={() => document.dispatchEvent(new Event('login'))}
                    >
                      Login
                    </div>
                  </Dropdown.Item>
                ) : null
              }
              <Dropdown.Item>
                {
                  address && address !== selectedAddress ? (
                    <label onClick={(e) => {
                      disconnect()
                    }}>
                      {getPartialAddress(address)}
                      <br></br>
                      Disconnect Wallet
                    </label>
                  ) : (
                    <label onClick={
                      (e) => {
                        connect(true)
                      }
                    }>Connect Wallet
                    </label>
                  )
                }
              </Dropdown.Item>
              {
                email ? (
                  <Dropdown.Item>
                    <div onClick={() => document.dispatchEvent(new Event('logout'))}>
                      Logout
                    </div>
                  </Dropdown.Item>
                ) : null
              }
            </DropdownButton>
            <label onClick={(e) => changeCurrentPage("MintCards")} className={`${classes[""]} ${currentPage == "MintCards" ? classes["resultLinkActive"] : classes["resultLinkInactive"]} ${classes["resultLinkBtn"]} ${props.disableHeaderLinks ? classes["navbar-brand-disabled"] : classes["navbar-brand-enabled"]}`} style={{ pointerEvents: props.disableHeaderLinks ? "none" : "all"}} >NFTs
            </label>
            <label
              onClick={(e) => changeCurrentPage("Matches")}
              className={`${classes[""]} ${currentPage == "Matches" ? classes["resultLinkActive"] : classes["resultLinkInactive"]} ${classes["resultLinkBtn"]} ${props.disableHeaderLinks ? classes["navbar-brand-disabled"] : classes["navbar-brand-enabled"]}`}
              style={{ pointerEvents: props.disableHeaderLinks ? "none" : "all" }}
            >
              Matches
            </label>
            <label onClick={(e) => changeCurrentPage("Community")} className={`${classes[""]} ${currentPage == "Community" ? classes["resultLinkActive"] : classes["resultLinkInactive"]} ${classes["resultLinkBtn"]} ${props.disableHeaderLinks ? classes["navbar-brand-disabled"] : classes["navbar-brand-enabled"]}`} style={{ pointerEvents: props.disableHeaderLinks ? "none" : "all" }} >Community
            </label>
            <label onClick={(e) => changeCurrentPage("Result")} className={`${classes[""]} ${currentPage == "Result" ? classes["resultLinkActive"] : classes["resultLinkInactive"]} ${classes["resultLinkBtn"]} ${props.disableHeaderLinks ? classes["navbar-brand-disabled"] : classes["navbar-brand-enabled"]}`} style={{ pointerEvents: props.disableHeaderLinks ? "none" : "all" }} >Results
            </label>
          </div>
          : null}

        {startTest ? (
          <ul className={`navbar-nav ${classes["navbar-nav"]}`}>
            <h4 className={`float-right mt-1 pr-2 ${classes["completionWord"]}`} >Completion</h4>
            <h4 style={{ width: 90 }}>
              <span className={`completionWordPercentage float-right mt-1" ${classes["completionWordPercentage"]}`} >%</span>
              <Flip value={Hpercent} />
            </h4>
            {/* <h2
              className={`nav-link ${classes["nav-link"]}`}
            >{`Completion: ${Hpercent}%`}</h2> */}
          </ul>
        ) : null}
      </nav>
    </Fragment>
  );
};

export default Header;
