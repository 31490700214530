import React, { useEffect, useState } from "react";
import { Context } from "../store/StateProvider";
import SelectBox from "./../UI/SelectBox";
import classes from "./Test2.module.css";
import CautionPNG from "../../assets/caution.png";

let QUESTION_ANS = [];
let QUESTION_ANS_DUPLICATE = [];

const Question2 = (props) => {
  // states
  const [state, dispatch] = Context();
  const [dropDown, setDropDown] = useState([]);
  const [dropDownDuplicate, setDropDownDuplicate] = useState([]);

  const [option1, setOption1] = useState('');
  const [option2, setOption2] = useState('');
  const [option3, setOption3] = useState('');
  // states end

  /////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////

  // dropdown logic
  useEffect(() => {
    // Options for dropDown
    const OPTIONS = props.options;
    const dropDownOptions = OPTIONS.map((option) => {
      return {
        value: option.optionMark,
        label: option.optionLab,
      };
    });
    console.log(dropDownOptions);
    setDropDownDuplicate(dropDownOptions);
    setDropDown(dropDownOptions);
    // Options for dropDown end
  }, [props.options]);

  //////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////

  useEffect(() => {
    QUESTION_ANS = [...state.test3Data];
    QUESTION_ANS_DUPLICATE = [...state.test3Data];
    // console.log("useEffect");
  }, [state.test3Data]);
  // box1 handler
  const box1Handler = ({ label, value }, boxValue) => {
    const objbox1 = {
      label,
      value,
      boxValue,
      questionId: props.id,
    };
    const dataDuplicacy1 = QUESTION_ANS.findIndex(
      (e) => e.boxValue === boxValue && e.questionId === props.id
    );
    if (dataDuplicacy1 >= 0) {
      QUESTION_ANS[dataDuplicacy1] = objbox1;
    } else {
      QUESTION_ANS.push(objbox1);
    }
    boxesData();

    // dropdown logic
    let dropDownRemove = dropDownDuplicate.filter((e) => e.label !== label);
    setOption1(label);
    if (option2 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option2);
    }
    if (option3 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option3);
    }
    console.log(dropDownDuplicate);
    console.log(dropDownRemove);
    setDropDown(dropDownRemove);
  };
  // box2 handler
  const box2Handler = ({ label, value }, boxValue) => {
    const objbox2 = {
      label,
      value,
      boxValue,
      questionId: props.id,
    };
    const dataDuplicacy2 = QUESTION_ANS.findIndex(
      (e) => e.boxValue === boxValue && e.questionId === props.id
    );
    if (dataDuplicacy2 >= 0) {
      QUESTION_ANS[dataDuplicacy2] = objbox2;
    } else {
      QUESTION_ANS.push(objbox2);
    }
    boxesData();

    // dropdown logic

    let dropDownRemove = dropDownDuplicate.filter((e) => e.label !== label);
    setOption2(label);
    if (option1 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option1);
    }
    if (option3 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option3);
    }
    console.log(dropDownDuplicate);
    console.log(dropDownRemove);
    setDropDown(dropDownRemove);
  };
  // box3 handler
  const box3Handler = ({ label, value }, boxValue) => {
    const objbox3 = {
      label,
      value,
      boxValue,
      questionId: props.id,
    };
    const dataDuplicacy3 = QUESTION_ANS.findIndex(
      (e) => e.boxValue === boxValue && e.questionId === props.id
    );
    if (dataDuplicacy3 >= 0) {
      QUESTION_ANS[dataDuplicacy3] = objbox3;
    } else {
      QUESTION_ANS.push(objbox3);
    }
    boxesData();

    // dropdown logic
    let dropDownRemove = dropDownDuplicate.filter((e) => e.label !== label);
    setOption3(label);
    if (option1 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option1);
    }
    if (option2 != "") {
      dropDownRemove = dropDownRemove.filter((e) => e.label !== option2);
    }
    console.log(dropDownDuplicate);
    console.log(dropDownRemove);

    setDropDown(dropDownRemove);
  };
  const boxesData = () => {
    console.log("1")
    const variable = QUESTION_ANS.filter((e) => e.questionId === props.id);
    if (
      variable.length === 3 &&
      QUESTION_ANS_DUPLICATE.length < QUESTION_ANS.length
    ) {
      console.log("2")
      props.questionHandle(QUESTION_ANS, props.index);
      // document.getElementById("myDiv"+parseInt(props.index)+1).scrollIntoView({ alignToTop:true,behaviour: "smooth" });
      // props.scrollMove.current.scrollIntoView({ behaviour: "smooth" });
    }

    // logic for last question
    const questionsL = props.questionLength.length;
    if (questionsL * 3 === QUESTION_ANS.length) {
      console.log("3")
      props.questionHandle(QUESTION_ANS, props.index);
    }
    // logic for last question end

    // logic for pagination system last question
    const variable2 = (props.count - 9) * 3;
    const variable3 = QUESTION_ANS.length - variable2;
    if (questionsL * 3 === variable3) {
      console.log("4")
      props.questionHandle(QUESTION_ANS, props.index);
    }
    // logic for pagination system last question
  };

  return (
    <div onClick={(e) => {
      console.log(props.isEnabled + " - " + props.id)
      if (props.isEnabled == false) {
        console.log("Run")
        props.focusCurrentHandler(props.id);
      }
    }} className={props.isEnabled ? "" : classes["disabled"]}>
      <div
        className={`${classes["questionMainDiv"]} ${(props.IsMissed == true && props.validationErrorCounter == 1) ? classes["questionMainDivError"] : ""} `}
      >
        <h1 className={`${classes["test2QuestionHead"]}`}>{props.question}</h1>
        {(props.IsMissed == true && props.validationErrorCounter == 1) ?
          <img className={`${classes["cautionErrorImg"]} `} src={CautionPNG} />
          : null}
        <SelectBox
          key={props.id}
          id={props.id}
          isEnabled={props.isEnabled}
          index={props.index}
          options={dropDown}
          box1={box1Handler}
          box2={box2Handler}
          box3={box3Handler}
        />
      </div>
    </div>
  );
};

export default Question2;
