
import React, { Fragment } from "react";
import "./Matches.css";
import { SessionContext } from "../store/SessionProvider";

const Matches = ({changeCurrentPage}) => {
  const [ { compatibleParticipants, email }] = SessionContext();
  return (
    <Fragment>
      <div className="matchesContainer">
        <div>
          <h4 className="matchesTitle">
            {
              email ? 'My Matches' : 'Want to see your Matches?'
            }
          </h4>
          <div className="matchesTable">
            {
              !email ? (
                <div>
                  <button className="mintButtonRedCommunity1" onClick={() => changeCurrentPage('Login')}>
                    Please Login to continue
                  </button>
                </div>
              ) : !compatibleParticipants ? (
                <div class="text-center">
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <table className="text-left table">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ minWidth: "120px" }} >Name</th>
                      <th style={{ minWidth: "120px" }} >Email</th>
                      <th style={{ minWidth: "120px" }} >Wallet</th>
                      <th style={{ minWidth: "120px" }} >Sex</th>
                      <th style={{ minWidth: "120px" }} >Match %</th>
                    </tr>
                  </thead>
                  <tbody>
                    {compatibleParticipants.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>John Doe</td>
                          <td>{item.email}</td>
                          {item.address != "" ? <td>...{item.address.substring(22)}</td> : <td></td>}
                          <td>M</td>
                          <td>{item.score}%</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              )
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Matches;
