import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { CarouselItem } from "react-bootstrap";
import resultCardMain2 from "../../assets/resultCardMain2_c.webp";
import { Context } from "../store/StateProvider";

import TraitsCard from "../../assets/TraitsCard.png";
import P1 from "../../assets/PersonalityTraitsNums/1.png";
import P2 from "../../assets/PersonalityTraitsNums/2.png";
import P3 from "../../assets/PersonalityTraitsNums/3.png";
import P4 from "../../assets/PersonalityTraitsNums/4.png";
import P5 from "../../assets/PersonalityTraitsNums/5.png";
import P6 from "../../assets/PersonalityTraitsNums/6.png";
import P7 from "../../assets/PersonalityTraitsNums/7.png";
import P8 from "../../assets/PersonalityTraitsNums/8.png";
import P9 from "../../assets/PersonalityTraitsNums/9.png";
import P10 from "../../assets/PersonalityTraitsNums/10.png";

import D1 from "../../assets/PersonalityTraitsNums/1.png";
import D2 from "../../assets/PersonalityTraitsNums/2.png";
import D3 from "../../assets/PersonalityTraitsNums/3.png";
import D4 from "../../assets/PersonalityTraitsNums/4.png";
import D5 from "../../assets/PersonalityTraitsNums/5.png";
import D6 from "../../assets/PersonalityTraitsNums/6.png";
import D7 from "../../assets/PersonalityTraitsNums/7.png";
import D8 from "../../assets/PersonalityTraitsNums/8.png";
import D9 from "../../assets/PersonalityTraitsNums/9.png";
import D10 from "../../assets/PersonalityTraitsNums/10.png";


const Personality = (props) => {
  const [state, dispatch] = Context();
  const [bigFiveTraitsDetails, setBigFiveTraitsDetails] = useState([]);
  useEffect(() => {
    
    let data = props.bigFiveTraitsDetails.sort((a, b) => b.percentage - a.percentage);
    if (data.length == 5) {
      // console.log("Big5 Results", data);
    }
    props.saveBigFiveForPdf(data);
    setBigFiveTraitsDetails(data);

  }, [props]);
  const handleScroll = () => {
    const position = window.pageYOffset;

    const resultPersonalityHead = document.querySelector("#resultPersonalityHead");
    if (resultPersonalityHead.getBoundingClientRect().top > 0 && resultPersonalityHead.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultPersonalityHead.classList.add('resultYourResultHeadingAnim');
    }

    const resultExtroversionHead1 = document.querySelector("#resultExtroversionHead1");
    if (resultExtroversionHead1.getBoundingClientRect().top > 0 && resultExtroversionHead1.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultExtroversionHead1.classList.add('resultYourResultHeadingAnim');
    }

    const resultExtroversionHead2 = document.querySelector("#resultExtroversionHead2");
    if (resultExtroversionHead2.getBoundingClientRect().top > 0 && resultExtroversionHead2.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultExtroversionHead2.classList.add('resultYourResultHeadingAnim');
    }

    const resultExtroversionHead3 = document.querySelector("#resultExtroversionHead3");
    if (resultExtroversionHead3.getBoundingClientRect().top > 0 && resultExtroversionHead3.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultExtroversionHead3.classList.add('resultYourResultHeadingAnim');
    }

    const resultExtroversionHead4 = document.querySelector("#resultExtroversionHead4");
    if (resultExtroversionHead4.getBoundingClientRect().top > 0 && resultExtroversionHead4.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultExtroversionHead4.classList.add('resultYourResultHeadingAnim');
    }

    const resultExtroversionHead5 = document.querySelector("#resultExtroversionHead5");
    if (resultExtroversionHead5.getBoundingClientRect().top > 0 && resultExtroversionHead5.getBoundingClientRect().top < document.documentElement.clientHeight) {
      resultExtroversionHead5.classList.add('resultYourResultHeadingAnim');
    }

    const traitDescription1 = document.querySelector("#traitDescription1");
    if (traitDescription1.getBoundingClientRect().top > 0 && traitDescription1.getBoundingClientRect().top < document.documentElement.clientHeight) {
      traitDescription1.classList.add('resultYourResultHeadingAnim');
    }

    const traitDescription2 = document.querySelector("#traitDescription2");
    if (traitDescription2.getBoundingClientRect().top > 0 && traitDescription2.getBoundingClientRect().top < document.documentElement.clientHeight) {
      traitDescription2.classList.add('resultYourResultHeadingAnim');
    }

    const traitDescription3 = document.querySelector("#traitDescription3");
    if (traitDescription3.getBoundingClientRect().top > 0 && traitDescription3.getBoundingClientRect().top < document.documentElement.clientHeight) {
      traitDescription3.classList.add('resultYourResultHeadingAnim');
    }

    const traitDescription4 = document.querySelector("#traitDescription4");
    if (traitDescription4.getBoundingClientRect().top > 0 && traitDescription4.getBoundingClientRect().top < document.documentElement.clientHeight) {
      traitDescription4.classList.add('resultYourResultHeadingAnim');
    }

    const traitDescription5 = document.querySelector("#traitDescription5");
    if (traitDescription5.getBoundingClientRect().top > 0 && traitDescription5.getBoundingClientRect().top < document.documentElement.clientHeight) {
      traitDescription5.classList.add('resultYourResultHeadingAnim');
    }

    const subtraitDescription1 = document.querySelector("#subtraitDescription1");
    if (subtraitDescription1 != null) {
      if (subtraitDescription1.getBoundingClientRect().top > 0 && subtraitDescription1.getBoundingClientRect().top < document.documentElement.clientHeight) {
        subtraitDescription1.classList.add('resultYourResultHeadingAnim');
      }
    }

    const subtraitDescription2 = document.querySelector("#subtraitDescription2");
    if (subtraitDescription2 != null) {
      if (subtraitDescription2.getBoundingClientRect().top > 0 && subtraitDescription2.getBoundingClientRect().top < document.documentElement.clientHeight) {
        subtraitDescription2.classList.add('resultYourResultHeadingAnim');
      }
    }

    const subtraitDescription3 = document.querySelector("#subtraitDescription3");
    if (subtraitDescription3 != null) {
      if (subtraitDescription3.getBoundingClientRect().top > 0 && subtraitDescription3.getBoundingClientRect().top < document.documentElement.clientHeight) {
        subtraitDescription3.classList.add('resultYourResultHeadingAnim');
      }
    }

    const subtraitDescription4 = document.querySelector("#subtraitDescription4");
    if (subtraitDescription4 != null) {
      if (subtraitDescription4.getBoundingClientRect().top > 0 && subtraitDescription4.getBoundingClientRect().top < document.documentElement.clientHeight) {
        subtraitDescription4.classList.add('resultYourResultHeadingAnim');
      }
    }

    const subtraitDescription5 = document.querySelector("#subtraitDescription5");
    if (subtraitDescription5 != null) {
      if (subtraitDescription5.getBoundingClientRect().top > 0 && subtraitDescription5.getBoundingClientRect().top < document.documentElement.clientHeight) {
        subtraitDescription5.classList.add('resultYourResultHeadingAnim');
      }
    }

    const resultCardMain1Text5Description1 = document.querySelector("#resultCardMain1Text5Description1");
    if (resultCardMain1Text5Description1 != null) {
      if (resultCardMain1Text5Description1.getBoundingClientRect().top > 0 && resultCardMain1Text5Description1.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultCardMain1Text5Description1.classList.add('resultCardMain1Text5Anim');
      }
    }

    const resultCardMain1Text5Description2 = document.querySelector("#resultCardMain1Text5Description2");
    if (resultCardMain1Text5Description2 != null) {
      if (resultCardMain1Text5Description2.getBoundingClientRect().top > 0 && resultCardMain1Text5Description2.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultCardMain1Text5Description2.classList.add('resultCardMain1Text5Anim');
      }
    }

    const resultCardMain1Text5Description3 = document.querySelector("#resultCardMain1Text5Description3");
    if (resultCardMain1Text5Description3 != null) {
      if (resultCardMain1Text5Description3.getBoundingClientRect().top > 0 && resultCardMain1Text5Description3.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultCardMain1Text5Description3.classList.add('resultCardMain1Text5Anim');
      }
    }

    const resultCardMain1Text5Description4 = document.querySelector("#resultCardMain1Text5Description4");
    if (resultCardMain1Text5Description4 != null) {
      if (resultCardMain1Text5Description4.getBoundingClientRect().top > 0 && resultCardMain1Text5Description4.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultCardMain1Text5Description4.classList.add('resultCardMain1Text5AnimConscientiousness');
      }
    }

    const resultCardMain1Text5Description5 = document.querySelector("#resultCardMain1Text5Description5");
    if (resultCardMain1Text5Description5 != null) {
      if (resultCardMain1Text5Description5.getBoundingClientRect().top > 0 && resultCardMain1Text5Description5.getBoundingClientRect().top < document.documentElement.clientHeight) {
        resultCardMain1Text5Description5.classList.add('resultCardMain1Text5AnimOpenness');
      }
    }

  };
  function downloadSVG() {

    console.log(document.getElementById("personalityCard"))
    var svgData = document.getElementById("personalityCard").outerHTML;
    console.log(svgData)
    var svgBlob = new Blob([svgData], { type: "svg+xml;charset=utf-8" });
    console.log(svgBlob)
    var svgUrl = URL.createObjectURL(svgBlob);
    console.log(svgUrl)
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    console.log(downloadLink)
    downloadLink.download = "personality_card.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink)
  }

  return (
    <Fragment>
      {/* <!-- personality section --> */}
      {bigFiveTraitsDetails.length > 4 ? (
        <div>
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-5 ml-4"><h2 className="resultPersonality mt-5">Your Personality</h2></div>
              <div className="col-md-3"></div>
            </div>
          </div>

          <div className="resultCardMainImageWrapper mt-5">
            {/* <a href="https://personalitynft.com/" target="_blank"> */}
            <svg onClick={(e) => {
              // svgToCanvas()
            }} id="personalityCard"
              name="personalityCard" width="321" height="469"
              xmlns="http://www.w3.org/2000/svg">
              <image href={TraitsCard} width="321" height="469" />
              <image href={
                props.extraversion != {} ?
                  parseInt(Math.round((props.extraversion.percentage / 10.00))) == "1" ? P1
                    : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "2" ? P2
                      : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "3" ? P3
                        : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "4" ? P4
                          : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "5" ? P5
                            : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "6" ? P6
                              : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "7" ? P7
                                : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "8" ? P8
                                  : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "9" ? P9
                                    : parseInt(Math.round((props.extraversion.percentage / 10.00))) == "10" ? P10
                                      : ''
                  : ''}
                x="93" y="151" width="48" height="48" />
              <image href={props.openness != {} ?
                parseInt(Math.round((props.openness.percentage / 10.00))) == "1" ? P1
                  : parseInt(Math.round((props.openness.percentage / 10.00))) == "2" ? P2
                    : parseInt(Math.round((props.openness.percentage / 10.00))) == "3" ? P3
                      : parseInt(Math.round((props.openness.percentage / 10.00))) == "4" ? P4
                        : parseInt(Math.round((props.openness.percentage / 10.00))) == "5" ? P5
                          : parseInt(Math.round((props.openness.percentage / 10.00))) == "6" ? P6
                            : parseInt(Math.round((props.openness.percentage / 10.00))) == "7" ? P7
                              : parseInt(Math.round((props.openness.percentage / 10.00))) == "8" ? P8
                                : parseInt(Math.round((props.openness.percentage / 10.00))) == "9" ? P9
                                  : parseInt(Math.round((props.openness.percentage / 10.00))) == "10" ? P10
                                    : ''
                : ''} x="175" y="151" width="48" height="48" />
              <image href={props.neuroticism != {} ?
                parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "1" ? P1
                  : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "2" ? P2
                    : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "3" ? P3
                      : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "4" ? P4
                        : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "5" ? P5
                          : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "6" ? P6
                            : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "7" ? P7
                              : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "8" ? P8
                                : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "9" ? P9
                                  : parseInt(Math.round((props.neuroticism.percentage / 10.00))) == "10" ? P10
                                    : ''
                : ''} x="93" y="243" width="48" height="48" />
              <image href={props.agreeableness != {} ?
                parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "1" ? P1
                  : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "2" ? P2
                    : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "3" ? P3
                      : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "4" ? P4
                        : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "5" ? P5
                          : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "6" ? P6
                            : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "7" ? P7
                              : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "8" ? P8
                                : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "9" ? P9
                                  : parseInt(Math.round((props.agreeableness.percentage / 10.00))) == "10" ? P10
                                    : ''
                : ''} x="134" y="257" width="48" height="48" />
              <image href={props.conscientiousness != {} ?
                parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "1" ? P1
                  : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "2" ? P2
                    : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "3" ? P3
                      : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "4" ? P4
                        : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "5" ? P5
                          : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "6" ? P6
                            : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "7" ? P7
                              : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "8" ? P8
                                : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "9" ? P9
                                  : parseInt(Math.round((props.conscientiousness.percentage / 10.00))) == "10" ? P10
                                    : ''
                : ''} x="177" y="243" width="48" height="48" />

              <image href={props.machiavellianism != {} ?
                parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "1" ? D1
                  : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "2" ? D2
                    : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "3" ? D3
                      : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "4" ? D4
                        : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "5" ? D5
                          : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "6" ? D6
                            : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "7" ? D7
                              : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "8" ? D8
                                : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "9" ? D9
                                  : parseInt(Math.round((props.machiavellianism.percentage / 10.00))) == "10" ? D10
                                    : ''
                : ''} x="127" y="167" width="65" height="65" />
              <image href={props.narcissism != {} ?
                parseInt(Math.round((props.narcissism.percentage / 10.00))) == "1" ? D1
                  : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "2" ? D2
                    : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "3" ? D3
                      : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "4" ? D4
                        : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "5" ? D5
                          : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "6" ? D6
                            : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "7" ? D7
                              : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "8" ? D8
                                : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "9" ? D9
                                  : parseInt(Math.round((props.narcissism.percentage / 10.00))) == "10" ? D10
                                    : ''
                : ''} x="107" y="201" width="65" height="65" />
              <image href={props.psychopathy != {} ?
                parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "1" ? D1
                  : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "2" ? D2
                    : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "3" ? D3
                      : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "4" ? D4
                        : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "5" ? D5
                          : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "6" ? D6
                            : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "7" ? D7
                              : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "8" ? D8
                                : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "9" ? D9
                                  : parseInt(Math.round((props.psychopathy.percentage / 10.00))) == "10" ? D10
                                    : ''
                : ''} x="147" y="201" width="65" height="65" />
            </svg>
            {/* Extraversion Start */}
            <div className="resultCardMainLine5"></div>
            <h4 className="resultCardMain1Text5" id="resultCardMain1Text5Description1">
              {props.extraversion != {} ?
                props.extraversion.traitName + " : " + props.extraversion.percentage + "%"
                : null}
            </h4>
            {/* Extraversion End */}
            {/* Neuroticism Start */}
            <div className="resultCardMainLine6"></div>
            <h4 className="resultCardMain1Text6" id="resultCardMain1Text5Description2">
              {props.neuroticism != {} ?
                props.neuroticism.traitName + " : " + props.neuroticism.percentage + "%"
                : null}
            </h4>
            {/* Neuroticism End */}
            {/* Agreeableness Start */}
            <div className="resultCardMainLine7"></div>
            <h4 className="resultCardMain1Text7" id="resultCardMain1Text5Description3">
              {props.agreeableness != {} ?
                props.agreeableness.traitName + " : " + props.agreeableness.percentage + "%"
                : null}
            </h4>
            {/* Agreeableness End */}
            {/* Conscientiousness Start */}
            <div className="resultCardMainLine8"></div>
            <h4 className="resultCardMain1Text8" id="resultCardMain1Text5Description4">
              {props.conscientiousness != {} ?
                props.conscientiousness.traitName + " : " + props.conscientiousness.percentage + "%"
                : null}
            </h4>
            {/* Conscientiousness End */}
            {/* Openness Start */}
            <div className="resultCardMainLine9"></div>
            <h4 className="resultCardMain1Text9" id="resultCardMain1Text5Description5">
              {props.openness != {} ?
                props.openness.traitName + " : " + props.openness.percentage + "%"
                : null}
            </h4>
            {/* Openness End */}
          </div>
        </div>
      ) : null}
      {/* <!-- personality traits --> */}

      {bigFiveTraitsDetails.length > 4 ? (
        <div className="container">
          <div className="row px-3 px-md-5">
            <div className="col-12 my-5 text-left">
              <h2 className="mt-5 mb-3 resultPersonalityHead" id="resultPersonalityHead">
                Big 5 Personality Traits
              </h2>
              <p className="resultPersonalitypara">
                Let’s jump into the result. Below are your Big 5 results ranked in order of highest to lowest. So, the first master trait you see below is your highest trait. This is the master-trait you use most often.
              </p>
            </div>
            {/* <!-- First Trait Start --> */}
            <div className="col-md-4 ">
              <h3 className="resultExtroversionHead" id="resultExtroversionHead1">
                {bigFiveTraitsDetails[0].traitName}: {bigFiveTraitsDetails[0].percentage}%
              </h3>
            </div>
            <div className="col-md-8 mb-5">
              <p className="resultExtroversionPara">
                {/* {console.log(bigFiveTraitsDetails[0])} */}
                <div dangerouslySetInnerHTML={{ __html: bigFiveTraitsDetails[0].description }} />
              </p>
              <h4 className="resultExtroversiondesc mt-1" id="traitDescription1">Trait Description</h4>
              <div className="row pl-4">
                <div className="col-md-12">
                  <ul className="resultExtroversionpionts">
                    {JSON.parse(bigFiveTraitsDetails[0].bullets).map((bullet) => {
                      return <li className="resultExtroversionpiontsItem">{bullet.bulletValue}</li>;
                    })}
                  </ul>
                </div>
              </div>
              {props.subtraitsPercentages.map((item) => {
                if (bigFiveTraitsDetails[0].trait_id == item.traitId) {
                  return <div>
                    <h4 className="resultExtroversiondesc" id="subtraitDescription1">{item.name} : {(Math.round(item.percent)).toFixed(2)} %</h4>
                    <p className="resultExtroversionPara">{item.result != {} ? <div dangerouslySetInnerHTML={{ __html: item.result.description }} /> : null}</p>

                    {item.result != {} && item.result.bullets != "" ?
                      <ul className="resultSubtraitsBullets">
                        {item.result != {} && item.result.bullets != "" ? JSON.parse(item.result.bullets).map((bulletSubtrait) => {
                          return <li className="resultSubtraitsBulletsItem">{bulletSubtrait.bulletValue}</li>;
                        }) : null}
                      </ul>
                      : null}
                  </div>
                }
              })}
            </div>
            {/* <!-- First Trait END --> */}

            {/* <!-- Second Trait START --> */}
            <div className="col-md-4">
              <h3 className="resultExtroversionHead" id="resultExtroversionHead2">
                {bigFiveTraitsDetails[1].traitName}: {bigFiveTraitsDetails[1].percentage}%
              </h3>
            </div>
            <div className="col-md-8 mb-5">
              <p className="resultExtroversionPara">
                <div dangerouslySetInnerHTML={{ __html: bigFiveTraitsDetails[1].description }} />
              </p>
              <h4 className="resultExtroversiondesc" id="traitDescription2">Trait Description</h4>
              <div className="row pl-4">
                <div className="col-md-12">
                  <ul className="resultExtroversionpionts">
                    {JSON.parse(bigFiveTraitsDetails[1].bullets).map((bullet) => {
                      return <li className="resultExtroversionpiontsItem">{bullet.bulletValue}</li>;
                    })}
                  </ul>
                </div>
              </div>
              {props.subtraitsPercentages.map((item) => {
                if (bigFiveTraitsDetails[1].trait_id == item.traitId) {
                  return <div>
                    <h4 className="resultExtroversiondesc" id="subtraitDescription2">{item.name} : {(Math.round(item.percent)).toFixed(2)} %</h4>
                    <p className="resultExtroversionPara">{item.result != {} ? <div dangerouslySetInnerHTML={{ __html: item.result.description }} /> : null}</p>
                    

                    {item.result != {} && item.result.bullets != "" ?
                      <ul className="resultSubtraitsBullets">
                        {item.result != {} && item.result.bullets != "" ? JSON.parse(item.result.bullets).map((bulletSubtrait) => {
                          return <li className="resultSubtraitsBulletsItem">{bulletSubtrait.bulletValue}</li>;
                        }) : null}
                      </ul>
                      : null}
                  </div>
                }
              })}
            </div>
            {/* <!-- Second Trait END --> */}
            {/* <!-- Third Trait START --> */}
            <div className="col-md-4">
              <h3 className="resultExtroversionHead" id="resultExtroversionHead3">
                {bigFiveTraitsDetails[2].traitName}: {bigFiveTraitsDetails[2].percentage}%
              </h3>
            </div>
            <div className="col-md-8 mb-5">
              <p className="resultExtroversionPara">
                <div dangerouslySetInnerHTML={{ __html: bigFiveTraitsDetails[2].description }} />
              </p>
              <h4 className="resultExtroversiondesc" id="traitDescription3">Trait Description</h4>
              <div className="row pl-4">
                <div className="col-md-12">
                  <ul className="resultExtroversionpionts">
                    {JSON.parse(bigFiveTraitsDetails[2].bullets).map((bullet) => {
                      return <li className="resultExtroversionpiontsItem">{bullet.bulletValue}</li>;
                    })}
                  </ul>
                </div>
              </div>
              {props.subtraitsPercentages.map((item) => {
                if (bigFiveTraitsDetails[2].trait_id == item.traitId) {
                  return <div>
                    <h4 className="resultExtroversiondesc" id="subtraitDescription3">{item.name} : {(Math.round(item.percent)).toFixed(2)} %</h4>
                    <p className="resultExtroversionPara">{item.result != {} ? <div dangerouslySetInnerHTML={{ __html: item.result.description }} /> : null}</p>
                    

                    {item.result != {} && item.result.bullets != "" ?
                      <ul className="resultSubtraitsBullets">
                        {item.result != {} && item.result.bullets != "" ? JSON.parse(item.result.bullets).map((bulletSubtrait) => {
                          return <li className="resultSubtraitsBulletsItem">{bulletSubtrait.bulletValue}</li>;
                        }) : null}
                      </ul>
                      : null}
                  </div>
                }
              })}
            </div>
            {/* <!-- Third Trait END --> */}
            {/* <!-- Forth Trait START   --> */}
            <div className="col-md-4">
              <h3 className="resultExtroversionHead" id="resultExtroversionHead4">
                {bigFiveTraitsDetails[3].traitName}: {bigFiveTraitsDetails[3].percentage}%
              </h3>
            </div>
            <div className="col-md-8 mb-5">
              <p className="resultExtroversionPara">
                <div dangerouslySetInnerHTML={{ __html: bigFiveTraitsDetails[3].description }} />
              </p>
              <h4 className="resultExtroversiondesc" id="traitDescription4">Trait Description</h4>
              <div className="row pl-4">
                <div className="col-md-12">
                  <ul className="resultExtroversionpionts">
                    {JSON.parse(bigFiveTraitsDetails[3].bullets).map((bullet) => {
                      return <li className="resultExtroversionpiontsItem">{bullet.bulletValue}</li>;
                    })}

                  </ul>
                </div>
              </div>
              {props.subtraitsPercentages.map((item) => {
                if (bigFiveTraitsDetails[3].trait_id == item.traitId) {
                  return <div>
                    <h4 className="resultExtroversiondesc" id="subtraitDescription4">{item.name} : {(Math.round(item.percent)).toFixed(2)} %</h4>
                    <p className="resultExtroversionPara">{item.result != {} ? <div dangerouslySetInnerHTML={{ __html: item.result.description }} /> : null}</p>
                    

                    {item.result != {} && item.result.bullets != "" ?
                      <ul className="resultSubtraitsBullets">
                        {item.result != {} && item.result.bullets != "" ? JSON.parse(item.result.bullets).map((bulletSubtrait) => {
                          return <li className="resultSubtraitsBulletsItem">{bulletSubtrait.bulletValue}</li>;
                        }) : null}
                      </ul>
                      : null}
                  </div>
                }
              })}

              {/* trait_id */}
            </div>
            {/* <!-- Forth Trait  END --> */}
            {/* <!-- Fifth Trait START --> */}
            <div className="col-md-4">
              <h3 className="resultExtroversionHead" id="resultExtroversionHead5">
                {bigFiveTraitsDetails[4].traitName}: {bigFiveTraitsDetails[4].percentage}%
              </h3>
            </div>
            <div className="col-md-8 mb-5">
              <p className="resultExtroversionPara">
                <div dangerouslySetInnerHTML={{ __html: bigFiveTraitsDetails[4].description }} />
              </p>
              <h4 className="resultExtroversiondesc" id="traitDescription5">Trait Description</h4>
              <div className="row pl-4">
                <div className="col-md-12">
                  <ul className="resultExtroversionpionts">
                    {JSON.parse(bigFiveTraitsDetails[4].bullets).map((bullet) => {
                      return <li className="resultExtroversionpiontsItem">{bullet.bulletValue}</li>;
                    })}
                  </ul>
                </div>
              </div>
              {props.subtraitsPercentages.map((item) => {
                if (bigFiveTraitsDetails[4].trait_id == item.traitId) {
                  return <div>

                    <h4 className="resultExtroversiondesc" id="subtraitDescription5">{item.name} : {(Math.round(item.percent)).toFixed(2)} %</h4>
                    <p className="resultExtroversionPara">{item.result != {} ? <div dangerouslySetInnerHTML={{ __html: item.result.description }} /> : null}</p>
                    

                    {item.result != {} && item.result.bullets != "" ?
                      <ul className="resultSubtraitsBullets">
                        {item.result != {} && item.result.bullets != "" ? JSON.parse(item.result.bullets).map((bulletSubtrait) => {
                          return <li className="resultSubtraitsBulletsItem">{bulletSubtrait.bulletValue}</li>;
                        }) : null}
                      </ul>
                      : null}
                  </div>
                }
              })}
            </div>
            {/* <!-- Fifth Trait END --> */}

          </div>
        </div>
      ) : null}
      {/* <!-- personality traits end --> */}
    </Fragment>
  );
};

export default Personality;
