import React, { Fragment, useState, useEffect } from "react";
import * as ReactDOM from 'react-dom';
import resultCardMain1 from "../../assets/resultCardMain1.webp";
import resultCardMain2 from "../../assets/resultCardMain2_c.webp";
import orderPNG from "../../assets/1650124620.png";
import shamanPNG from "../../assets/1650124033.png";
import TraitsCard from "../../assets/TraitsCard.png";
import P1 from "../../assets/PersonalityTraitsNums/1.png";
import P2 from "../../assets/PersonalityTraitsNums/2.png";
import P3 from "../../assets/PersonalityTraitsNums/3.png";
import P4 from "../../assets/PersonalityTraitsNums/4.png";
import P5 from "../../assets/PersonalityTraitsNums/5.png";
import P6 from "../../assets/PersonalityTraitsNums/6.png";
import P7 from "../../assets/PersonalityTraitsNums/7.png";
import P8 from "../../assets/PersonalityTraitsNums/8.png";
import P9 from "../../assets/PersonalityTraitsNums/9.png";
import P10 from "../../assets/PersonalityTraitsNums/10.png";

import D1 from "../../assets/PersonalityTraitsNums/1.png";
import D2 from "../../assets/PersonalityTraitsNums/2.png";
import D3 from "../../assets/PersonalityTraitsNums/3.png";
import D4 from "../../assets/PersonalityTraitsNums/4.png";
import D5 from "../../assets/PersonalityTraitsNums/5.png";
import D6 from "../../assets/PersonalityTraitsNums/6.png";
import D7 from "../../assets/PersonalityTraitsNums/7.png";
import D8 from "../../assets/PersonalityTraitsNums/8.png";
import D9 from "../../assets/PersonalityTraitsNums/9.png";
import D10 from "../../assets/PersonalityTraitsNums/10.png";

import axios from "axios";
import { Context } from "../store/StateProvider";
import toImg from 'react-svg-to-image';
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION
} from "react-image-magnifiers";

import html2canvas from 'html2canvas';
import { useLocation, useParams } from "react-router";

const PersonalityCardDownloader = (props) => {
  const [state, dispatch] = Context();

  const [cardDownloadCanvas, setCardDownloadCanvas] = useState(false);
  const params = useParams()

  useEffect(() => {
    console.log(JSON.parse(localStorage.getItem('resultData')))
    console.log(params)
    let resultData = JSON.parse(localStorage.getItem('resultData'));
    // window.addEventListener("scroll", handleScroll);

    drawCanvas(JSON.parse(resultData.personalityScoresArray), JSON.parse(resultData.darkTraidScoresArray), resultData);
    // setTimeout(function () {

    // }, 7000);

  }, [props]);
  function drawCanvas(personality_values, dark_traid_values, resultData) {
    // setCardDownloadCanvas(true);
    // var input = document.createElement('canvas');
    // input.id = "mycanvasSVGNew";
    const input = document.getElementById('mycanvasSVGNew');
    let base_image = new Image(0, 0);
    base_image.src = TraitsCard;
    base_image.width = 963; //20.0625 : 29.3125 (divided by 16)
    base_image.height = 1407;
    input.width = base_image.width;
    input.height = base_image.height;


    let contextInput = input.getContext('2d');

    base_image.addEventListener('load', e => {
      contextInput.imageSmoothingEnabled = true;
      contextInput.drawImage(base_image, 0, 0, 2667, 4000, 0, 0, 963, 1407);

      // Declare All Personality Traits
      let p1 = new Image(0, 0);
      p1.src = P1;
      let p2 = new Image(0, 0);
      p2.src = P2;
      let p3 = new Image(0, 0);
      p3.src = P3;
      let p4 = new Image(0, 0);
      p4.src = P4;
      let p5 = new Image(0, 0);
      p5.src = P5;
      let p6 = new Image(0, 0);
      p6.src = P6;
      let p7 = new Image(0, 0);
      p7.src = P7;
      let p8 = new Image(0, 0);
      p8.src = P8;
      let p9 = new Image(0, 0);
      p9.src = P9;
      let p10 = new Image(0, 0);
      p10.src = P10;

      // Declare All Dark Traid Traits
      let d1 = new Image(0, 0);
      d1.src = D1;
      let d2 = new Image(0, 0);
      d2.src = D2;
      let d3 = new Image(0, 0);
      d3.src = D3;
      let d4 = new Image(0, 0);
      d4.src = D4;
      let d5 = new Image(0, 0);
      d5.src = D5;
      let d6 = new Image(0, 0);
      d6.src = D6;
      let d7 = new Image(0, 0);
      d7.src = D7;
      let d8 = new Image(0, 0);
      d8.src = D8;
      let d9 = new Image(0, 0);
      d9.src = D9;
      let d10 = new Image(0, 0);
      d10.src = D10;



      //
      let personalityScoresArray = personality_values;
      // personalityScoresArray[0] = parseInt(Math.round((props.extraversion.percentage / 10.00)));
      // personalityScoresArray[1] = parseInt(Math.round((props.openness.percentage / 10.00)));
      // personalityScoresArray[2] = parseInt(Math.round((props.neuroticism.percentage / 10.00)));
      // personalityScoresArray[3] = parseInt(Math.round((props.conscientiousness.percentage / 10.00)));
      // personalityScoresArray[4] = parseInt(Math.round((props.agreeableness.percentage / 10.00)));

      let darkTraidScoresArray = dark_traid_values;
      // darkTraidScoresArray[0] = parseInt(Math.round((props.machiavellianism.percentage / 10.00)));
      // darkTraidScoresArray[1] = parseInt(Math.round((props.narcissism.percentage / 10.00)));
      // darkTraidScoresArray[2] = parseInt(Math.round((props.psychopathy.percentage / 10.00)));

      let extX = 283, extY = 465;
      let openX = 523, openY = 457;
      let neuX = 282, neuY = 743;
      let conX = 535, conY = 743;
      let agrX = 413, agrY = 780;

      let macX = 387, macY = 520;
      let narX = 327, narY = 620;
      let psyX = 443, psyY = 620;

      console.log(personalityScoresArray);
      let i = 0;
      for (i = 0; i < 5; i++) {
        let x_axis = 0, y_axis = 0;
        if (i == 0) {
          x_axis = extX;
          y_axis = extY;
        }
        else if (i == 1) {
          x_axis = openX;
          y_axis = openY;
        }
        else if (i == 2) {
          x_axis = neuX;
          y_axis = neuY;
        }
        else if (i == 3) {
          x_axis = conX;
          y_axis = conY;
        }
        else if (i == 4) {
          x_axis = agrX;
          y_axis = agrY;
        }
        if (personalityScoresArray[i] == "1") {
          p1.addEventListener('load', e => {
            contextInput.drawImage(p1, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "2") {
          p2.addEventListener('load', e => {
            contextInput.drawImage(p2, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "3") {
          p3.addEventListener('load', e => {
            contextInput.drawImage(p3, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "4") {
          p4.addEventListener('load', e => {
            contextInput.drawImage(p4, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "5") {
          p5.addEventListener('load', e => {
            contextInput.drawImage(p5, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "6") {
          p6.addEventListener('load', e => {
            contextInput.drawImage(p6, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "7") {
          p7.addEventListener('load', e => {
            contextInput.drawImage(p7, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "8") {
          p8.addEventListener('load', e => {
            contextInput.drawImage(p8, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "9") {
          p9.addEventListener('load', e => {
            contextInput.drawImage(p9, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
        else if (personalityScoresArray[i] == "10") {
          p10.addEventListener('load', e => {
            contextInput.drawImage(p10, 0, 0, 288, 328, x_axis, y_axis, 125, 125);
          });
        }
      }

      for (i = 0; i < 3; i++) {
        let x_axis = 0, y_axis = 0;
        if (i == 0) {
          x_axis = macX;
          y_axis = macY;
        }
        else if (i == 1) {
          x_axis = narX;
          y_axis = narY;
        }
        else if (i == 2) {
          x_axis = psyX;
          y_axis = psyY;
        }
        if (darkTraidScoresArray[i] == "1") {
          p1.addEventListener('load', e => {
            contextInput.drawImage(p1, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "2") {
          p2.addEventListener('load', e => {
            contextInput.drawImage(p2, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "3") {
          p3.addEventListener('load', e => {
            contextInput.drawImage(p3, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "4") {
          p4.addEventListener('load', e => {
            contextInput.drawImage(p4, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "5") {
          p5.addEventListener('load', e => {
            contextInput.drawImage(p5, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "6") {
          p6.addEventListener('load', e => {
            contextInput.drawImage(p6, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "7") {
          p7.addEventListener('load', e => {
            contextInput.drawImage(p7, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "8") {
          p8.addEventListener('load', e => {
            contextInput.drawImage(p8, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "9") {
          p9.addEventListener('load', e => {
            contextInput.drawImage(p9, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
        else if (darkTraidScoresArray[i] == "10") {
          p10.addEventListener('load', e => {
            contextInput.drawImage(p10, 0, 0, 288, 328, x_axis, y_axis, 160, 160);
          });
        }
      }

      setTimeout(function () {
        console.log(input);

        var link = document.createElement('a');
        link.download = 'personality_traits.png';

        link.href = input.toDataURL()
        global.personalityCardBase64 = link;
        console.log(link);
        link.click();
        window.close();
      }, 4000);
    });


  }
  const handleScroll = () => {
    // const mintButtonsYourResult = document.querySelector("#mintButtonsYourResult");
    // console.log("Scroll- Your Result", mintButtonsYourResult.getBoundingClientRect());
    // if (mintButtonsYourResult.getBoundingClientRect().top > 0 && mintButtonsYourResult.getBoundingClientRect().top < document.documentElement.clientHeight) {
    //   console.log("A");
    //   mintButtonsYourResult.classList.add('resultYourResultHeadingAnim');
    // }
  };
  function downloadPersonalityPNGCanvas() {

    const input = document.getElementById('mycanvasSVGNew');
    html2canvas(input)
      .then((canvas) => {
        console.log(canvas)
        var link = document.createElement('a');
        link.download = 'personality_traits.png';

        link.href = canvas.toDataURL()
        console.log(link.href);
        link.click();
      })
      ;
    // input.clearRect(0, 0, input.width, input.height);
    // setCardDownloadCanvas(false);
  }

  return (
    <Fragment>
      <div className="container">

        <canvas id="mycanvasSVGNew" onClick={(e) => {
          downloadPersonalityPNGCanvas()
        }} style={{ width: 963, height: 1407 }}>
        </canvas>
      </div>

    </Fragment>
  );
};

export default PersonalityCardDownloader;
