import React, { useEffect, useState } from "react";
import classes from "./Main.module.css";
import card1 from "../../assets/card_1.webp";
import card2 from "../../assets/card_2.webp";
import enterSign from "../../assets/Enter-arrow.png";
import { Link } from "react-router-dom";
import { Context } from "../store/StateProvider";
import axios from "axios";
import { SessionContext } from "../store/SessionProvider";
import { Layout } from "../Layout";

// let scriptData = "<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-XXXXXX');</script>"
const Main = () => {
  const [state, dispatch] = Context();
  const [testUrl, setTestUrl] = useState("");
  const [ { result } ] = SessionContext();
  const { winner } = result;

  useEffect(async() => {
    if (window.location.href.includes("discount-code")) {
      let pathname = window.location.href;
      let pathSplits = pathname.split('#')
      console.log("pathSplits", pathSplits)
      if (pathSplits.length == 2) {
        let formDataIsTokenMinted = {
          tokenId: pathSplits[1]
        }
        localStorage.setItem("referralTokenId", pathSplits[1]);
      }
      console.log("Location", window.location)
    }
    dispatch({ type: "percentage", resultDataa: 0 });
    dispatch({ type: "testStarted", isTesting: false });
    dispatch({ type: "testData", resultData: [] });
    axios.get(`${global.apiAdress}/api/user/all-tests`).then((response) => {
      if (response.status === 200 && response.data.tests.length > 0) {
        let count = 0;
        response.data.tests.forEach((element, index) => {
          if (parseInt(element.template_id) === 1) {
            if (element.number_of_traits > 0) {
              if (count === 0) {
                setTestUrl(`/test/${element.id}`);
                count++;
              }
            } else if (index < response.data.tests.length - 1) {
              if (count === 0) {
                setTestUrl(`/test/${element[index + 1].id}`);
                count++;
              }
            }
          } else {
            if (count === 0) {
              setTestUrl(`/test2/${element.id}`);
              count++;
            }
          }
        });
      }
    });
  }, []);

  return (
    <Layout>
      <div className={`${classes["image-wrapper"]}`}>
        <img
          data-aos="zoom-in"
          className={classes["image-card-1"]}
          src={card1}
          alt="card_1"
        />
        <img
          data-aos="zoom-in"
          className={classes["image-card-2"]}
          src={card2}
          alt="card_2"
        />
      </div>
      <div className={`${classes["content-wrapper"]}`}>
        <h1 className={`${classes["main-heading"]}`}>
          Personality Test
        </h1>
        <p className={`${classes["main-description"]}`}>
          Take the Test & Get your Personality Cards
        </p>
        <div className={`${classes["button-wrapper"]}`}>
          <Link onClick={(e)=>{
            localStorage.setItem("emailPopup", "");
          }} to={testUrl} className={`btn ${classes["btn-main"]}`}>
            Let's get Started
          </Link>
          {/* <Link to="/test/1" className={`btn ${classes["btn-main"]}`}>
            Let's get Started
          </Link> */}
          <p className={`${classes["main-enter"]}`}>
            Press <span>Enter</span>
            <img src={enterSign} alt="arrow" />
          </p>
        </div>
        <p className={`${classes["main-para"]}`}>
          This Test is Free & Takes 5-7min 
          {/* | <a href="#">Login Here ...</a> */}
        </p>
        {
          !!winner && (
            <Link to={'/result'} className={`btn ${classes["btn-results"]}`}>
              View my past result
            </Link>  
          )
        }                
      </div>
    </Layout>
  );
};

export default Main;
