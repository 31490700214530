import React from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";

export class Flip extends React.Component {
  constructor(props) {
    super(props);
    this._tickRef = React.createRef();
  }

  componentDidMount() {
    const { value } = this.props;
    this._tickInstance = Tick.DOM.create(this._tickRef.current, {
      value
    });
    Tick.count.down("2021");
  }

  componentDidUpdate() {
    if (!this._tickInstance) return;
    this._tickInstance.value = this.props.value;
  }

  componentWillUnmount() {
    if (!this._tickInstance) return;
    Tick.DOM.destroy(this._tickRef.current);
  }

  render() {
    return (
      <div ref={this._tickRef} className="tick">
        <div data-repeat="true" data-transform="spring -> round -> split -> delay(rtl, 100, 150) -> pad('00')" aria-hidden="true">
          <span data-view="flip">Tick </span>
        </div>
        {/* <div data-repeat="true" data-transform="arrive(5, .01) -> round -> split -> delay(rtl, 100, 150)" aria-hidden="true">
          <span data-view="flip" data-style="transition:revolve(x, .5, .25)">Tick </span>
        </div> */}
      </div>
    );
  }
}
