import React, { Fragment, useEffect, useState } from "react";
import classes from "./Login.module.css";
import TraitsCard from "../../assets/TraitsCard.png";
import EmailSentImage from "../../assets/email-sent.gif";
import { Modal } from 'reactstrap'
import { WalletContext } from "../store/WalletProvider";
import { SessionContext } from "../store/SessionProvider";
import { PersonalityTrait } from "../PersonalityTrait";
import { isEmail } from "../../utils/string";
import TraitCard from "../UI/TraitCard";


const Login = (props) => {
  const [{ address }] = WalletContext();
  const [{ loadSessionData, email, visitorId, result, logout }] = SessionContext();

  const [emailSentModal, setEmailSentModal] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)
  const [highestTrait, setHighestTrait] = useState("");
  const [userGender] = useState("Gender");
  const [firstName] = useState("");
  const [lastName] = useState("");
  const { 
    conscientiousness,
    agreeableness,
    openness,
    extraversion,
    winnerCardName, 
    neuroticism,
    machiavellianism,
    narcissism,
    psychopathy,
    winner,
    bigFiveTraitsDetails,
   } = result;

  useEffect(async () => {
    if (bigFiveTraitsDetails) {
      let data = bigFiveTraitsDetails.sort((a, b) => b.percentage - a.percentage);
      setHighestTrait(data[0].traitName)  
    }
  }, [bigFiveTraitsDetails]);

  return (
    <Fragment>
      <div className="container">
        <div className="text-center">
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12">
              <TraitCard
                conscientiousness={conscientiousness}
                agreeableness={agreeableness}
                openness={openness}
                extraversion={extraversion} 
                neuroticism={neuroticism}
                narcissism={narcissism}
                psychopathy={psychopathy}
                machiavellianism={machiavellianism}
                winnerCardName={winnerCardName}
                height="469px"
              />
              <div hidden class="card__inner ml-5" onClick={(e) => {
                const card = document.querySelector(".card__inner");
                card.classList.toggle('is-flipped');
              }}>
                <div class="card__face card__face--front">
                  <img
                    id="personalityCardWorldView"
                    className="resultCardMain1 resultCardMainImageWrapperImg"
                    style={{ width: 290, height: 435, minHeight: 435 }}
                    src={winner != undefined ? "https://pdfapi.personalitynft.com/public/images/" + winner.card_image : null}
                    alt="card1"
                  />
                </div>
                <div class="card__face card__face--back">
                  <svg onClick={(e) => {
                  }} id="personalityCard"
                    style={{ marginLeft: "25px" }}
                    name="personalityCard" width="290" height="435"
                    xmlns="http://www.w3.org/2000/svg">
                    <image href={TraitsCard} width="290" height="435" />
                    <PersonalityTrait trait={extraversion} x="81" y="139" width="48" height="48" />
                    <PersonalityTrait trait={openness} x="158" y="139" width="48" height="48" />
                    <PersonalityTrait trait={neuroticism} x="82" y="227" width="48" height="48" />
                    <PersonalityTrait trait={agreeableness} x="120" y="238" width="48" height="48" />
                    <PersonalityTrait trait={conscientiousness} x="158" y="227" width="48" height="48" />
                    <PersonalityTrait negative trait={machiavellianism} x="111" y="153" width="65" height="65" />
                    <PersonalityTrait negative trait={narcissism} x="93" y="184" width="65" height="65" />
                    <PersonalityTrait negative trait={psychopathy} x="130" y="184" width="65" height="65" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="col-md-7 col-sm-12">
              <h1 className="mintCardsPageHeading text-left">Login</h1>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <h4 className="mintCardsLinksSecondHeading text-left mt-4 mr-5">Your Primary Value System is a {winner != undefined ? winner.value_name : ""} </h4>
                  <h4 className="mintCardsLinksSecondHeading text-left mr-5">Your Highest Trait is {(highestTrait != "" && highestTrait != null && highestTrait != undefined) ? highestTrait : ""}</h4>
                  <h4 hidden className="mintCardsLinksSecondHeading text-left mr-5">Rarity: Super Rare </h4>

                  {
                    address ? (
                      <div className="walletDiv mt-3 mr-5">
                        <h4 className="mintCardsLinksSecondHeading emailAccountId text-left">Wallet: {address}</h4>
                      </div>
                    ) : null
                  }

                  {email ?
                    <h4 className="mintCardsLinksSecondHeading emailAccountId text-left mt-3">Email: {email} </h4>
                    :
                    null
                  }

                  {email ?
                    <button className="mintCardsComingSoonButton mt-5 pt-2 pb-2" onClick={() => {
                      if (isEmail(email)) {
                        props.sendPdfLinkAsEmail(email)
                        setEmailSentModal(!emailSentModal);
                      }
                    }} >Email me My Cards & PDF {'>'}</button>
                    :
                    null
                  }

                  {!email ?
                    <div>
                      <div className={`mt-3 float-left ${classes["loginTermWrapperWalletOnly"]}`}>
                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className={`form-control  ${classes["loginInputWalletOnly"]}`}
                            placeholder="Enter Email"
                            value={emailInput}
                            onChange={e => setEmailInput(e.target.value)}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className={`form-control  ${classes["loginInputWalletOnly"]}`}
                            placeholder="Enter Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      {
                        errorMessage ? (
                          <div>
                            <p className="mintCardsLinksSecondHeading float-left">NOTE: {errorMessage}</p>
                          </div>
                        ) : null
                      }
                      <button onClick={(e) => {
                        if (isEmail(emailInput)) {
                          let formData = {
                            email: emailInput,
                            password: password,
                            visitorId: visitorId,
                            first_name: firstName,
                            last_name: lastName,
                            gender: userGender.toLowerCase()
                          }
                          let method = "login";
                          loadSessionData(
                            { 
                              formData, method, email, setEmailInput, setPassword, 
                              setEmailInput, setPassword, setErrorMessage
                            } 
                          );
                        }
                        else {
                          setErrorMessage("Your entered a invalid email, please retry!")
                        }
                      }} className={`btn  ${classes["btnSignupWalletOnly"]} float-left mt-1`}>
                        Login
                      </button>
                      <div className="float-left mt-3 signupText">Don't have an account? <span><button className={`${classes["signupbtn"]}`} onClick={() => { props.changeCurrentPage("Signup") }}>Sign up!</button></span></div>
                    </div>
                    :
                    <button className="mintCardsComingSoonButton mt-3 pt-2 pb-2" onClick={logout} >Logout {'>'}</button>
                  }
                </div>
              </div>
            </div>

            <Modal style={{ borderTopRightRadius: 40, color: "white", textAlign: "center" }} contentEditable={false} isOpen={emailSentModal} toggle={() => setEmailSentModal(!emailSentModal)} className='modalEmail modal-lg modal-dialog-centered' contentClassName="modalEmail" >
              <div>
                <svg className="close closeModalBtn btnMargins" style={{ cursor: "pointer", height: 40, width: 40, fontWeight: "lighter", color: "#fff", position: 'absolute', top: "-10%", right: "-10%" }} onClick={() => {
                  setEmailSentModal(!emailSentModal)
                }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 41">
                  <style>
                    {
                      `.cls-1
                        {
                          fill:#fff
                        }
                        @media only screen and (max-width: 576px) {
                          .btnMargins{
                            right:'15%';
                          }
                        }
                      `
                    }
                  </style>
                  <path className="cls-1 btnMargins" d="M28.7,134.3l-6.9-6.8,6.9-6.8-1.4-1.4-6.8,6.9-6.8-6.9-1.4,1.4,6.9,6.8-6.9,6.8,1.4,1.4,6.8-6.9,6.8,6.9Z" transform="translate(0 -107)" />
                  <path className="cls-1 btnMargins" d="M20.5,107A20.5,20.5,0,1,0,41,127.5,20.5,20.5,0,0,0,20.5,107Zm0,39.5a19,19,0,1,1,19-19A19,19,0,0,1,20.5,146.5Z" transform="translate(0 -107)" />
                </svg>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <img className="mt-4" src={EmailSentImage} style={{ height: 210 }} />
                    <h1 className={`${classes["modalEmailSentTitle"]}`}>Email Sent!</h1>
                    <p className={`${classes["modalEmailSentMessage"]}`}>We just sent you the PDF, Cards, and Community Invite.</p>
                  </div>
                </div>
                <div hidden className="modalEmailFooter row mb-5">
                  <div hidden className="col-lg-8 col-md-12 modalEmailFooterCol1">
                    <input type="email" value={emailInput} name="email" onChange={(e) => setEmailInput(e.target.value)} className="modalEmailText" placeholder="Enter Email  " />
                  </div>
                  <div className="col-lg-12 text-align-center col-md-12 modalEmailFooterCol2 mt-xl-0 mt-lg-0 mt-1">
                    <button className="modalEmailOkButton" onClick={() => {
                      // signupEmail()
                      setEmailSentModal(!emailSentModal);
                    }} >OK</button>
                  </div>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
