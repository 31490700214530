import React, { Fragment, useEffect, useState } from "react";
import classes from "./Signup.module.css";

import { WalletContext } from "../store/WalletProvider";
import Form from 'react-bootstrap/Form';
import { SessionContext } from "../store/SessionProvider";
import { isEmail } from "../../utils/string";
import TraitCard from "../UI/TraitCard";


const Signup = (props) => {
  const [{ address }] = WalletContext();
  const [{ loadSessionData, email, visitorId, result, logout }] = SessionContext();

  const [emailSentModal, setEmailSentModal] = useState(false)
  const [emailInput, setEmailInput] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [highestTrait, setHighestTrait] = useState("");
  const [userGender, setUserGender] = useState("Gender");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const {
    conscientiousness,
    agreeableness,
    openness,
    extraversion,
    winnerCardName,
    neuroticism,
    machiavellianism,
    narcissism,
    psychopathy,
    winner,
    bigFiveTraitsDetails,
  } = result;

  useEffect(async () => {
    if (bigFiveTraitsDetails) {
      let data = bigFiveTraitsDetails.sort((a, b) => b.percentage - a.percentage);
      setHighestTrait(data[0].traitName)
    }
  }, [bigFiveTraitsDetails]);

  return (
    <Fragment>
      <div className="container">
        <div className="text-center">
          <div className="row mt-5">
            <div className="col-md-5 col-sm-12">
              <TraitCard
                conscientiousness={conscientiousness}
                agreeableness={agreeableness}
                openness={openness}
                extraversion={extraversion} 
                neuroticism={neuroticism}
                narcissism={narcissism}
                psychopathy={psychopathy}
                machiavellianism={machiavellianism}
                winnerCardName={winnerCardName}
                height="469px"
              />
            </div>

            <div className="col-md-7 col-sm-12">
            <h1 className="mintCardsPageHeading text-left">Signup</h1>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  <h4 className="mintCardsLinksSecondHeading text-left mt-4 mr-5">Your Primary Value System is a {winner != undefined ? winner.value_name : ""} </h4>
                  <h4 className="mintCardsLinksSecondHeading text-left mr-5">Your Highest Trait is {(highestTrait != "" && highestTrait != null && highestTrait != undefined) ? highestTrait : ""}</h4>
                  <h4 hidden className="mintCardsLinksSecondHeading text-left mr-5">Rarity: Super Rare </h4>
                  {
                    address ? (
                      <div className="walletDiv mt-3 mr-5">
                        <h4 className="mintCardsLinksSecondHeading emailAccountId text-left">Wallet: {address}</h4>
                      </div>
                    ) : null
                  }

                  {email ?
                    <h4 className="mintCardsLinksSecondHeading emailAccountId text-left mt-3">Email: {email} </h4>
                    :
                    null
                  }

                  {email ?
                    <button className="mintCardsComingSoonButton mt-5 pt-2 pb-2" onClick={() => {
                      if (isEmail(email)) {
                        props.sendPdfLinkAsEmail(email)
                        setEmailSentModal(!emailSentModal);
                      }
                    }} >Email me My Cards & PDF {'>'}</button>
                    :
                    null
                  }

                  {!email ?
                    <div>
                      <div className={`mt-3 float-left ${classes["loginTermWrapperWalletOnly"]}`}>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className={`form-control  ${classes["loginInputWalletOnly"]}`}
                              placeholder="Enter First Name"
                              onChange={e => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className={`form-control  ${classes["loginInputWalletOnly"]}`}
                              placeholder="Enter Last Name"
                              onChange={e => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                        <div style={{ display: "flex", paddingBottom: "13px", color: "white" }}>
                          <Form id="gender-form">
                            <Form.Check
                              inline
                              type="radio"
                              id="default-radio"
                              label="Male"
                              name="gender-form"
                              onClick={() => setUserGender("Male")}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              id="default-radio1"
                              label="Female"
                              name="gender-form"
                              onClick={() => setUserGender("Female")}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              id="default-radio2"
                              label="Other"
                              name="gender-form"
                              onClick={() => setUserGender("Other")}
                            />
                            <Form.Check
                              inline
                              type="radio"
                              id="default-radio3"
                              label="Prefer not to say"
                              name="gender-form"
                              onClick={() => setUserGender("Prefer not to say")}
                            />
                          </Form>
                        </div>

                        <div className="input-group mb-3">
                          <input
                            type="text"
                            className={`form-control  ${classes["loginInputWalletOnly"]}`}
                            placeholder="Enter Email"
                            value={emailInput}
                            onChange={e => setEmailInput(e.target.value)}
                          />
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            className={`form-control  ${classes["loginInputWalletOnly"]}`}
                            placeholder="Enter Password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      {
                        errorMessage ? (
                          <div>
                            <p className="mintCardsLinksSecondHeading float-left">NOTE: {errorMessage}</p>
                          </div>
                        ) : null
                      }

                      <button onClick={(e) => {
                        if (isEmail(emailInput)) {
                          let formData = {
                            email: emailInput,
                            password: password,
                            visitorId: visitorId,
                            first_name: firstName,
                            last_name: lastName,
                            gender: userGender.toLowerCase()
                          }
                          let method = "signup";
                          loadSessionData(
                            {
                              formData, method, email, setEmailInput, setPassword,
                              setEmailInput, setPassword, setErrorMessage
                            }
                          );
                        }
                        else {
                          setErrorMessage("Your entered a invalid email, please retry!")
                        }
                      }} className={`btn  ${classes["btnSignupWalletOnly"]} float-left mt-1`}>
                        Signup
                      </button>
                      <div className="float-left mt-3 signupText">Already have an account? <span><button className={`${classes["signupbtn"]}`} onClick={() => { props.changeCurrentPage("Login") }}>Login!</button></span></div>
                    </div>
                    :
                    <button className="mintCardsComingSoonButton mt-3 pt-2 pb-2" onClick={logout} >Logout {'>'}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Signup;
