
export const initialSessionState = {
  loading: true,
  loadSessionData: () => {},
  loadEmailData: () => {},
  logout: () => {},
  email: '',
  password: '',
  loggedIn: false,
  loggedUser: '',
  wrongPassword: false,
  errorMsg: '',
  currentPage: 'Result',
  nextMint: null,
  activeConnections: null,
  compatibleParticipants: null,
  visitorId: localStorage.getItem('visitorId') || '',
  result: null,
  userId: '',
};

const SessionReducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return {
        ...initialSessionState,
      };
    case "update":
      return {
        ...state,
        [action.key]: action.value,
      };
    case "multiUpdate":
      return {
        ...state,
        ...action.state,
      };
    default:
      throw new Error();
  }
};

export default SessionReducer;
