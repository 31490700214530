import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Question2 from "./Question2";
import { Context } from "./../store/StateProvider";
import Header from "../header/Header";
import classes from "./Test2.module.css";
import RedPNG from "../../assets/red-error.png";

let first_multiplier;
let second_multiplier;
let third_multiplier;
let TESTANSARRAY = [];
let count = 0;
let countend = 20;

const Test2 = () => {
  // states
  const [state, dispatch] = Context();
  const [QUESTIONS, setQUESTIONS] = useState([]);
  const [party, setParty] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [nextBtn, setNextBtn] = useState(false);

  const [isDisableError, setIsDisabledError] = useState(false);
  const [validationErrorCounter, setValidationErrorCounter] = useState(0);
  const [scriptData, setScriptData] = useState("");
  let navigate = useNavigate();
  const percentages = useLocation();
  let { id } = useParams();
  const move = useRef();
  // states end


  // data fetching for the multiplier
  const multiLoad = async () => {
    const response = await axios.get(
      `${global.apiAdress}/api/user/test/value-multiplier`
    );
    if (response.status === 200) {
      // console.log(response.data.valueResult);
      const third = response.data.valueResult.third_choice;
      const second = response.data.valueResult.second_choice;
      const first = response.data.valueResult.first_choice;
      first_multiplier = parseInt(first);
      second_multiplier = parseInt(second);
      third_multiplier = parseInt(third);
    }
  };
  // data fetching for the multiplier

  // data fetching for the partites
  const partyLoad = async () => {
    let PartyArray = [];
    const response = await axios.get(
      `${global.apiAdress}/api/user/test/world-values/all`
    );
    console.log(response);
    if (response.status === 200) {
      PartyArray = response.data.valueResults;
      console.log(PartyArray);
      const PartyData = PartyArray.map((e) => {
        return {
          id: e.id,
          value_id: e.value_id,
          value_name: e.value_name,
          value_result_link: e.value_result_link,
          card_image: e.card_image,
          description: e.description,
          first_Choice: 0,
          second_Choice: 0,
          third_Choice: 0,
          total_Score: 0,
          most_Common: 0,
          total_Score_Distribution: null,
          most_Common_Distribution: null,
        };
      });
      console.log(PartyData);
      setParty(PartyData);
    }
  };
  // data fetching for the partites end

  // data fetching from server
  const apiLoad = async () => {
    setLoading(true);
    const response = await axios.get(
      `${global.apiAdress}/api/user/test/world-values/${id}`
    );
    if (response.status === 200) {
      const JSONData = response.data.questions; // JSON data
      // to convert data into array form
      const Data = JSONData.map((e) => {
        return {
          id: e.id,
          test_three_answers: JSON.parse(e.test_three_answers),
          question_title: e.question_title,
          test_id: e.test_id,
          trait_id: e.trait_id,
          IsEnabled: e.IsEnabled,
          IsMissed: true
        };
      });
      // filteration for option = null
      const filteredData = Data.filter((e) => e.test_three_answers.length > 0);

      // pagination

      if (filteredData.length > 9) {
        if (countend < filteredData.length) {
          setIsDisabled(true);
          setNextBtn(true);
          const PagedData = filteredData.slice(count, countend);
          // to enable first index
          const PagedDataEnable = PagedData.map((e, index) => {
            if (index === 0) {
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: true,
                IsMissed: e.IsMissed
              };
            } else {
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: false,
                IsMissed: e.IsMissed
              };
            }
          });
          // to enable first index end
          setQUESTIONS(PagedDataEnable);
        } else {
          setIsDisabled(true);
          setNextBtn(false);
          const PagedData = filteredData.slice(count, filteredData.length);
          // logic to enable first index element on every page
          const PagedDataEnable = PagedData.map((e, index) => {
            if (index === 0) {
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: true,
                IsMissed: e.IsMissed
              };
            } else {
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: false,
                IsMissed: e.IsMissed
              };
            }
          });
          // logic to enable first index element on every page end
          setQUESTIONS(PagedDataEnable);
        }
        count = count + 9;
        countend = countend + 9;
      } else {
        setQUESTIONS(filteredData);
      }
      // pagination end
    } else {
      alert("Connection Error!");
    }
    setLoading(false);
  };
  // data fetching from server end

  // use Effect
  useEffect(() => {
    axios.get(`${global.apiAdress}/api/code-snippets/list`).then(response => {
      console.log(response);
      if(response.data.status=200)
      {
        let CodeSnippetsRaw = response.data.code_snippets;
        const CodeSnippets = CodeSnippetsRaw.filter(
          (e) => e.code_page == "Test Page"
        );
        if(CodeSnippets.length>0)
        {
          setScriptData(CodeSnippets[0].code);
        }
        console.log(CodeSnippets)
      }
    })
    dispatch({ type: "testStarted", isTesting: true });
    apiLoad();
    partyLoad();
    multiLoad();
  }, []);
  // use Effect end

  const tiebrakers = (mostCommonScoreDistribution) => {
    let winner = null; // global for all tie breakers final winner will here.

    // clear winner //
    let max = 0;
    let maxTotalScore = 0;
    // for maximum first choice vote
    const maxFirstChoice = mostCommonScoreDistribution.map((e) => {
      if (e.first_Choice > max) {
        max = e.first_Choice;
        return max;
      } else {
        return max;
      }
    });
    const maxFC = Math.max(...maxFirstChoice);
    const filteredMaxFC = mostCommonScoreDistribution.filter(
      (e) => e.first_Choice === maxFC
    );
    console.log("filteredMaxFC", filteredMaxFC);
    // for maximum first choice vote end

    // for maximum total score
    const maxTotalSA = mostCommonScoreDistribution.map((e) => {
      if (e.total_Score > maxTotalScore) {
        maxTotalScore = e.total_Score;
        return maxTotalScore;
      } else {
        return maxTotalScore;
      }
    });
    const maxSC = Math.max(...maxTotalSA);
    const filteredMaxSC = mostCommonScoreDistribution.filter(
      (e) => e.total_Score === maxSC
    );
    console.log("filteredMaxSC", filteredMaxSC);
    // for maximum total score end

    let IMC = 0;
    const maxMostCommon = mostCommonScoreDistribution.map((e) => {
      if (e.most_Common > IMC) {
        IMC = e.most_Common;
        return IMC;
      } else {
        return IMC;
      }
    });
    const SIMC = Math.max(...maxMostCommon);
    const MaxMostCom = mostCommonScoreDistribution.filter(
      (e) => e.most_Common === SIMC
    );
    console.log(MaxMostCom, "MaxMostCom");

    axios.get(`${global.apiAdress}/api/value-result/setting/edit/${1}`).then(response => {
      console.log(response)
      if (response.status === 200) {

        //Check First Priority
        if ((response.data.valueSetting.first_priority_first == "Most 1st Choose Values" && response.data.valueSetting.first_priority_second == "Highest Total Score") || (response.data.valueSetting.first_priority_first == "Highest Total Score" && response.data.valueSetting.first_priority_second == "Most 1st Choose Values")) {
          if (filteredMaxFC.length === 1 && filteredMaxSC.length === 1) {
            if (filteredMaxFC[0].value_id === filteredMaxSC[0].value_id) {
              winner = filteredMaxFC[0];
            }
          }
          console.log(winner, "Clear winner");
        }
        else if ((response.data.valueSetting.first_priority_first == "Most 1st Choose Values" && response.data.valueSetting.first_priority_second == "Most Common Values") || (response.data.valueSetting.first_priority_first == "Most Common Values" && response.data.valueSetting.first_priority_second == "Most 1st Choose Values")) {
          // filteredMaxFC.length comes from cleaar winner
          if (filteredMaxFC.length === 1 && MaxMostCom.length === 1) {
            if (filteredMaxFC[0].value_id === MaxMostCom[0].value_id) {
              winner = filteredMaxFC[0];
            }
          }
          console.log(winner, "third tie braker");

        }
        else if ((response.data.valueSetting.first_priority_first == "Most 1st Choose Values" && response.data.valueSetting.first_priority_second.includes("Margin of")) || (response.data.valueSetting.first_priority_first.includes("Margin of") && response.data.valueSetting.first_priority_second == "Most 1st Choose Values")) {
          // extracting only first choices
          const onlyFirstChoices = mostCommonScoreDistribution.map(
            (e) => e.first_Choice
          );
          // removing duplicacy in first choices
          const removedDuplicacy = onlyFirstChoices.filter(
            (item, index) => onlyFirstChoices.indexOf(item) === index
          );
          // getting max of the array
          const stiebMax = Math.max.apply(null, removedDuplicacy); // get max of PartyArray
          removedDuplicacy.splice(removedDuplicacy.indexOf(stiebMax), 1); // removing max of array
          const stiebSMax = Math.max.apply(null, removedDuplicacy);
          // getting max of the array end
          const filteredFirsthighest = mostCommonScoreDistribution.filter(
            (e) => e.first_Choice === stiebMax
          );
          console.log(filteredFirsthighest, "filteredFirsthighest");
          if (response.data.valueSetting.first_priority_first == "Margin of +2" || response.data.valueSetting.first_priority_second == "Margin of +2") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 2) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +3" || response.data.valueSetting.first_priority_second == "Margin of +3") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 3) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +4" || response.data.valueSetting.first_priority_second == "Margin of +4") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 4) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +5" || response.data.valueSetting.first_priority_second == "Margin of +5") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 5) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +6" || response.data.valueSetting.first_priority_second == "Margin of +6") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 6) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +7" || response.data.valueSetting.first_priority_second == "Margin of +7") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 7) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +8" || response.data.valueSetting.first_priority_second == "Margin of +8") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 8) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +9" || response.data.valueSetting.first_priority_second == "Margin of +9") {
            if (filteredFirsthighest.length === 1) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 9) {
                winner = filteredFirsthighest[0];
              }
            }
          }
          else if (response.data.valueSetting.first_priority_first == "Margin of +10" || response.data.valueSetting.first_priority_second == "Margin of +10") {
            if (filteredFirsthighest.length === 10) {
              const diffrence = stiebMax - stiebSMax;
              if (diffrence >= 2) {
                winner = filteredFirsthighest[0];
              }
            }
          }
        }
        //Check Second Priority
        if (winner === null) {
          if ((response.data.valueSetting.second_priority_first == "Most 1st Choose Values" && response.data.valueSetting.second_priority_second == "Highest Total Score") || (response.data.valueSetting.second_priority_first == "Highest Total Score" && response.data.valueSetting.second_priority_second == "Most 1st Choose Values")) {
            if (filteredMaxFC.length === 1 && filteredMaxSC.length === 1) {
              if (filteredMaxFC[0].value_id === filteredMaxSC[0].value_id) {
                winner = filteredMaxFC[0];
              }
            }
            console.log(winner, "Clear winner");
          }
          else if ((response.data.valueSetting.second_priority_first == "Most 1st Choose Values" && response.data.valueSetting.second_priority_second == "Most Common Values") || (response.data.valueSetting.second_priority_first == "Most Common Values" && response.data.valueSetting.second_priority_second == "Most 1st Choose Values")) {
            // filteredMaxFC.length comes from cleaar winner
            if (filteredMaxFC.length === 1 && MaxMostCom.length === 1) {
              if (filteredMaxFC[0].value_id === MaxMostCom[0].value_id) {
                winner = filteredMaxFC[0];
              }
            }
            console.log(winner, "third tie braker");

          }
          else if ((response.data.valueSetting.second_priority_first == "Most 1st Choose Values" && response.data.valueSetting.second_priority_second.includes("Margin of")) || (response.data.valueSetting.second_priority_first.includes("Margin of") && response.data.valueSetting.second_priority_second == "Most 1st Choose Values")) {
            // extracting only first choices
            const onlyFirstChoices = mostCommonScoreDistribution.map(
              (e) => e.first_Choice
            );
            // removing duplicacy in first choices
            const removedDuplicacy = onlyFirstChoices.filter(
              (item, index) => onlyFirstChoices.indexOf(item) === index
            );
            // getting max of the array
            const stiebMax = Math.max.apply(null, removedDuplicacy); // get max of PartyArray
            removedDuplicacy.splice(removedDuplicacy.indexOf(stiebMax), 1); // removing max of array
            const stiebSMax = Math.max.apply(null, removedDuplicacy);
            // getting max of the array end
            const filteredFirsthighest = mostCommonScoreDistribution.filter(
              (e) => e.first_Choice === stiebMax
            );
            if (response.data.valueSetting.second_priority_first == "Margin of +2" || response.data.valueSetting.second_priority_second == "Margin of +2") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 2) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +3" || response.data.valueSetting.second_priority_second == "Margin of +3") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 3) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +4" || response.data.valueSetting.second_priority_second == "Margin of +4") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 4) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +5" || response.data.valueSetting.second_priority_second == "Margin of +5") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 5) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +6" || response.data.valueSetting.second_priority_second == "Margin of +6") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 6) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +7" || response.data.valueSetting.second_priority_second == "Margin of +7") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 7) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +8" || response.data.valueSetting.second_priority_second == "Margin of +8") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 8) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +9" || response.data.valueSetting.second_priority_second == "Margin of +9") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 9) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.second_priority_first == "Margin of +10" || response.data.valueSetting.second_priority_second == "Margin of +10") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 10) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
          }
        }
        //Check Third Priority
        if (winner === null) {
          if ((response.data.valueSetting.third_priority_first == "Most 1st Choose Values" && response.data.valueSetting.third_priority_second == "Highest Total Score") || (response.data.valueSetting.third_priority_first == "Highest Total Score" && response.data.valueSetting.third_priority_second == "Most 1st Choose Values")) {
            if (filteredMaxFC.length === 1 && filteredMaxSC.length === 1) {
              if (filteredMaxFC[0].value_id === filteredMaxSC[0].value_id) {
                winner = filteredMaxFC[0];
              }
            }
            console.log(winner, "Clear winner");
          }
          else if ((response.data.valueSetting.third_priority_first == "Most 1st Choose Values" && response.data.valueSetting.third_priority_second == "Most Common Values") || (response.data.valueSetting.third_priority_first == "Most Common Values" && response.data.valueSetting.third_priority_second == "Most 1st Choose Values")) {
            // filteredMaxFC.length comes from cleaar winner
            if (filteredMaxFC.length === 1 && MaxMostCom.length === 1) {
              if (filteredMaxFC[0].value_id === MaxMostCom[0].value_id) {
                winner = filteredMaxFC[0];
              }
            }
            console.log(winner, "third tie braker");

          }
          else if ((response.data.valueSetting.third_priority_first == "Most 1st Choose Values" && response.data.valueSetting.third_priority_second.includes("Margin of")) || (response.data.valueSetting.third_priority_first.includes("Margin of") && response.data.valueSetting.third_priority_second == "Most 1st Choose Values")) {
            // extracting only first choices
            const onlyFirstChoices = mostCommonScoreDistribution.map(
              (e) => e.first_Choice
            );
            // removing duplicacy in first choices
            const removedDuplicacy = onlyFirstChoices.filter(
              (item, index) => onlyFirstChoices.indexOf(item) === index
            );
            // getting max of the array
            const stiebMax = Math.max.apply(null, removedDuplicacy); // get max of PartyArray
            removedDuplicacy.splice(removedDuplicacy.indexOf(stiebMax), 1); // removing max of array
            const stiebSMax = Math.max.apply(null, removedDuplicacy);
            // getting max of the array end
            const filteredFirsthighest = mostCommonScoreDistribution.filter(
              (e) => e.first_Choice === stiebMax
            );
            console.log(filteredFirsthighest, "filteredFirsthighest");
            if (response.data.valueSetting.third_priority_first == "Margin of +2" || response.data.valueSetting.third_priority_second == "Margin of +2") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 2) {
                  winner = filteredFirsthighest[0];
                }
              }
              console.log(winner, "third tie braker-2");
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +3" || response.data.valueSetting.third_priority_second == "Margin of +3") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 3) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +4" || response.data.valueSetting.third_priority_second == "Margin of +4") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 4) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +5" || response.data.valueSetting.third_priority_second == "Margin of +5") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 5) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +6" || response.data.valueSetting.third_priority_second == "Margin of +6") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 6) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +7" || response.data.valueSetting.third_priority_second == "Margin of +7") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 7) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +8" || response.data.valueSetting.third_priority_second == "Margin of +8") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 8) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +9" || response.data.valueSetting.third_priority_second == "Margin of +9") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 9) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
            else if (response.data.valueSetting.third_priority_first == "Margin of +10" || response.data.valueSetting.third_priority_second == "Margin of +10") {
              if (filteredFirsthighest.length === 1) {
                const diffrence = stiebMax - stiebSMax;
                if (diffrence >= 10) {
                  winner = filteredFirsthighest[0];
                }
              }
            }
          }
        }
        //Check Half Values Priority 1
        if (winner === null) {
          console.log("filteredMaxFC", filteredMaxFC);
          console.log(mostCommonScoreDistribution, "mostCommonScoreDistribution");
          // filteredMaxFC is comming from clear winner
          const valueIdOfParty = filteredMaxFC[0].value_id;
          console.log(valueIdOfParty)
          const indexofParty = mostCommonScoreDistribution.findIndex(
            (e) => e.value_id === valueIdOfParty
          );
          console.log(indexofParty);
          console.log(mostCommonScoreDistribution);
          if (
            mostCommonScoreDistribution[indexofParty].first_Choice ===
            mostCommonScoreDistribution[indexofParty + 1].first_Choice
          ) {
            const F1 = mostCommonScoreDistribution[indexofParty].value_name;
            const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
            const F1_id = mostCommonScoreDistribution[indexofParty].value_id;
            const F2_id = mostCommonScoreDistribution[indexofParty + 1].value_id;
            let F = 0.0;
            console.log(F1, "middle of", F2, "Between tie braker");
            if (parseFloat(F1_id) > parseFloat(F2_id)) {
              console.log(parseFloat(F1_id), "F1");
              console.log(parseFloat(F2_id), "F2");
              let tempF1 = parseFloat(F1_id) - parseFloat(F2_id);
              let tempF2 = parseFloat(tempF1) / 2.0;
              F = parseFloat(F1_id) - parseFloat(tempF2);
              console.log(F, "Between Value-2a");
            }
            else {
              console.log(parseFloat(F1_id), "F1");
              console.log(parseFloat(F2_id), "F2");
              let tempF1 = parseFloat(F2_id) - parseFloat(F1_id);
              let tempF2 = parseFloat(tempF1) / 2.0;
              F = parseFloat(F2_id) - parseFloat(tempF2);
              console.log(F, "Between Value-2b");
            }
            console.log(F, "Between Value-1");
            const filteredData = mostCommonScoreDistribution.filter((e) => parseFloat(e.value_id) == parseFloat(F));
            winner = filteredData[0];
            console.log(filteredData);
          } else if (indexofParty != 0) {
            if (mostCommonScoreDistribution[indexofParty].first_Choice ===
              mostCommonScoreDistribution[indexofParty - 1].first_Choice) {
              const F1 = mostCommonScoreDistribution[indexofParty].value_name;
              const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
              console.log(F1, "middle of", F2, "Between tie braker");
              const F1_id = mostCommonScoreDistribution[indexofParty].value_id;
              const F2_id = mostCommonScoreDistribution[indexofParty + 1].value_id;
              let F = 0.0;
              if (parseFloat(F1_id) > parseFloat(F2_id)) {
                console.log(parseFloat(F1_id), "F1");
                console.log(parseFloat(F2_id), "F2");
                F = parseFloat(F1_id) / parseFloat(F2_id);
                console.log(F, "Between Value-2a");
              }
              else {
                console.log(parseFloat(F1_id), "F1");
                console.log(parseFloat(F2_id), "F2");
                F = parseFloat(F2_id) / parseFloat(F1_id);
                console.log(F, "Between Value-2b");
              }
              console.log(F, "Between Value-2");
              const filteredData = mostCommonScoreDistribution.filter((e) => parseFloat(e.value_id) == parseFloat(F));
              winner = filteredData[0];
              console.log(filteredData);
            }
          }
        }
        //Check Half Values Priority 2
        if (winner === null) {
          console.log("filteredMaxSC", filteredMaxSC);
          console.log(mostCommonScoreDistribution, "mostCommonScoreDistribution");
          // filteredMaxSC is comming from clear winner
          const valueIdOfParty = filteredMaxSC[0].value_id;
          console.log(valueIdOfParty)
          const indexofParty = mostCommonScoreDistribution.findIndex(
            (e) => e.value_id === valueIdOfParty
          );
          console.log(indexofParty);
          console.log(mostCommonScoreDistribution);
          if (
            mostCommonScoreDistribution[indexofParty].total_Score ===
            mostCommonScoreDistribution[indexofParty + 1].total_Score
          ) {
            const F1 = mostCommonScoreDistribution[indexofParty].value_name;
            const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
            const F1_id = mostCommonScoreDistribution[indexofParty].value_id;
            const F2_id = mostCommonScoreDistribution[indexofParty + 1].value_id;
            let F = 0.0;
            console.log(F1, "middle of", F2, "Between tie braker");
            if (parseFloat(F1_id) > parseFloat(F2_id)) {
              console.log(parseFloat(F1_id), "F1");
              console.log(parseFloat(F2_id), "F2");
              let tempF1 = parseFloat(F1_id) - parseFloat(F2_id);
              let tempF2 = parseFloat(tempF1) / 2.0;
              F = parseFloat(F1_id) - parseFloat(tempF2);
              console.log(F, "Between Value-2a");
            }
            else {
              console.log(parseFloat(F1_id), "F1");
              console.log(parseFloat(F2_id), "F2");
              let tempF1 = parseFloat(F2_id) - parseFloat(F1_id);
              let tempF2 = parseFloat(tempF1) / 2.0;
              F = parseFloat(F2_id) - parseFloat(tempF2);
              console.log(F, "Between Value-2b");
            }
            console.log(F, "Between Value-1");
            const filteredData = mostCommonScoreDistribution.filter((e) => parseFloat(e.value_id) == parseFloat(F));
            winner = filteredData[0];
            console.log(filteredData);
          } else if (indexofParty != 0) {
            if (mostCommonScoreDistribution[indexofParty].total_Score ===
              mostCommonScoreDistribution[indexofParty - 1].total_Score) {
              const F1 = mostCommonScoreDistribution[indexofParty].value_name;
              const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
              console.log(F1, "middle of", F2, "Between tie braker");
              const F1_id = mostCommonScoreDistribution[indexofParty].value_id;
              const F2_id = mostCommonScoreDistribution[indexofParty + 1].value_id;
              let F = 0.0;
              if (parseFloat(F1_id) > parseFloat(F2_id)) {
                console.log(parseFloat(F1_id), "F1");
                console.log(parseFloat(F2_id), "F2");
                F = parseFloat(F1_id) / parseFloat(F2_id);
                console.log(F, "Between Value-2a");
              }
              else {
                console.log(parseFloat(F1_id), "F1");
                console.log(parseFloat(F2_id), "F2");
                F = parseFloat(F2_id) / parseFloat(F1_id);
                console.log(F, "Between Value-2b");
              }
              console.log(F, "Between Value-2");
              const filteredData = mostCommonScoreDistribution.filter((e) => parseFloat(e.value_id) == parseFloat(F));
              winner = filteredData[0];
              console.log(filteredData);
            }
          }
        }
        // Check 7th Periority
        if (winner === null) {
          if ((response.data.valueSetting.seventh_priority == "Highest Total Score")) {
            winner = filteredMaxSC[0];
          }
          else if ((response.data.valueSetting.seventh_priority == "Most Common Values")) {
            winner = MaxMostCom[0];
          }
          if ((response.data.valueSetting.seventh_priority == "Most 1st Choose Values")) {
            winner = filteredMaxFC[0];
          }
        }
        // Check 8th Periority
        if (winner === null) {
          if ((response.data.valueSetting.eigth_priority == "Highest Total Score")) {
            winner = filteredMaxSC[0];
          }
          else if ((response.data.valueSetting.eigth_priority == "Most Common Values")) {
            winner = MaxMostCom[0];
          }
          if ((response.data.valueSetting.eigth_priority == "Most 1st Choose Values")) {
            winner = filteredMaxFC[0];
          }
        }
        // Check 9th Periority
        if (winner === null) {
          if ((response.data.valueSetting.ninth_priority == "Highest Total Score")) {
            winner = filteredMaxSC[0];
          }
          else if ((response.data.valueSetting.ninth_priority == "Most Common Values")) {
            winner = MaxMostCom[0];
          }
          if ((response.data.valueSetting.ninth_priority == "Most 1st Choose Values")) {
            winner = filteredMaxFC[0];
          }
        }
        global.winner = winner;
        dispatch({ type: "TEMPLATE2_WINNER", winner: winner });
        navigate(`/reportResult/${id}`, { state: percentages.state });
        // setFirstChoice(response.data.valueSetting.first_choice)
        // setSecondChoice(response.data.valueSetting.second_choice)
        // setThirdChoice(response.data.valueSetting.third_choice)
        // setForthChoice(response.data.valueSetting.forth_choice)
        // setFifthChoice(response.data.valueSetting.fifth_choice)
        // setSixthChoice(response.data.valueSetting.sixth_choice)
        // setSeventhChoice(response.data.valueSetting.seventh_choice)
        // setEigthChoice(response.data.valueSetting.eigth_choice)
        // setNinthChoice(response.data.valueSetting.ninth_choice)

        // // Before Four
        // setFirstPriorityFirstVal(response.data.valueSetting.first_priority_first)
        // setFirstPrioritySecondVal(response.data.valueSetting.first_priority_second)
        // setSecondPriorityFirstVal(response.data.valueSetting.second_priority_first)
        // setSecondPrioritySecondVal(response.data.valueSetting.second_priority_second)
        // setThirdPriorityFirstVal(response.data.valueSetting.third_priority_first)
        // setThirdPrioritySecondVal(response.data.valueSetting.third_priority_second)

        // setForthPriorityFirstVal(response.data.valueSetting.forth_priority_first)
        // setForthPrioritySecondVal(response.data.valueSetting.forth_priority_second)
        // setFifthPriorityFirstVal(response.data.valueSetting.fifth_priority_first)
        // setFifthPrioritySecondVal(response.data.valueSetting.fifth_priority_second)
        // setSixthPriorityFirstVal(response.data.valueSetting.sixth_priority_first)
        // setSixthPrioritySecondVal(response.data.valueSetting.sixth_priority_second)

        // setSeventhPriority(response.data.valueSetting.seventh_priority)
        // setEigthPriority(response.data.valueSetting.eigth_priority)
        // setNinthPriority(response.data.valueSetting.ninth_priority)
      }
    })

    ////////////////////////////////////////
    ////// TIE BRAKERS /////////////////////
    ///////////////////////////////////////




    // // clear winner end //
    // console.log(mostCommonScoreDistribution, "mostCommonScoreDistribution");
    // //second tie braker
    // if (winner === null) {
    //   // extracting only first choices
    //   const onlyFirstChoices = mostCommonScoreDistribution.map(
    //     (e) => e.first_Choice
    //   );
    //   // removing duplicacy in first choices
    //   const removedDuplicacy = onlyFirstChoices.filter(
    //     (item, index) => onlyFirstChoices.indexOf(item) === index
    //   );
    //   // getting max of the array
    //   const stiebMax = Math.max.apply(null, removedDuplicacy); // get max of PartyArray
    //   removedDuplicacy.splice(removedDuplicacy.indexOf(stiebMax), 1); // removing max of array
    //   const stiebSMax = Math.max.apply(null, removedDuplicacy);
    //   // getting max of the array end
    //   const filteredFirsthighest = mostCommonScoreDistribution.filter(
    //     (e) => e.first_Choice === stiebMax
    //   );
    //   console.log(filteredFirsthighest, "filteredFirsthighest");
    //   if (filteredFirsthighest.length === 1) {
    //     const diffrence = stiebMax - stiebSMax;
    //     if (diffrence >= 2) {
    //       winner = filteredFirsthighest[0];
    //     }
    //   }
    //   console.log(winner, "second tie braker");
    // }
    // //second tie braker end

    // //Third Tie Braker
    // if (winner === null) {


    // }

    // //Third Tie Braker end

    // // between values tie braker
    // //winner === null
    // if (true) {
    //   // filteredMaxFC is comming from clear winner
    //   const valueIdOfParty = filteredMaxFC[0].value_id;
    //   const indexofParty = mostCommonScoreDistribution.findIndex(
    //     (e) => e.value_id === valueIdOfParty
    //   );
    //   if (
    //     mostCommonScoreDistribution[indexofParty].first_Choice ===
    //     mostCommonScoreDistribution[indexofParty + 1]
    //   ) {
    //     const F1 = mostCommonScoreDistribution[indexofParty].value_name;
    //     const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
    //     console.log(F1, "middle of", F2, "Between tie braker");
    //   } else if (
    //     mostCommonScoreDistribution[indexofParty].first_Choice ===
    //     mostCommonScoreDistribution[indexofParty - 1]
    //   ) {
    //     const F1 = mostCommonScoreDistribution[indexofParty].value_name;
    //     const F2 = mostCommonScoreDistribution[indexofParty + 1].value_name;
    //     console.log(F1, "middle of", F2, "Between tie braker");
    //   }
    // }
    // // between values tie braker end
    // global.winner = winner;
    // dispatch({ type: "TEMPLATE2_WINNER", winner: winner });

  };

  //Calculations
  const calculations = () => {
    let updatedParty = [];
    // answers that are submitted by user
    const ansData = state.test3Data;
    console.log("ansData", ansData);
    // increamenting first,second and third choices in party data
    ansData.forEach((element) => {
      updatedParty = party.map((e) => {
        if (parseInt(element.value) === e.id && element.boxValue === 1) {
          e.first_Choice = e.first_Choice + 1;
          return e;
        } else if (parseInt(element.value) === e.id && element.boxValue === 2) {
          e.second_Choice = e.second_Choice + 1;
          return e;
        } else if (parseInt(element.value) === e.id && element.boxValue === 3) {
          e.third_Choice = e.third_Choice + 1;
          return e;
        } else {
          return e;
        }
      });
    });
    console.log(updatedParty);
    // adding total score of first, second and third box
    const total_score = updatedParty.map((e) => {
      e.total_Score =
        e.first_Choice * first_multiplier +
        e.second_Choice * second_multiplier +
        e.third_Choice * third_multiplier;
      return e;
    });
    // adding most common of first,second and third box
    console.log(total_score);
    const most_common = total_score.map((e) => {
      e.most_Common = e.first_Choice + e.second_Choice + e.third_Choice;
      return e;
    });
    console.log(most_common);
    // adding total scores to net total score
    let netTotalScore = 0;
    most_common.forEach((e) => {
      netTotalScore += e.total_Score;
    });

    // adding most common to net most common
    let netMostCommon = 0;
    most_common.forEach((e) => {
      netMostCommon += e.most_Common;
    });

    // adding netTotalScore and netMostCommon to party PartyArray
    const newParty = most_common.map((e) => {
      e.netTotalScore = netTotalScore;
      e.netMostCommon = netMostCommon;
      return e;
    });
    console.log(newParty);

    // calculating total score distribution
    const totalScoreDistribution = newParty.map((e) => {
      const disScore = (e.total_Score / e.netTotalScore) * 100;
      e.total_Score_Distribution = disScore.toFixed(2);
      return e;
    });
    console.log(totalScoreDistribution);
    // calculating most common score distribution
    const mostCommonScoreDistribution = totalScoreDistribution.map((e) => {
      const comScore = (e.most_Common / e.netMostCommon) * 100;
      e.most_Common_Distribution = comScore.toFixed(2);
      return e;
    });
    //
    console.log(mostCommonScoreDistribution);
    global.mostCommonScoreDistribution = mostCommonScoreDistribution;
    dispatch({ type: "TEMPLATE2_SCORE_DISTRIBUTION", mostCommonScoreDistribution: mostCommonScoreDistribution });
    // function for tie braking
    tiebrakers(mostCommonScoreDistribution);


  };
  //Calculations end

  // questionAnswerHandler
  const questionAnsHandler = (array, indexP) => {
    console.log("Enabling...");
    indexP++;
    console.log("indexP", indexP);
    console.log(QUESTIONS.length);
    if (indexP < QUESTIONS.length) {
      console.log(document.getElementById("moveTop" + indexP))
      if (validationErrorCounter == 0) {
        document.getElementById("moveTop" + indexP).scrollIntoView({ alignTop: true, behaviour: "smooth" });
      }
    }

    // Logic for updating questions Enableing
    const EnabledIndex = QUESTIONS.findIndex((e) => e.IsEnabled === true);
    console.log("EnabledIndex", EnabledIndex);
    if (EnabledIndex <= QUESTIONS.length - 1 && EnabledIndex >= 0) {
      let firstQuestionId = "";
      let boxFilled = false;
      const UPDATED_QUESTIONS = QUESTIONS.map((e, index) => {
        // Enableing next index
        console.log("index", index)
        if (validationErrorCounter == 0) {
          if (EnabledIndex + 1 === index) {
            console.log("Enabled Match")
            return {
              id: e.id,
              test_three_answers: e.test_three_answers,
              question_title: e.question_title,
              test_id: e.test_id,
              trait_id: e.trait_id,
              IsEnabled: true,
              IsMissed: e.IsMissed
            };
          }
          else if ((indexP - 1) === index) {
            console.log("Same")
            return {
              id: e.id,
              test_three_answers: e.test_three_answers,
              question_title: e.question_title,
              test_id: e.test_id,
              trait_id: e.trait_id,
              IsEnabled: false,
              IsMissed: false
            };
          }
          else {
            console.log("Other")
            return {
              id: e.id,
              test_three_answers: e.test_three_answers,
              question_title: e.question_title,
              test_id: e.test_id,
              trait_id: e.trait_id,
              IsEnabled: false,
              IsMissed: e.IsMissed
            };

          }
        }
        else {
          if ((indexP - 1) === index) {
            boxFilled = true;
            return {
              id: e.id,
              test_three_answers: e.test_three_answers,
              question_title: e.question_title,
              test_id: e.test_id,
              trait_id: e.trait_id,
              IsEnabled: false,
              IsMissed: false
            };
          }
          else {
            if (boxFilled == true && firstQuestionId == "") {
              firstQuestionId = index;
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: true,
                IsMissed: e.IsMissed
              };
            }
            else {
              return {
                id: e.id,
                test_three_answers: e.test_three_answers,
                question_title: e.question_title,
                test_id: e.test_id,
                trait_id: e.trait_id,
                IsEnabled: false,
                IsMissed: e.IsMissed
              };
            }


          }
        }

      });
      if (validationErrorCounter == 1) {
        if (firstQuestionId != "") {
          console.log(firstQuestionId)
          console.log(document.getElementById("moveTop" + firstQuestionId))
          document.getElementById("moveTop" + firstQuestionId).scrollIntoView({ alignTop: true, behaviour: "smooth" });
        }
      }

      console.log(UPDATED_QUESTIONS);
      setQUESTIONS(UPDATED_QUESTIONS);
      // result button enabling Logic
    }
    console.log(QUESTIONS)
    // if (EnabledIndex === QUESTIONS.length - 1) {

    // }
    if ((QUESTIONS.length * 3) == array.length) {
      setIsDisabled(false);
    }
    console.log(array)
    TESTANSARRAY = array;
    // console.log(TESTANSARRAY);
    dispatch({ type: "TEST3", test3Ans: TESTANSARRAY });
  };
  const focusCurrentHandler = (id) => {
    const UPDATED_QUESTIONS = QUESTIONS.map((e, index) => {
      // Enableing next index
      if (id === e.id) {
        return {
          id: e.id,
          test_three_answers: e.test_three_answers,
          question_title: e.question_title,
          test_id: e.test_id,
          trait_id: e.trait_id,
          IsEnabled: true,
          IsMissed: e.IsMissed
        };
      } else {
        return {
          id: e.id,
          test_three_answers: e.test_three_answers,
          question_title: e.question_title,
          test_id: e.test_id,
          trait_id: e.trait_id,
          IsEnabled: false,
          IsMissed: e.IsMissed
        };
      }
    });
    setQUESTIONS(UPDATED_QUESTIONS);
  };
  // questionAnswerHandler end

  // function to prevent back
  const preventBack = () => { };
  // function to prevent back end

  // result button handler
  const resultBtnHandler = (e) => {
    e.preventDefault();
    if (!isDisabled) {
      setIsDisabled(true);
      setIsDisabledError(false);
      setValidationErrorCounter(0);
      calculations();
      preventBack();
    }
    else {
      console.log(isDisabled)
      console.log("ASB1")
      console.log(QUESTIONS)
      setValidationErrorCounter(1);
      setIsDisabledError(true);

      let firstQuestionId = "";
      const UPDATEDQUESTIONS = QUESTIONS.map((question, index) => {
        console.log(question)
        if (firstQuestionId == "" && question.IsMissed == true) {
          firstQuestionId = index;
          question.IsEnabled = true;
          return question;
        }
        else {
          question.IsEnabled = false;
          return question;
        }

      });
      console.log(firstQuestionId)
      console.log(document.getElementById("moveTop" + firstQuestionId))
      document.getElementById("moveTop" + firstQuestionId).scrollIntoView({ alignTop: true, behaviour: "smooth" });
      setQUESTIONS(UPDATEDQUESTIONS)
    }
    // navigate(`/reportResult/${id}`, { state: percentages.state });
  };
  // result button handler end

  //next button handler
  const nextBtnHandler = (e) => {
    if (!isDisabled) {
      setIsDisabled(true);
      setIsDisabledError(false);
      setValidationErrorCounter(0);
      apiLoad();
    }
    else {
      console.log("ASB")
      setValidationErrorCounter(1);
      setIsDisabledError(true);

      let firstQuestionId = "";
      const UPDATEDQUESTIONS = QUESTIONS.map((question, index) => {
        console.log(question)
        if (firstQuestionId == "" && question.IsMissed == true) {
          firstQuestionId = index;
          question.IsEnabled = true;
          return question;
        }
        else {
          question.IsEnabled = false;
          return question;
        }

      });
      console.log(firstQuestionId)
      console.log(document.getElementById("moveTop" + firstQuestionId))
      document.getElementById("moveTop" + firstQuestionId).scrollIntoView({ alignTop: true, behaviour: "smooth" });
      setQUESTIONS(UPDATEDQUESTIONS)
    }
  };
  //next button handler End

  return (
    <div className="container-fluid  h-100">
      <div dangerouslySetInnerHTML={{ __html: scriptData }} />
      <div className="row h-100">
        <div className="col-11  px-0 mx-auto h-100 ">
          <div className={` ${classes.mainBoxWrapper}`}>
            <Header />
            <div className={`container ${classes.mainBox}`}>
              <div className={` ${classes.mainBoxAfter}`}>
                {/* copied */}
                {loading ? (
                  <h1 className="text-white text-center my-5">Loading...</h1>
                ) : (
                  <div>
                    {QUESTIONS.map((question, index) => (
                      <div
                        className={index == 0 ? "mt-2" : "mt-4"}
                        ref={question.IsEnabled ? move : null}
                        key={question.id}
                        id={"moveTop" + parseInt(index)}
                      >
                        <Question2
                          key={question.id}
                          id={question.id}
                          question={question.question_title}
                          isEnabled={question.IsEnabled}
                          options={question.test_three_answers}
                          index={index}
                          questionHandle={questionAnsHandler}
                          questionLength={QUESTIONS}
                          scrollMove={move}
                          count={count}
                          focusCurrentHandler={focusCurrentHandler}
                          validationErrorCounter={validationErrorCounter}
                          IsMissed={question.IsMissed}
                        />
                      </div>
                    ))}
                    {isDisableError ?
                      <div className=" mb-5 align-items-center text-center testSection">
                        <h2 className={`${classes["errorHeading"]}`}><img style={{ height: 27, marginRight: 15 }} src={RedPNG} />Error ! Complete All Questions!</h2>
                      </div>
                      : null}
                    <div className="text-center">
                      {nextBtn ? (
                        <button
                          className={`btn ${classes["reportBtn"]}`}
                          onClick={nextBtnHandler}
                        // disabled={isDisabled}
                        >
                          next
                        </button>
                      ) : (
                        <button
                          className={`btn ${classes["reportBtn"]}`}
                          onClick={resultBtnHandler}
                        // disabled={isDisabled}
                        >
                          result
                        </button>
                      )}
                    </div>
                  </div>
                )}
                {/* copied end */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden className={` ${classes.footerCodeSnippets}`}>
        <h1>Code Here...</h1>
      </div>
    </div>
  );
};

export default Test2;
