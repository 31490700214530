export const getPartialAddress = (address, front = 7, back = 4) => {
  if (!address) {
    return "";
  }
  return (
    address.substr(0, front) +
    "..." +
    address.substr(address.length - back, address.length)
  );
};

export const isEmail = (email) => {
  // don't remember from where i copied this code, but this works.
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(email)) {
    return true;
    // this is a valid email address
    // call setState({email: email}) to update the email
    // or update the data in redux store.
  }
  else {
    return false
    // invalid email, maybe show an error to the user.
  }
}
