export const supportedChains = [
  {
    name: "Ethereum",
    short_name: "mainnet",
    chain: "ETH",
    network: "mainnet",
    chain_id: 1,
    network_id: 1,
    rpc_url: "https://mainnet.infura.io/v3/%API_KEY%",
    native_currency: {
      symbol: "ETH",
      name: "ETH",
      decimals: "18",
      contractAddress: "",
      balance: "",
    },
    explorer: "https://etherscan.io",
  },
];

export const networks = [
  {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://mainnet.infura.io/v3/"],
  },
];

